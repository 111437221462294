import { useSuspenseQuery } from "@tanstack/react-query";

import {
  buildQueryKeyPart,
  buildRegionQueryKey,
} from "~/api/buildQueryKeySchema";
import {
  ClusterReplicaMetricsParameters,
  fetchClusterReplicaMetrics,
} from "~/api/materialize/cluster/clusterReplicaMetrics";

export const queryKeys = {
  clusterMetrics: (params: ClusterReplicaMetricsParameters) =>
    [
      buildRegionQueryKey("cluster-metrics"),
      buildQueryKeyPart("metrics", params),
    ] as const,
};

export const clusterMetricsQueryKey = (
  params: ClusterReplicaMetricsParameters,
) =>
  [
    ...queryKeys.clusterMetrics(params),
    buildQueryKeyPart("cluster-metrics", params),
  ] as const;

export function useClusterReplicaMetrics(
  params: ClusterReplicaMetricsParameters,
) {
  return useSuspenseQuery({
    refetchInterval: 5000,
    queryKey: clusterMetricsQueryKey({ ...params }),
    queryFn: async ({ queryKey, signal }) => {
      const [, , queryKeyParameters] = queryKey;

      return fetchClusterReplicaMetrics({
        queryKey,
        parameters: queryKeyParameters,
        requestOptions: { signal },
      });
    },
  });
}
