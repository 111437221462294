import React from "react";
import { Route } from "react-router-dom";

import { SentryRoutes } from "~/sentry";

import IntegrationsGallery from "./IntegrationsGallery";
import integrationsList from "./integrationsList";

const IntegrationsRoutes = () => {
  return (
    <SentryRoutes>
      <Route
        path="/"
        element={<IntegrationsGallery integrations={integrationsList} />}
      />
    </SentryRoutes>
  );
};

export default IntegrationsRoutes;
