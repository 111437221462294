import { atom } from "jotai";

import {
  fetchCurrentOrganization,
  queryKeys as authQueryKeys,
} from "~/api/auth";
import { Organization } from "~/api/cloudGlobalApi";
import { User } from "~/api/frontegg/types";
import { appConfig } from "~/config/AppConfig";
import { fronteggQueryKeys, queryUser } from "~/queries/frontegg";
import { getQueryClient } from "~/queryClient";

import { currentEnvironmentState } from "./environments";

export const isCurrentOrganizationBlockedAtom = atom(async (get) => {
  if (!appConfig.hasAuthProvider) return false;
  const currentUser = await getQueryClient().ensureQueryData<User>({
    queryKey: fronteggQueryKeys.user(),
    queryFn: queryUser,
  });
  const currentOrganization =
    await getQueryClient().ensureQueryData<Organization>({
      queryKey: authQueryKeys.currentOrganization(currentUser.tenantId),
      queryFn: fetchCurrentOrganization,
    });
  if (currentOrganization.blocked) return true;
  const currentEnvironment = await get(currentEnvironmentState);
  return (
    currentEnvironment?.state === "enabled" &&
    currentEnvironment.status.health === "blocked"
  );
});
