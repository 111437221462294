import { OpenApiFetchError } from "./OpenApiFetchError";

export async function handleOpenApiResponse<T>(
  data: T | undefined,
  response: Response,
) {
  if (!response.ok) {
    throw new OpenApiFetchError(response.status, data ?? "Empty response");
  }
  return {
    ...response,
    data,
  };
}
export async function handleOpenApiResponseWithBody<T>(
  data: T | undefined,
  response: Response,
) {
  if (!response.ok) {
    throw new OpenApiFetchError(response.status, data ?? "Empty response");
  }
  if (!data) {
    throw new OpenApiFetchError(response.status, data ?? "Empty response");
  }
  return {
    ...response,
    data,
  };
}
