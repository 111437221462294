import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";

import { getCurrentTenant } from "~/api/auth";
import { ResetStoreContext } from "~/layouts/JotaiProviderWrapper";
import {
  useCurrentUser,
  useSetActiveTenant,
  useTenants,
} from "~/queries/frontegg";

const useRemoveLocationParams = () => {
  const navigate = useNavigate();
  return () => {
    navigate(location.pathname, { replace: true });
  };
};

export function useSwitchOrganization() {
  const { data: user } = useCurrentUser();
  const { mutateAsync: switchTenant } = useSetActiveTenant();
  const [switchTenantPromise, setSwitchTenantPromise] =
    React.useState<Promise<void> | null>(null);
  const queryClient = useQueryClient();
  const resetStore = React.useContext(ResetStoreContext);
  const removeLocationParams = useRemoveLocationParams();
  const {
    data: { tenants },
  } = useTenants();
  const tenantSwitchingEnabled = tenants.length > 1;
  const currentTenant = getCurrentTenant(user, tenants);

  if (switchTenantPromise) {
    // Suspend until we are done switching the tenant, otherwise the user wouldn't see a
    // loading state until 2 sequential api calls complete.
    throw switchTenantPromise;
  }

  return async (tenantId: string) => {
    if (!tenantSwitchingEnabled) return;
    if (tenantId === currentTenant?.tenantId) return;

    const promise = (async () => {
      // Frontegg api call to switch tenants, followed by refreshing the token, since it
      // includes the active tenant.
      await switchTenant({ tenantId });
      // Once that's done, clear our query cache and jotai store, which triggers a bunch
      // of data loading and suspends in the App component.
      queryClient.clear();
      resetStore();
      // Remove all query params so view state is not shared across
      // organizations.
      removeLocationParams();
    })();
    setSwitchTenantPromise(promise);
  };
}
