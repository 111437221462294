import { DatabaseType } from "./constants";

export function typeToLabel(databaseType: DatabaseType): string {
  if (databaseType === "mysql") {
    return "MySQL";
  } else if (databaseType === "postgres") {
    return "PostgreSQL";
  }
  throw new Error("Unreachable");
}
