// Easter egg componentry

import { chakra } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { useToast } from "~/hooks/useToast";

const cdnRoot = "https://de62f3dtfyis.cloudfront.net/";

type Track = {
  filePath: string;
  artist: string;
  attribution: string;
  title: string;
};

const tracks: Track[] = [
  {
    filePath: "vacation-2023.mp3",
    artist: "Jared Wofford & Josh Arenberg",
    attribution: "https://linktr.ee/jaredwoffordandjosharenberg",
    title: "🌊⋎⍲🝌⧜Ϯ⎞⍬𐒐🌊⤳",
  },
];

function getRandomTrack(): Track {
  return tracks[0];
}

const Audio = () => {
  const [track, _setTrack] = useState<Track>(getRandomTrack());
  const audio = useRef<HTMLAudioElement>(null);
  const toast = useToast({ position: "top", isClosable: true });
  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps, react-compiler/react-compiler
  const toastCb = React.useCallback(toast, []);
  useEffect(() => {
    toastCb({
      duration: 5000,
      icon: null,
      status: "info",
      title: track.title,
      description: (
        <a href={track.attribution} target="_blank" rel="noreferrer">
          {track.artist}
        </a>
      ),
    });
  }, [track, toastCb]);
  return (
    <chakra.audio
      ref={audio}
      src={cdnRoot + track.filePath}
      autoPlay
      loop
    ></chakra.audio>
  );
};

export { Audio };
