import { sql } from "kysely";

import { escapedLiteral as lit } from "~/api/materialize";

export default function createClusterReplicaStatement(values: {
  clusterName: string;
  name: string;
  size: string;
}) {
  return sql`CREATE CLUSTER REPLICA ${sql.id(values.clusterName)}.${sql.id(
    values.name,
  )} SIZE = ${lit(values.size)}
`;
}
