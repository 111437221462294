import { useCallback, useState } from "react";

function useIsScrolledHorizontal(threshold = 0) {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const onScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      event.stopPropagation();
      if (event.currentTarget.scrollLeft > threshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    },
    [threshold],
  );

  return { isScrolled, onScroll };
}

export default useIsScrolledHorizontal;
