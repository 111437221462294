import { forwardRef, Link, LinkProps, useTheme } from "@chakra-ui/react";
import React from "react";

import { MaterializeTheme } from "~/theme";

const TextLink = forwardRef<LinkProps, "a">((props: LinkProps, ref) => {
  const { colors } = useTheme<MaterializeTheme>();

  return (
    <Link
      ref={ref}
      color={colors.accent.brightPurple}
      textDecoration="none"
      _hover={{ textDecoration: "underline" }}
      {...props}
    />
  );
});

export default TextLink;
