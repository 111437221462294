import React from "react";

import { AppConfig, appConfig } from "~/config/AppConfig";

export function withConfigCheck<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  configCheck: (config: AppConfig) => boolean,
) {
  return function WithConfigCheckComponent(props: P) {
    if (!configCheck(appConfig)) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
}
