import { useSegmentPageTracking } from "~/analytics/segment";
import useHubspotNpsSurvey from "~/analytics/useHubspotNpsSurvey";
import { usePrivileges } from "~/hooks/usePrivileges";
import { useSetInitialRegion } from "~/hooks/useSetInitialRegion";
import { useShowEnvironmentErrors } from "~/hooks/useShowEnvironmentErrors";
import { useSentryIdentifyOrganization } from "~/sentry";
import { useSubscribeToAllClusters } from "~/store/allClusters";
import { useSubscribeToAllObjects } from "~/store/allObjects";
import { useSubscribeToAllSchemas } from "~/store/allSchemas";
import { usePollEnvironmentHealth } from "~/store/environments";
import { useTrackFocus } from "~/store/focus";

/** Initializes global features, rendered after frontegg token is available */
export const AppInitializer = () => {
  useHubspotNpsSurvey();
  useTrackFocus();
  useSegmentPageTracking();
  useSentryIdentifyOrganization();
  usePollEnvironmentHealth({ intervalMs: 5000 });
  useShowEnvironmentErrors();
  useSetInitialRegion();
  usePrivileges();
  useSubscribeToAllObjects();
  useSubscribeToAllSchemas();
  useSubscribeToAllClusters();

  return null;
};
