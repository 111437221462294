import * as Sentry from "@sentry/react";
import { QueryKey } from "@tanstack/react-query";
import { InferResult, sql } from "kysely";

import { executeSqlV2, queryBuilder } from "../";

export type ClusterReplicasParams = {
  clusterId: string;
  orderBy: "memory_bytes" | "name";
};

export function buildClusterReplicasQuery({
  clusterId,
  orderBy,
}: ClusterReplicasParams) {
  return (
    queryBuilder
      .selectFrom("mz_cluster_replicas as cr")
      .innerJoin("mz_cluster_replica_sizes as crs", "cr.size", "crs.size")
      .select((eb) => [
        "cr.name",
        sql<bigint>`${eb.ref("crs.memory_bytes")} * ${eb.ref("crs.processes")}`.as(
          "memoryBytes",
        ),
      ])
      .where("cr.cluster_id", "=", clusterId)
      // Order by smallest replicas first
      .orderBy(orderBy)
  );
}

const spanContext = {
  name: "cluster-replicas-query",
  op: "http.client",
};

/**
 * Fetches all replicas for a given cluster.
 */
export async function fetchClusterReplicas(
  params: ClusterReplicasParams,
  queryKey: QueryKey,
  requestOptions?: RequestInit,
) {
  const compiledQuery = buildClusterReplicasQuery(params).compile();
  return Sentry.startSpan(spanContext, async () =>
    executeSqlV2({
      queries: compiledQuery,
      queryKey: queryKey,
      requestOptions,
    }),
  );
}

export type ClusterReplica = InferResult<
  ReturnType<typeof buildClusterReplicasQuery>
>[0];
