import { BoxProps, Center, Spinner } from "@chakra-ui/react";
import React from "react";

export const LoadingContainer = (props: BoxProps) => {
  return (
    <Center flex={1} css={{ width: "100%", height: "100%" }} {...props}>
      <Spinner data-testid="loading-spinner" />
    </Center>
  );
};
