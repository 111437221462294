export function isSubroute(route: string, potentialSubroute: string) {
  const routeSegments = route.split("/").filter(Boolean);
  const potentialSubrouteSegments = potentialSubroute
    .split("/")
    .filter(Boolean);

  return routeSegments.every(
    (routeSegment, index) => routeSegment === potentialSubrouteSegments[index],
  );
}
