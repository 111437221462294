export type MimeType = "text/plain" | "text/csv";
export type DownloadFileOptions = {
  mimeType: MimeType;
};

/**
 * Trigger a download of a file to the local machine.
 *
 * @param fileName the default file name
 * @param contents the file contents
 * @param options (optional) additional properties for the file
 */
export function downloadFile(
  fileName: string,
  contents: string,
  options: DownloadFileOptions = { mimeType: "text/plain" },
) {
  const file = new Blob([contents], { type: options.mimeType });
  const url = URL.createObjectURL(file);
  const element = document.createElement("a");
  element.href = url;
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  // Cleanup
  element.remove();
  URL.revokeObjectURL(url);
}
