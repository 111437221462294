import { atom, useAtom } from "jotai";
import React from "react";
import { useInView } from "react-intersection-observer";
import useResizeObserver from "use-resize-observer";

export const usePageHeadingRef = () => {
  const [, setValue] = useAtom(isPageHeadingVisible);
  const headerHeight = usePageHeaderHeight();
  const { ref, inView } = useInView({ rootMargin: `-${headerHeight ?? 0}px` });

  React.useEffect(() => {
    setValue(inView);
  }, [inView, setValue]);

  return ref;
};

export const useTrackPageHeaderHeight = () => {
  const headerRef = React.useRef<HTMLElement | null>(null);
  const [, setHeight] = useAtom(pageHeaderHeight);

  useResizeObserver<HTMLElement>({
    ref: headerRef,
    onResize: ({ height }) => {
      if (height) {
        const offsetTop = headerRef.current?.offsetTop ?? 0;
        // include offsetTop to account for the top bar on mobile layout
        setHeight(height + offsetTop);
      }
    },
  });
  return headerRef;
};

export const usePageHeaderHeight = () => {
  const [value] = useAtom(pageHeaderHeight);
  return value;
};

export const usePageHeadingInView = () => {
  const [value] = useAtom(isPageHeadingVisible);
  return value;
};

export const pageHeaderHeight = atom<number | null>(null);

export const isPageHeadingVisible = atom<boolean>(true);
