import { QueryKey } from "@tanstack/react-query";
import { InferResult } from "kysely";

import { queryBuilder } from "~/api/materialize/db";
import { executeSqlV2 } from "~/api/materialize/executeSqlV2";

export function buildQueryHistoryClustersQuery() {
  return queryBuilder
    .selectFrom("mz_clusters as c")
    .select(["c.id", "c.name"])
    .orderBy(["c.name"]);
}

/**
 * Fetches all clusters in the query history cluster filter.
 */
export async function fetchQueryHistoryClusters({
  queryKey,
  requestOptions,
}: {
  queryKey: QueryKey;
  requestOptions?: RequestInit;
}) {
  const compiledQuery = buildQueryHistoryClustersQuery().compile();
  return executeSqlV2({
    queries: compiledQuery,
    queryKey: queryKey,
    requestOptions,
  });
}

export type QueryHistoryCluster = InferResult<
  ReturnType<typeof buildQueryHistoryClustersQuery>
>[0];
