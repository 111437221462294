import { Code, useTheme, VStack } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { LinkIt, urlRegex } from "react-linkify-it";

import { Notice } from "~/api/materialize/types";
import TextLink from "~/components/TextLink";
import useGrafanaTraceUrl from "~/hooks/useGrafanaTraceUrl";
import { useCurrentUser } from "~/queries/frontegg";
import { MaterializeTheme } from "~/theme";
import { isMzInternalEmail } from "~/util";

// Use our TextLink component for linkification instead of react-linkify-it's
// anchor.
const LinkItUrl = ({ children }: PropsWithChildren) => {
  return (
    <LinkIt
      component={(match, key) => (
        <TextLink href={match} key={key} target="_blank" rel="noreferrer">
          {match}
        </TextLink>
      )}
      regex={urlRegex}
    >
      {children}
    </LinkIt>
  );
};

const traceIdRegex = /(?<=trace id: )\w+/;

const TraceLink = ({ traceId }: { traceId: string }) => {
  const { data: user } = useCurrentUser();
  const url = useGrafanaTraceUrl(traceId);
  if (!isMzInternalEmail(user.email) || !url) return traceId;
  return (
    <TextLink href={url} target="_blank" rel="noreferrer">
      {traceId}
    </TextLink>
  );
};

const LinkItTraceId = ({ children }: PropsWithChildren) => {
  return (
    <LinkIt
      component={(match, key) => <TraceLink key={key} traceId={match} />}
      regex={traceIdRegex}
    >
      {children}
    </LinkIt>
  );
};

const NoticeOutput = ({ notice }: { notice: Notice }) => {
  const { colors } = useTheme<MaterializeTheme>();
  const messages = [`${notice.severity.toUpperCase()}: ${notice.message}`];
  if (notice.detail) {
    messages.push(`DETAIL: ${notice.detail}`);
  }
  if (notice.hint) {
    messages.push(`HINT: ${notice.hint}`);
  }
  return (
    <VStack alignItems="flex-start" px="3">
      {messages.map((message, index) => (
        <Code
          key={index}
          color={colors.foreground.secondary}
          whiteSpace="pre-wrap"
        >
          <LinkItUrl>
            <LinkItTraceId>{message}</LinkItTraceId>
          </LinkItUrl>
        </Code>
      ))}
    </VStack>
  );
};

export default NoticeOutput;
