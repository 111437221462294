import { useClipboard } from "@chakra-ui/react";
import React from "react";

/** Wraps Chakras useClipboard hook and adds a `copied` state with a timeout. */
export const useCopyableText = (text: string, delay?: number) => {
  const { onCopy, setValue, hasCopied } = useClipboard(text, delay || 1000);

  React.useEffect(() => {
    setValue(text);
  }, [setValue, text]);

  return {
    onCopy,
    copied: hasCopied,
  };
};
