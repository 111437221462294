import { QueryKey } from "@tanstack/react-query";
import { sql } from "kysely";

import { executeSqlV2, queryBuilder } from "~/api/materialize";

export function buildMaxMySqlConnectionsQuery() {
  return sql<{ max_mysql_connections: string }>`SHOW max_mysql_connections`;
}

/**
 * Fetches the maximum number of MySQL connections based on the database's
 * LaunchDarkly configuration
 */
export default async function fetchMaxMySqlConnections({
  queryKey,
  requestOptions,
}: {
  queryKey: QueryKey;
  requestOptions: RequestInit;
}) {
  const compiledQuery = buildMaxMySqlConnectionsQuery().compile(queryBuilder);

  const response = await executeSqlV2({
    queries: compiledQuery,
    queryKey,
    requestOptions,
  });

  let max: number | null = null;

  if (response.rows) {
    max = parseInt(response.rows[0].max_mysql_connections);
  }

  return max;
}
