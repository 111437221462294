import { Provider as JotaiProvider } from "jotai";
import React from "react";

import { getStore, resetStore } from "~/jotai";

import { ResetStoreContext } from "./Context";

export const JotaiProviderWrapper = (props: React.PropsWithChildren) => {
  const [key, setKey] = React.useState(0);

  const reset = React.useCallback(() => {
    setKey(key + 1);
    resetStore();
  }, [key]);

  return (
    <ResetStoreContext.Provider value={reset}>
      <JotaiProvider key={key} store={getStore()}>
        {props.children}
      </JotaiProvider>
    </ResetStoreContext.Provider>
  );
};
