import { Text, TextProps, useTheme } from "@chakra-ui/react";
import React from "react";

import { MaterializeTheme } from "~/theme";

const CountSignifier = ({ children, ...rest }: TextProps) => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <Text
      as="span"
      backgroundColor={colors.accent.brightPurple}
      borderRadius="36px"
      color={colors.white}
      textStyle="text-small"
      fontWeight="500"
      px="6px"
      h="4"
      w="5"
      ml="3"
      verticalAlign="top"
      {...rest}
    >
      {children}
    </Text>
  );
};

export default CountSignifier;
