import { v4 as uuidv4 } from "uuid";

export type HistoryId = string;

/**
 * Returns a v4 uuid. This function exists so we can easily mock historyIds in test.
 */
export function createHistoryId() {
  return uuidv4();
}
