import {
  // This is the wrapper, the only place we should be importing it
  // eslint-disable-next-line no-restricted-imports
  Modal as ChakraModal,
  ModalProps,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

/**
 * Wraps Chakra's modal and automatically disables focus lock for mobile viewports.
 */
export const Modal = (props: ModalProps) => {
  const blockScrollOnMount = useBreakpointValue(
    {
      base: false,
      lg: true,
    },
    { ssr: false },
  );
  return <ChakraModal blockScrollOnMount={blockScrollOnMount} {...props} />;
};
