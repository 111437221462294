import { createCodeMirrorWithContext } from "@codemirror-toolkit/react";

const {
  Provider,
  useGetView,
  useView,
  useViewDispatch,
  useViewEffect,
  useContainerRef,
} = createCodeMirrorWithContext<HTMLDivElement>("CodeMirrorContext");

export {
  Provider as _Provider,
  useContainerRef,
  useGetView,
  useView,
  useViewDispatch,
  useViewEffect,
};
