import { MenuItem, useDisclosure } from "@chakra-ui/react";
import React from "react";

import { Cluster } from "~/api/materialize/cluster/clusterList";

import { AlterClusterModal } from "./AlterClusterModal";
import { useAvailableClusterSizes, useMaxReplicasPerCluster } from "./queries";

const AlterClusterMenuItem = ({ cluster }: { cluster: Cluster }) => {
  const { data: clusterSizes } = useAvailableClusterSizes();
  const { data: maxReplicas } = useMaxReplicasPerCluster();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}
        textStyle="text-ui-med"
      >
        Alter cluster
      </MenuItem>
      {isOpen && clusterSizes && (
        <AlterClusterModal
          cluster={cluster}
          clusterSizes={clusterSizes}
          isOpen={isOpen}
          maxReplicas={maxReplicas ?? undefined}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default AlterClusterMenuItem;
