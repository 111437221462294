const TENANT_DOMAIN = "materialize";
const ROUTER_NAME = "app-router";

/**
 * Open the sales call scheduling modal for the specified user.
 *
 * @param name - the user's name.
 * @param email - the user's email address.
 */
export function submit({
  name,
  email,
  organizationId,
  meetingType,
}: {
  name: string;
  email: string;
  organizationId: string;
  meetingType?: string;
}) {
  ChiliPiper.submit(TENANT_DOMAIN, ROUTER_NAME, {
    map: true,
    lead: {
      name: name,
      email: email,
    },
    type: meetingType,
    event: {
      Organization_ID__c: organizationId,
    },
  });
}
