import { typographySystem } from "../typography";

export const Accordion = {
  defaultProps: {
    variant: "borderless",
  },
  variants: {
    borderless: {
      container: {
        border: "none",
        p: "0",
      },
      button: {
        ...typographySystem["text-ui-med"],
        color: "foreground.primary",
        lineHeight: "16px",

        px: "4",
        py: "2",
        _hover: {
          background: "transparent",
        },
      },
      icon: {
        width: "16px",
        height: "16px",
      },
      panel: {
        px: "4",
        py: "2",
      },
    },
  },
};
