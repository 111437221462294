import { QueryKey } from "@tanstack/react-query";
import { InferResult, sql } from "kysely";

import { assert } from "~/util";

import { executeSqlV2, queryBuilder } from "../";

export type ArrangmentMemoryUsageParams = {
  arrangmentIds?: string[];
  replicaSize?: number;
  clusterName?: string;
  replicaName?: string;
};

export function buildArrangmentMemoryUsageQuery({
  replicaSize,
  arrangmentIds,
}: Required<ArrangmentMemoryUsageParams>) {
  return queryBuilder
    .selectFrom("mz_objects as o")
    .innerJoin("mz_compute_exports as ce", "ce.export_id", "o.id")
    .innerJoin(
      "mz_dataflow_arrangement_sizes as das",
      "das.id",
      "ce.dataflow_id",
    )
    .select((eb) => {
      assert(replicaSize);
      return [
        "o.id",
        eb.ref("das.size").$castTo<number>().as("size"),
        sql<number>`${sql.id("das", "size")} / ${sql.raw(
          replicaSize.toString(),
        )} * 100`.as("memoryPercentage"),
      ];
    })
    .where("o.id", "in", arrangmentIds);
}

/**
 * Fetches arrangment memory usage for a given cluster replica.
 */
export async function fetchArrangmentMemoryUsage({
  params: { clusterName, replicaName, replicaSize, arrangmentIds },
  queryKey,
  requestOptions,
}: {
  params: ArrangmentMemoryUsageParams;
  queryKey: QueryKey;
  requestOptions?: RequestInit;
}) {
  if (
    !clusterName ||
    !arrangmentIds ||
    !replicaSize ||
    !replicaName ||
    arrangmentIds?.length === 0
  ) {
    return null;
  }

  const compiledQuery = buildArrangmentMemoryUsageQuery({
    arrangmentIds,
    replicaSize,
    clusterName,
    replicaName,
  }).compile();
  return executeSqlV2({
    queries: compiledQuery,
    queryKey: queryKey,
    requestOptions,
    sessionVariables: { cluster: clusterName, cluster_replica: replicaName },
  });
}

export type MemoryUsage = InferResult<
  ReturnType<typeof buildArrangmentMemoryUsageQuery>
>[0];
