import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const GcpLogoIcon = (props: IconProps) => {
  return (
    <Icon
      width="8"
      height="8"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M20.3631 10.244L21.3881 10.2615L24.1731 7.4765L24.3081 6.2965C22.0991 4.3285 19.1826 3.13 15.9956 3.13C10.2256 3.13 5.35556 7.056 3.91406 12.374C4.21806 12.162 4.86806 12.321 4.86806 12.321L10.4331 11.406C10.4331 11.406 10.7191 10.9325 10.8641 10.956C11.5148 10.2411 12.3077 9.67002 13.192 9.27937C14.0762 8.88873 15.0323 8.68713 15.9991 8.6875C17.6491 8.691 19.1691 9.274 20.3631 10.2385V10.244Z"
          fill="#EA4335"
        />
        <path
          d="M28.0841 12.385C27.4376 10 26.1051 7.89401 24.3066 6.29651L20.3631 10.24C21.9431 11.515 22.9566 13.466 22.9566 15.65V16.346C24.8751 16.346 26.4336 17.908 26.4336 19.823C26.4336 21.7415 24.8716 23.3 22.9566 23.3H16.0026L15.3066 24V28.173L16.0026 28.8655H22.9566C25.3538 28.8622 27.6518 27.9085 29.3468 26.2135C31.0419 24.5186 31.9957 22.2206 31.9991 19.8235C31.9956 16.746 30.4491 14.023 28.0841 12.385Z"
          fill="#4285F4"
        />
        <path
          d="M9.04162 28.87H15.9916V23.3H9.04162C8.54785 23.3001 8.05982 23.194 7.61062 22.989L6.60712 23.2965L3.82212 26.0815L3.57812 27.0215C5.14757 28.2188 7.06712 28.8669 9.04112 28.866L9.04162 28.87Z"
          fill="#34A853"
        />
        <path
          d="M9.0425 10.785C6.64538 10.7883 4.34738 11.742 2.65232 13.437C0.957249 15.132 0.0034392 17.4299 0 19.827C0 22.7635 1.4065 25.3745 3.583 27.0285L7.615 22.9965C7.00471 22.7213 6.48675 22.276 6.12323 21.7138C5.75971 21.1516 5.56606 20.4965 5.5655 19.827C5.5655 17.9085 7.1275 16.35 9.0425 16.35C10.4525 16.35 11.6645 17.2 12.2125 18.4L16.2445 14.368C14.591 12.1915 11.9795 10.785 9.043 10.785H9.0425Z"
          fill="#FBBC05"
        />
      </g>
    </Icon>
  );
};
