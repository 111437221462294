import { QueryKey } from "@tanstack/react-query";
import { sql } from "kysely";

import { executeSqlV2, queryBuilder } from "~/api/materialize";

export function buildLargestClusterReplicaQuery(clusterId: string) {
  return (
    queryBuilder
      .selectFrom("mz_cluster_replicas as cr")
      .innerJoin("mz_cluster_replica_sizes as crs", "cr.size", "crs.size")
      .select((eb) => [
        "cr.name",
        "cr.size",
        eb
          .cast<number>(
            sql<number>`${eb.ref("crs.memory_bytes")} * ${eb.ref("crs.processes")}`,
            "numeric",
          )
          .as("memoryBytes"),
      ])
      .where("cr.cluster_id", "=", clusterId)
      // Order by largest replicas first, take the firs tone
      .orderBy("crs.memory_bytes", "desc")
      .limit(() => sql.raw("1"))
  );
}

export type LargestClusterReplicaParams = {
  clusterId: string;
};

/**
 * Fetches the largest cluster replica for a given cluster.
 */
export async function fetchLargestClusterReplica({
  params,
  queryKey,
  requestOptions,
}: {
  params: LargestClusterReplicaParams;
  queryKey: QueryKey;
  requestOptions?: RequestInit;
}) {
  const compiledQuery = buildLargestClusterReplicaQuery(
    params.clusterId,
  ).compile();
  return executeSqlV2({
    queries: compiledQuery,
    queryKey: queryKey,
    requestOptions,
  });
}
