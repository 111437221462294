import { PostgresError } from "pg-error-enum";

import MaterializeErrorCode from "./errorCodes";

class NetworkPolicyError extends Error {
  code: MaterializeErrorCode | PostgresError;
  detail: string | undefined;

  constructor(
    code: MaterializeErrorCode | PostgresError,
    detail: string | undefined,
    ...params: Parameters<ErrorConstructor>
  ) {
    super(...params);
    this.code = code;
    this.detail = detail;
  }
}

export default NetworkPolicyError;
