type HeightCache = Map<number, number>;

export const heightByListItemIndex: HeightCache = new Map<number, number>();

export function getTotalHeight(heightCache: HeightCache) {
  let sum = 0;
  for (const [_, height] of heightCache) {
    sum += height;
  }

  return sum;
}

export function clearListItemHeights() {
  heightByListItemIndex.clear();
}

export default heightByListItemIndex;
