export const ERROR_NOTICE_OUTPUT_MAX_WIDTH = "1008px";
export const TABLE_PAGE_SIZE = 20;
export const JOTAI_DEBOUNCE_WAIT_MS = 100;

export const COMMAND_RESULT_MAX_SIZE_BYTES = "1000000"; // When running a query, sets the data response cap at 1MB

export const NAVBAR_HEIGHT_PX = 64;

export const TUTORIAL_WIDTH = "600px";

export const CONNECTION_LOST_NOTICE_MESSAGE =
  "The connection was interrupted. Some session state may have been lost.";
