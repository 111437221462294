import React from "react";
import { Route } from "react-router-dom";

import SourcesList from "~/platform/sources/SourcesList";
import { SentryRoutes } from "~/sentry";

import NewSource from "./create/NewSource";

const SourceRoutes = () => {
  return (
    <SentryRoutes>
      <Route index element={<SourcesList />} />
      <Route path="new">
        <Route index path="*" element={<NewSource />} />
      </Route>
    </SentryRoutes>
  );
};

export default SourceRoutes;
