import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const LowerLeftCornerIcon = forwardRef<IconProps, "svg">((props, ref) => (
  <Icon
    height="4"
    width="4"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g id="Frame 536">
      <path
        id="Vector 148"
        d="M4 1V6C4 7.65685 5.34315 9 7 9H12"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </g>
  </Icon>
));

export default LowerLeftCornerIcon;
