import { QueryKey } from "@tanstack/react-query";
import { InferResult } from "kysely";

import { executeSqlV2, queryBuilder } from "..";

export const COLLECTION_INTERVAL_MS = 60_000;

/**
 * Returns a query for the statistics of a single sink.
 */
export function buildSinkStatisticsQuery({ sinkId }: { sinkId: string }) {
  return queryBuilder
    .with("sinks", (qb) =>
      qb
        .selectFrom("mz_sinks as s")
        .where("s.id", "=", sinkId)
        .select(["s.id", "s.id as sinkId"]),
    )
    .selectFrom("sinks as s")
    .innerJoin(`mz_sink_statistics as ss`, "ss.id", "s.id")
    .select((eb) => [
      "s.sinkId as id",
      eb.fn.sum<number>("messages_staged").as("messagesStaged"),
      eb.fn.sum<number>("messages_committed").as("messagesCommitted"),
      eb.fn.sum<number>("bytes_staged").as("bytesStaged"),
      eb.fn.sum<number>("bytes_committed").as("bytesCommitted"),
    ])
    .groupBy("s.sinkId");
}

export type SinkStatisticsDataPoint = InferResult<
  ReturnType<typeof buildSinkStatisticsQuery>
>[0];

export type Sink = InferResult<ReturnType<typeof buildSinkStatisticsQuery>>[0];

export async function fetchSinkStatistics(
  queryKey: QueryKey,
  filters: { sinkId: string },
  requestOptions: RequestInit,
) {
  const compiledQuery = buildSinkStatisticsQuery(filters).compile();
  return executeSqlV2({
    queries: compiledQuery,
    queryKey: queryKey,
    requestOptions,
  });
}
