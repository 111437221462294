import {
  Button,
  Code,
  Text,
  TextProps,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import React, { PropsWithChildren } from "react";

import {
  hasEnvironmentWritePermission,
  useCurrentOrganization,
} from "~/api/auth";
import MaterializeErrorCode from "~/api/materialize/errorCodes";
import NetworkPolicyError from "~/api/materialize/NetworkPolicyError";
import SupportLink from "~/components/SupportLink";
import TextLink from "~/components/TextLink";
import * as chilipiper from "~/marketing/chilipiper";
import docUrls from "~/mz-doc-urls.json";
import { useCurrentUser } from "~/queries/frontegg";
import { currentEnvironmentState } from "~/store/environments";
import { MaterializeTheme } from "~/theme";

const BlockedContent = ({
  title,
  children,
}: PropsWithChildren<{ title: string }>) => {
  return (
    <VStack flex={1} h="full" w="full" data-testid="account-status-alert">
      <VStack
        spacing={6}
        m={6}
        h="full"
        w="full"
        alignItems="center"
        justifyContent="center"
      >
        <VStack spacing={4} align="flex-start" maxWidth="500px">
          <Text as="h1" textStyle="heading-lg">
            {title}
          </Text>
          {children}
        </VStack>
      </VStack>
    </VStack>
  );
};

const BlockedText = (props: TextProps) => {
  return <Text as="h4" textStyle="text-base" fontSize="16px" {...props} />;
};

type NetworkBlockedDetails = {
  ip: string | null;
};

const IP_REGEX =
  /\b(?:\d{1,3}\.){3}\d{1,3}\b|\b(?:[A-Fa-f0-9]{1,4}:){7}[A-Fa-f0-9]{1,4}\b/g;

function parseNetworkBlock(error: NetworkPolicyError): NetworkBlockedDetails {
  const matches = error.detail?.match(IP_REGEX);
  if (matches) {
    return { ip: matches[0] ?? null };
  }
  return { ip: null };
}

function useNetworkPolicyBlock(): NetworkBlockedDetails | null {
  const environment = useAtomValue(currentEnvironmentState);
  if (environment && environment.state === "enabled") {
    for (const error of environment.status.errors) {
      if (
        error.details instanceof NetworkPolicyError &&
        error.details.code ===
          MaterializeErrorCode.NETWORK_POLICY_SESSION_DENIED
      ) {
        return parseNetworkBlock(error.details);
      }
    }
  }
  return null;
}

const BlockedState = () => {
  const { colors } = useTheme<MaterializeTheme>();
  const { data: user } = useCurrentUser();
  const { organization } = useCurrentOrganization();
  const isTrialCustomer = organization?.subscription?.type === "evaluation";
  const networkBlock = useNetworkPolicyBlock();
  const { data: currentUser } = useCurrentUser();
  const isOrgAdmin = hasEnvironmentWritePermission(currentUser);
  if (networkBlock !== null) {
    return (
      <BlockedContent title="Connection blocked">
        <BlockedText color={colors.foreground.secondary}>
          Your IP address{" "}
          {networkBlock.ip ? (
            <>
              (<Code>{networkBlock.ip}</Code>){" "}
            </>
          ) : null}
          is not included in the list of allowed IPs for this region&apos;s
          Network Policy.
        </BlockedText>
        <BlockedText>
          Learn more about Network Policies in our{" "}
          <TextLink href={docUrls["/docs/"]} isExternal>
            docs
          </TextLink>
          .
        </BlockedText>
        <BlockedText>
          If you believe this is an error, or you&apos;re accidentally locked
          out, please contact{" "}
          {isOrgAdmin ? (
            <SupportLink data-testid="admin-support-link">support</SupportLink>
          ) : (
            "your administrators"
          )}
          .
        </BlockedText>
      </BlockedContent>
    );
  }
  return (
    <BlockedContent
      title={
        isTrialCustomer
          ? "Your trial has ended"
          : "Your Materialize plan has lapsed"
      }
    >
      <BlockedText color={colors.foreground.secondary}>
        {isTrialCustomer
          ? "But, we can help get you back to operating in real-time in no time!"
          : "Your organization's access is currently restricted. Please contact us to reactivate your account and restore access to your environment."}
      </BlockedText>
      <Button
        size="lg"
        variant="primary"
        alignSelf="flex-end"
        onClick={() => {
          if (user) {
            const meetingType =
              // Customers are routed to a different set of people based on plan type.
              //   * Trial customers must go to the special `guided-trial` meeting group.
              //   * Everyone else should go to the default meeting group.
              isTrialCustomer ? "guided-trial" : undefined;
            chilipiper.submit({
              name: user.name,
              email: user.email,
              meetingType,
              organizationId: user.tenantId,
            });
          }
        }}
      >
        Talk with our team
      </Button>
    </BlockedContent>
  );
};

export default BlockedState;
