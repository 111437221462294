import * as Sentry from "@sentry/react";
import { useMutation } from "@tanstack/react-query";

import { buildRegionQueryKey } from "~/api/buildQueryKeySchema";
import { cancelQuery, CancelQueryParams } from "~/api/materialize/cancelQuery";

export const cancelQueryQueryKeys = {
  all: () => buildRegionQueryKey("cancelQuery"),
  connectionIdFromSessionId: (params: CancelQueryParams) =>
    [
      ...cancelQueryQueryKeys.all(),
      "connectionIdFromSessionId",
      params,
    ] as const,
  cancelQuery: (params: CancelQueryParams) =>
    [...cancelQueryQueryKeys.all(), "cancelQuery", params] as const,
};

export function useCancelQuery() {
  return useMutation({
    mutationKey: cancelQueryQueryKeys.all(),
    mutationFn: async (params: CancelQueryParams) => {
      return cancelQuery({
        params,
        getConnectionIdFromSessionIdQueryKey:
          cancelQueryQueryKeys.connectionIdFromSessionId(params),
        cancelQueryQueryKey: cancelQueryQueryKeys.cancelQuery(params),
      });
    },
    onError: (error, variables) => {
      Sentry.captureException(error, {
        extra: { variables },
      });
    },
  });
}
