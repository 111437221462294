import React from "react";
import { Navigate } from "react-router-dom";

import { appConfig } from "~/config/AppConfig";

export const RedirectIfImpersonating = ({
  children,
}: React.PropsWithChildren) => {
  if (appConfig.isImpersonating) {
    return <Navigate to="/" replace />;
  } else {
    return <>{children}</>;
  }
};
