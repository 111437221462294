import { Middleware } from "~/api/fetch";
import { appConfig } from "~/config/AppConfig";

export const impersonationCloudApiMiddleware: Middleware = async (
  request,
  next,
) => {
  if (appConfig.impersonation?.organizationId) {
    // When impersonating, we cannot pass custom header values due to
    // interactions between CORS preflight checks and Teleport. The only
    // checks that are permitted are "Simple" checks, which include only a
    // subset of known headers. We overload the `Accept` header to supply the
    // ID of the impersonated organization.
    const headers = new Headers();
    headers.set("accept", appConfig.impersonation?.organizationId);
    return await next(
      new Request(request, { headers, credentials: "include", method: "GET" }),
    );
  } else {
    return await next(request);
  }
};
