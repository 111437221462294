import {
  AnalyticsBrowser,
  Callback,
  EventProperties,
  Options,
  SegmentEvent,
} from "@segment/analytics-next";
import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";

import { appConfig } from "~/config/AppConfig";
import { useCurrentUser } from "~/queries/frontegg";

export const segment =
  appConfig.segmentApiKey !== null ? new AnalyticsBrowser() : undefined;

if (segment && appConfig.segmentApiKey !== null) {
  segment.load(
    {
      writeKey: appConfig.segmentApiKey,
    },
    {
      integrations: {
        // Use the Materialize-specific Segment proxy, which is less likely to
        // be on public ad blocking lists.
        "Segment.io": {
          apiHost: "api.segment.materialize.com/v1",
        },
      },
      retryQueue: true,
      // Use cookies first, since the work across subdomains. This ensures that logged in
      // users will correctly be track when the visit the docs and marketing site.
      storage: {
        stores: ["cookie", "localStorage", "memory"],
      },
    },
  );
}

/*
 * A React hook that returns a user-aware Segment client.
 *
 * The returned Segment client functions like Segment's standard
 * `AnalyticsBrowser` client, but calls to `track` attach the current
 * organization ID to the event.
 */
export const useSegment = segment
  ? () => {
      const { data: user } = useCurrentUser();

      const track = useCallback(
        (
          eventName: string | SegmentEvent,
          properties?: EventProperties | Callback | undefined,
          options?: Callback | Options | undefined,
          callback?: Callback | undefined,
        ) => {
          segment.track(
            eventName,
            properties,
            {
              groupId: user.tenantId,
              ...options,
            },
            callback,
          );
        },
        [user.tenantId],
      );

      return { track };
    }
  : () => ({ track: () => null });

export const useSegmentPageTracking = segment
  ? () => {
      const location = useLocation();
      const { data: user } = useCurrentUser();

      React.useEffect(() => {
        segment.page(
          undefined, // category
          undefined, // name
          {
            // Include the hash because the Frontegg admin portal uses the hash
            // for routing.
            hash: location.hash,
          },
          {
            groupId: user.tenantId,
          },
        );
      }, [location, user]);

      React.useEffect(() => {
        segment.identify(user.id);
      }, [user]);
    }
  : () => null;
