import { HStack, VStack } from "@chakra-ui/react";
import React from "react";

import { CommandBlockProps } from "~/components/CommandBlock/ReadOnlyCommandBlock";

import CommandChevron from "./CommandChevron";
import SyntaxHighlightedBlock from "./SyntaxHighlightedBlock";

// In order to match the left gutter, we take the chevron width + the stack gap
const LEFT_GUTTER = 5 + 2;

export const CommandBlockOutputContainer = ({
  command,
  commandBlockContainerProps,
  children,
}: {
  command: string;
  children: React.ReactNode;
  commandBlockContainerProps?: CommandBlockProps["containerProps"];
}) => {
  return (
    <HStack alignItems="flex-start" width="100%" pr={LEFT_GUTTER} gap="8px">
      <CommandChevron />
      <VStack spacing="3" alignItems="flex-start" flex="1" minWidth="0">
        <SyntaxHighlightedBlock
          value={command}
          containerProps={commandBlockContainerProps}
        />
        {children}
      </VStack>
    </HStack>
  );
};
