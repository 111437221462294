import React from "react";

import SearchableSelect from "~/components/SearchableSelect/SearchableSelect";
import { ClockIcon } from "~/icons";

export type TimeRangeSelectProps = {
  timeRange: number;
  setTimeRange: (val: number) => void;
};

const TimeRangeSelect = ({ timeRange, setTimeRange }: TimeRangeSelectProps) => {
  const timeRangeOptions: Record<number, string> = {
    7: "Last 7 days",
    14: "Last 14 days",
    30: "Last 30 days",
    90: "Last 90 days",
    180: "Last 180 days",
  };
  return (
    <SearchableSelect
      value={{
        id: timeRange.toString(),
        name: timeRangeOptions[timeRange],
      }}
      onChange={(value) => value && setTimeRange(parseInt(value.id))}
      isSearchable={false}
      ariaLabel="Select a time range"
      options={Object.entries(timeRangeOptions).map(([value, name]) => ({
        name,
        id: value,
      }))}
      leftIcon={<ClockIcon />}
    />
  );
};

export default TimeRangeSelect;
