export type SortOrder = "asc" | "desc";

export type SortFn<T> = (a: T, b: T) => number;

export function handleSortOrder<T>(order: SortOrder, sortFn: SortFn<T>) {
  if (order === "desc") {
    return (a: T, b: T) => sortFn(b, a);
  }
  return (a: T, b: T) => sortFn(a, b);
}
