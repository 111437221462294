import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const variantForm = definePartsStyle(({ theme }) => {
  const { colors } = theme;
  return {
    container: {
      alignItems: "start",
    },
    control: {
      marginTop: "0.2lh",
      borderColor: colors.border.primary,
      "&[data-checked]": {
        color: colors.background.primary,
        backgroundColor: colors.accent.brightPurple,
        borderColor: colors.accent.brightPurple,
      },
      "&[data-checked][data-hover]": {
        backgroundColor: colors.accent.purple,
        borderColor: colors.accent.purple,
      },
    },
    label: {
      marginInlineStart: 4,
    },
  };
});

export const Radio = defineMultiStyleConfig({
  variants: {
    form: variantForm,
  },
});
