import { Icon, IconProps, useTheme } from "@chakra-ui/react";
import React from "react";

import { MaterializeTheme } from "~/theme";

const EyeClosedIcon = (props: IconProps & { color?: string }) => {
  const { colors } = useTheme<MaterializeTheme>();

  return (
    <Icon
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={4}
      height={4}
      {...props}
    >
      <path
        d="M11.96 11.96C10.8204 12.8287 9.43273 13.3099 7.99999 13.3333C4.54338 13.3333 2.18405 10.4073 1.19292 8.88981C0.83755 8.34573 0.840165 7.65326 1.19453 7.10853C1.95939 5.9328 2.92117 4.89434 4.03999 4.04M6.59999 2.82667C7.05888 2.71925 7.5287 2.66556 7.99999 2.66667C11.4579 2.66667 13.8177 5.59489 14.8082 7.11187C15.1628 7.65505 15.1608 8.34617 14.8081 8.89065C14.5294 9.321 14.2239 9.73383 13.8933 10.1267M9.41332 9.41333C9.23022 9.60983 9.00942 9.76744 8.76409 9.87675C8.51876 9.98606 8.25392 10.0448 7.98538 10.0496C7.71684 10.0543 7.4501 10.0049 7.20106 9.90433C6.95203 9.80374 6.7258 9.65402 6.53589 9.4641C6.34597 9.27418 6.19625 9.04796 6.09566 8.79893C5.99507 8.54989 5.94567 8.28315 5.95041 8.01461C5.95515 7.74606 6.01393 7.48123 6.12324 7.2359C6.23255 6.99057 6.39016 6.76976 6.58666 6.58667"
        stroke={props.color || colors.foreground.secondary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.666656 0.666664L15.3333 15.3333"
        stroke={props.color || colors.foreground.secondary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default EyeClosedIcon;
