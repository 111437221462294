import { appConfig } from "~/config/AppConfig";

import storageAvailable from "./storageAvailable";

/**
 * We've disabled the new login flow because
 * at the time of writing, 2024/11/21, we don't have
 * the support to maintain it and have encountered too many bugs with it.
 */
const NEW_LOGIN_FLOW_ENABLED = false;

/**
 * Tracks the current tenant in sessionStorage. The key name matches the
 * Frontegg implementation, which is important, since we still use their admin panel, so
 * it may also read this value.
 */
const FRONTEGG_CURRENT_TENANT_KEY = "FRONTEGG_SEPARATE_TABS_BY_TENANT";

export function fronteggLoginDisabled() {
  return NEW_LOGIN_FLOW_ENABLED;
}

/**
 * Removes the stored tenant ID for session storage.
 */
export function clearCurrentTenantId() {
  if (storageAvailable("sessionStorage")) {
    return sessionStorage.removeItem(FRONTEGG_CURRENT_TENANT_KEY);
  }
  return null;
}

/**
 * Current tenant for this session stored in session storage.
 */
export function currentTenantId() {
  if (storageAvailable("sessionStorage")) {
    return sessionStorage.getItem(FRONTEGG_CURRENT_TENANT_KEY);
  }
  return null;
}

/**
 * Set the current tenant for this session storage.
 */
export function setCurrentTenantId(tenantId: string) {
  if (storageAvailable("sessionStorage")) {
    sessionStorage.setItem(FRONTEGG_CURRENT_TENANT_KEY, tenantId);
  }
}

/** Prepends a path the frontegg origin */
export function buildFronteggUrl(path: string) {
  return appConfig.fronteggUrl + path;
}
