import { refreshToken } from "./fronteggToken";

export type FetchRequest = (request: Request) => Promise<Response>;

export type Middleware = (
  request: Request,
  next: FetchRequest,
) => Promise<Response>;

type Handler = (index: number, request: Request) => Promise<Response>;

export function withMiddleware(...middlewares: Middleware[]) {
  const handler: Handler = async (index: number, request: Request) => {
    if (middlewares.length === index) {
      return fetch(request);
    }
    const next = middlewares[index];
    return next(request, (nextRequest) => handler(index + 1, nextRequest));
  };
  return (input: RequestInfo | URL, init?: RequestInit) =>
    handler(0, new Request(input, init));
}

export const tokenRefreshMiddleware: Middleware = async (request, next) => {
  const response = await next(request);
  if (response.status === 401) {
    const refreshResponse = await refreshToken();

    if (refreshResponse) {
      const headers = new Headers({
        ...request.headers,
        authorization: `Bearer ${refreshResponse.accessToken}`,
      });
      return next(new Request(request, { headers }));
    }
    return next(request);
  }
  return response;
};

export const fetchWithTokenRefresh = withMiddleware(tokenRefreshMiddleware);
