import { assertNoMoreThanOneRow } from "./MoreThanOneRowError";
import { assertAtLeastOneRow } from "./NoRowsError";

export function assertExactlyOneRow(
  rowCount: number,
  options?: { skipQueryRetry: boolean },
) {
  assertNoMoreThanOneRow(rowCount, {
    skipQueryRetry: options?.skipQueryRetry ?? false,
  });
  assertAtLeastOneRow(rowCount, {
    skipQueryRetry: options?.skipQueryRetry ?? false,
  });
}
