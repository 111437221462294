import { useSuspenseQuery } from "@tanstack/react-query";

import { buildGlobalQueryKey } from "~/api/buildQueryKeySchema";
import { fetchSummary } from "~/api/incident-io";

export const incidentIOQueryKeys = {
  all: () => buildGlobalQueryKey("incident-io"),
};

export function useSummary() {
  return useSuspenseQuery({
    queryKey: incidentIOQueryKeys.all(),
    refetchIntervalInBackground: true,
    refetchInterval: 60_000,
    refetchOnReconnect: true,
    queryFn: ({ signal }) => fetchSummary({ signal }),
  });
}
