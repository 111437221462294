/**
 * @module
 * Entry point for Console.
 */

// We host our own copy of Inter because the fontsource version is very out of date
import "~/font/Inter.var.woff2";
import "~/font/inter.css";
import "@fontsource/roboto-mono";
// Initializes Sentry error reporting and tracing
import "~/sentry";
// Initializes the ChiliPiper global object
if (!appConfig.isFlexibleDeployment) {
  import("@chilipiper/conciergejs-fire/dist/html/concierge");
}
import "jotai-devtools/styles.css";

import React from "react";
import { createRoot } from "react-dom/client";

import { App } from "~/platform/App";

import { appConfig } from "./config/AppConfig";
import { addChunkLoadErrorListener } from "./utils/chunkLoadErrorHandler";

const rootEl = document.createElement("div");
document.body.appendChild(rootEl);
const root = createRoot(rootEl);
root.render(<App />);

addChunkLoadErrorListener();
