import { Tag } from "@chakra-ui/react";
import * as React from "react";

import { CopyButton } from "~/components/copyableComponents";
import { appConfig } from "~/config/AppConfig";
import { withConfigCheck } from "~/hoc/withAppConfig";

const { mzConsoleImageTag } = appConfig;

const FlexibleDeploymentVersion = withConfigCheck(
  () => {
    if (!mzConsoleImageTag) return null;

    return (
      <Tag size="sm" variant="outline" opacity={0.8} fontSize="80%">
        {mzConsoleImageTag}
        <CopyButton
          contents={mzConsoleImageTag}
          size="xs"
          ml={1}
          title="Copy version"
          height="auto"
        />
      </Tag>
    );
  },
  ({ isFlexibleDeployment }) => isFlexibleDeployment,
);

export default FlexibleDeploymentVersion;
