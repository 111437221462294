import mitt from "mitt";
import React from "react";

import { EventEmitterEvents } from "~/types/charts";

import { EventEmitterContext } from "./Context";

export const EventEmitterProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const emitter = React.useMemo(() => mitt<EventEmitterEvents>(), []);
  return (
    <EventEmitterContext.Provider value={emitter}>
      {children}
    </EventEmitterContext.Provider>
  );
};
