// Global indexes
export const SELECT_MENU_Z_INDEX = 10;
export const MODAL_TOOLTIP_Z_INDEX = 1401;
export const DEVTOOL_BUTTONS_Z_INDEX = 2000;
export const STATUS_PAGE_WIDGET_Z_INDEX = 9999;

export const FIXED_TOP_BAR_Z_INDEX = 2;

export const WORKFLOW_GRAPH_CANVAS_Z_INDEX = 1;
export const WORKFLOW_GRAPH_NODE_Z_INDEX = 4;

// Used to prevent popovers from the compact navbar overflowing the main content
export const NAVBAR_Z_INDEX = 2;
export const MAIN_CONTENT_Z_INDEX = 1;
