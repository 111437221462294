import { chakra, LinkProps, Tooltip } from "@chakra-ui/react";
import React from "react";

import { InfoIcon } from "~/icons";
import docUrls from "~/mz-doc-urls.json";

const TransactionIsolationLevelTooltip = (props: LinkProps) => {
  return (
    <Tooltip
      label="The chosen isolation level determines consistency guarantees and may affect latency. Click to learn more."
      placement="end"
    >
      <chakra.a
        href={docUrls["/docs/get-started/isolation-level/"]}
        target="_blank"
        {...props}
      >
        <InfoIcon />
      </chakra.a>
    </Tooltip>
  );
};

export default TransactionIsolationLevelTooltip;
