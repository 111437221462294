import { StackProps, useTheme, VStack } from "@chakra-ui/react";
import React from "react";

import { MaterializeTheme } from "~/theme";

export const SectionNav = (props: StackProps) => {
  const { colors } = useTheme<MaterializeTheme>();

  return (
    <VStack
      bg={colors.background.secondary}
      borderRightWidth={1}
      borderColor={colors.border.primary}
      alignItems="stretch"
      alignSelf="stretch"
      height="100%"
      flexShrink="0"
      px="2"
      py="6"
      spacing="2"
      width="272px"
      {...props}
    />
  );
};
