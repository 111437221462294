import { useAtom } from "jotai";
import { useEffect, useRef } from "react";

import { getCache } from "./commandCache";
import { defaultState as defaultPromptState, promptAtom } from "./store/prompt";

/**
 * Reset the prompt and populate the history with cached commands from the region.
 */
const useResetPromptCache = (organizationId: string, regionId: string) => {
  const hasHydrated = useRef(false);
  const [, setPastPrompts] = useAtom(promptAtom);
  useEffect(() => {
    if (organizationId && !hasHydrated.current) {
      const cache = getCache(organizationId, regionId);
      setPastPrompts({
        // Use the defaultState here to start without historical baggage or
        // untracked generations.
        ...defaultPromptState,
        past: cache.commands.map((value, generation) => ({
          value,
          generation,
          originalValue: value,
        })),
        present: {
          value: "",
          generation: cache.commands.length,
        },
        currentGeneration: cache.commands.length,
      });
      hasHydrated.current = true;
    }
  }, [organizationId, regionId, setPastPrompts]);
};

export default useResetPromptCache;
