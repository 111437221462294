/**
 * A helper function take takes an explicit font size and calculates a line height that adheres to our 8px spacing grid based on this formula:
 * line-height = ceil((size * 1.3) / 8) * 8
 *
 * E.g. 12px font size would have a line-height of 16px
 */
export const lineHeightFromFontSize = (fontSize: number): string => {
  const lh = Math.ceil((fontSize * 1.3) / 8) * 8;
  return `${lh}px`;
};

/**
 * A helper function that calculates the tracking (letter spacing) for a given font size based on this formula:
 * The formula used is an exponential decay function. As the `fontSize` increases, the `tracking` decreases.
 * tracking = -0.0223 + 0.185 * pow(e, -0.1745 * size)
 */
export const trackingFromFontSize = (fontSize: number): string => {
  const tracking = -0.0223 + 0.185 * Math.pow(Math.E, -0.1745 * fontSize);
  return `${tracking}px`;
};
