import { Flex } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

import { AppErrorBoundary } from "~/components/AppErrorBoundary";

type ShellLayoutProps = PropsWithChildren;

/**
 * The layout for shell, containing the navigation bar
 * and a sticky footer.
 *
 */
export const ShellLayout = (props: ShellLayoutProps) => {
  return (
    <AppErrorBoundary>
      <Flex id="shell" flex="1" minHeight="0" position="relative">
        {props.children}
      </Flex>
    </AppErrorBoundary>
  );
};
