import {
  CSSReset,
  EnvironmentProvider,
  GlobalStyle,
  ThemeProvider,
  ToastProvider,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";

import { darkTheme, lightTheme } from "~/theme";

export const ChakraProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const mode = useColorMode();
  const theme = mode.colorMode === "dark" ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <CSSReset />
      <EnvironmentProvider>
        {children}
        <ToastProvider />
      </EnvironmentProvider>
    </ThemeProvider>
  );
};
