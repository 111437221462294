import { HealthStatus } from "~/platform/connectors/utils";
import { useQueryStringState } from "~/useQueryString";

export const useConnectorHealthFilter = () => {
  const [selectedHealthStatus, setSelectedHealthStatus] =
    useQueryStringState("health");
  const defaultValue = "all";

  return {
    selected: (selectedHealthStatus as HealthStatus) ?? defaultValue,
    setSelected: setSelectedHealthStatus,
  };
};

export type UseConnectorHealthFilter = ReturnType<
  typeof useConnectorHealthFilter
>;
