import {
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Text,
  useTheme,
} from "@chakra-ui/react";
import dagre from "@dagrejs/dagre";
import React from "react";

import { useCamera } from "~/hooks/useCamera";
import { useDrag } from "~/hooks/useDrag";
import { WORKFLOW_GRAPH_CANVAS_Z_INDEX } from "~/layouts/zIndex";
import FrameSelectionIcon from "~/svg/FrameSelectionIcon";
import ZoomInIcon from "~/svg/ZoomInIcon";
import ZoomOutIcon from "~/svg/ZoomOutIcon";
import { MaterializeTheme } from "~/theme";

import { SIDEBAR_WIDTH } from "./Sidebar";

export interface CanvasProps {
  width: number | undefined;
  height: number | undefined;
  selectedNode: dagre.Node | null;
}

/**
 * Margin around the graph
 *
 * We use this in two ways:
 * 1) to set the minimum absolute top and left values
 * 2) added to the height and width of the graph element
 */
export const GRAPH_MARGIN = 40;

export const Canvas = ({
  height,
  width,
  selectedNode,
  children,
}: React.PropsWithChildren<CanvasProps>) => {
  const {
    camera,
    cameraRef,
    formatZoomValue,
    panBy,
    panTo,
    resetZoom,
    cssTransform,
    zoomIn,
    zoomOut,
  } = useCamera();

  useDrag({
    ref: cameraRef,
    onStart: () => {
      cameraRef.current?.style.setProperty("cursor", "grabbing");
    },
    onStop: () => {
      cameraRef.current?.style.removeProperty("cursor");
    },
    onDrag: (_, { pointDelta }) => {
      if (!pointDelta) return;
      panBy(-pointDelta.x, -pointDelta.y);
    },
  });

  const { height: viewportHeight, width: viewportWidth } = camera;
  const { colors, shadows } = useTheme<MaterializeTheme>();

  const panToSelectedNode = React.useCallback(() => {
    if (!selectedNode || !viewportWidth || !viewportHeight || !height || !width)
      return;

    // Dagre node x and y are center points
    const { y: nodeY, x: nodeX } = selectedNode;

    const yOffset = nodeY - height / 2;
    const xOffset = nodeX - width / 2;

    panTo(-xOffset, -yOffset);
  }, [height, panTo, selectedNode, viewportHeight, viewportWidth, width]);

  React.useEffect(() => {
    panToSelectedNode();
  }, [panToSelectedNode]);

  return (
    <>
      <HStack
        zIndex={WORKFLOW_GRAPH_CANVAS_Z_INDEX}
        position="absolute"
        bottom={4}
        right={SIDEBAR_WIDTH + 16}
        spacing="0"
        background={colors.background.primary}
        border="1px solid"
        borderColor={colors.border.primary}
        borderRadius="md"
        shadow={shadows.level1}
        boxSizing="border-box"
        width="fit-content"
        overflow="hidden"
      >
        <IconButton
          variant="inline"
          aria-label="Reset zoom"
          icon={<FrameSelectionIcon />}
          onClick={() => {
            panToSelectedNode();
            resetZoom();
          }}
        />
        <IconButton
          variant="inline"
          aria-label="Zoom in"
          icon={<ZoomInIcon />}
          onClick={() => {
            zoomIn();
          }}
        />
        <IconButton
          variant="inline"
          aria-label="Zoom out"
          icon={<ZoomOutIcon />}
          onClick={() => {
            zoomOut();
          }}
        />
        <Center w={6}>
          <Divider
            orientation="vertical"
            color={colors.border.secondary}
            height="24px"
          />
        </Center>
        <Text
          textStyle="text-ui-med"
          color={colors.foreground.primary}
          pl="1"
          pr="5"
          minW="16"
          textAlign="center"
        >
          {formatZoomValue(camera.z)}
        </Text>
      </HStack>
      <Flex
        ref={cameraRef as React.MutableRefObject<HTMLDivElement>}
        top="0"
        left="0"
        right={SIDEBAR_WIDTH}
        bottom="0"
        display="grid"
        placeItems="center"
        position="absolute"
        overflow="hidden"
        scrollBehavior="smooth"
        background={`radial-gradient(${colors.border.secondary} 1px, ${colors.background.primary} 1px) 0px 0px / 24px 24px`}
        _hover={{ cursor: "grab" }}
      >
        {height && width ? (
          <Flex
            style={{
              // We explicitly set this as a style property otherwise everytime this property changes,
              // a new stylesheet is created
              transform: cssTransform,
            }}
            position="absolute"
            width={width}
            height={height}
          >
            {children}
          </Flex>
        ) : null}
      </Flex>
    </>
  );
};
