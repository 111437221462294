/**
 * @module
 * API authentication support.
 */

import { useQuery } from "@tanstack/react-query";

import { Tenant, User } from "~/api/frontegg/types";
import { appConfig } from "~/config/AppConfig";
import { useCurrentUser } from "~/queries/frontegg";

import { buildGlobalQueryKey } from "./buildQueryKeySchema";
import { getCurrentOrganization } from "./cloudGlobalApi";
import { OpenApiRequestOptions } from "./types";

export const ADMIN_ROLE_KEY = "MaterializePlatformAdmin";

export const queryKeys = {
  currentOrganization: (organizationId: string) => [
    buildGlobalQueryKey("global-api"),
    "current-organization",
    // organizationId is here so that the query is automatically invalidated when we
    // switch tenants
    organizationId,
  ],
};

export function useIsSuperUser() {
  const { data: user } = useCurrentUser();
  return user && isSuperUser(user);
}

function hasPermission(user: User | null | undefined, key: string): boolean {
  return !!user && !!user.permissions.find((p) => p === key);
}

export function hasEnvironmentReadPermission(user: User | null): boolean {
  return hasPermission(user, "materialize.environment.read");
}

export function hasEnvironmentWritePermission(user: User | null): boolean {
  return hasPermission(user, "materialize.environment.write");
}

export function hasTenantApiTokenPermissions(user: User | null): boolean {
  return [
    "fe.secure.read.tenantApiTokens",
    "fe.secure.write.tenantApiTokens",
    "fe.secure.delete.tenantApiTokens",
  ].every((permission) => hasPermission(user, permission));
}

export function hasInvoiceReadPermission(
  user: User | null | undefined,
): boolean {
  return (
    appConfig.hasBillingPermissionOverride ||
    hasPermission(user, "materialize.invoice.read")
  );
}

export function isSuperUser(user: User | null): boolean {
  return !!user && Boolean(user.roles.find((r) => r === ADMIN_ROLE_KEY));
}

export function getCurrentTenant(user: User, tenants: Tenant[]): Tenant {
  const tenant = tenants.find((t) => t.tenantId === user.tenantId);
  if (!tenant) {
    throw new Error(`Unknown tenant: ${user.tenantId}`);
  }
  return tenant;
}

export async function fetchCurrentOrganization({
  signal,
}: { signal?: OpenApiRequestOptions["signal"] } = {}) {
  const result = await getCurrentOrganization({ signal });
  return result.data;
}

export function useCurrentOrganization() {
  const { data: user } = useCurrentUser();
  const {
    data: organization,
    error,
    isLoading: loading,
  } = useQuery({
    queryKey: queryKeys.currentOrganization(user.tenantId),
    queryFn: fetchCurrentOrganization,
  });
  return { organization, loading, error };
}

export function useCanAccessTenantApiTokens() {
  const { data: user } = useCurrentUser();
  return hasTenantApiTokenPermissions(user);
}

export function useCanViewUsage() {
  const { data: user } = useCurrentUser();
  const { organization } = useCurrentOrganization();
  return (
    hasInvoiceReadPermission(user) &&
    ((organization?.subscription?.type &&
      ["capacity", "on-demand"].includes(organization.subscription.type)) ||
      appConfig.hasBillingPermissionOverride)
  );
}
