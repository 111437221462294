import { QueryClient } from "@tanstack/react-query";

import { appConfig } from "~/config/AppConfig";

export function buildQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: (failureCount, error) => {
          // Retries are disabled in test
          if (!appConfig.queryRetriesEnabled) return false;
          if (error.cause && (error.cause as any).status === 401) {
            // When a request is unauthorized, we want to fail fast and refresh the token.
            // In practice, the token should be refreshed by the middleware, so we should
            // only see this on the actual refresh token call which doesn't have the
            // middleware.
            return false;
          }
          // Some custom error have a `skipQueryRetry` field to skip retries,
          // e.g. NoRowsError.
          if (
            error instanceof Error &&
            "skipQueryRetry" in error &&
            error.skipQueryRetry
          ) {
            return false;
          }
          return failureCount < 3;
        },
      },
    },
  });
}

let queryClient = buildQueryClient();

export const resetQueryClient = () => {
  queryClient = buildQueryClient();
};

export const getQueryClient = () => queryClient;
