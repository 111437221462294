import { Code, StackProps, useTheme, VStack } from "@chakra-ui/react";
import React from "react";

import { Error as MaterializeError } from "~/api/materialize/types";
import { MaterializeTheme } from "~/theme";

const ErrorOutput = ({
  error,
  errorMessageOverride,
  ...props
}: { error: MaterializeError; errorMessageOverride?: string } & StackProps) => {
  const { colors } = useTheme<MaterializeTheme>();
  const messages = [errorMessageOverride ?? `Error: ${error.message}`];
  if (error.detail) {
    messages.push(`Detail: ${error.detail}`);
  }
  if (error.hint) {
    messages.push(`Hint: ${error.hint}`);
  }

  return (
    <VStack
      alignItems="flex-start"
      borderRadius="lg"
      borderWidth="1px"
      borderColor={colors.border.secondary}
      p="4"
      {...props}
    >
      {messages.map((message, index) => (
        <Code key={index} whiteSpace="pre-wrap">
          {message}
        </Code>
      ))}
    </VStack>
  );
};

export default ErrorOutput;
