import createClient from "openapi-fetch";

import { getFronteggToken } from "~/api/fronteggToken";
import { consoleVersionHeaders } from "~/version/api";

import { fetchWithTokenRefresh } from "./fetch";
import {
  handleOpenApiResponse,
  handleOpenApiResponseWithBody,
} from "./openApiUtils";
import { components, paths } from "./schemas/region-api";
import { OpenApiRequestOptions } from "./types";

export type Region =
  | components["schemas"]["Region"]
  | { regionInfo?: null; regionState: "disabled" };
export type RegionInfo = NonNullable<Region["regionInfo"]>;
export type RegionRequest = components["schemas"]["RegionRequest"];

function buildHeaders(headers?: HeadersInit) {
  return {
    authorization: `Bearer ${getFronteggToken()}`,
    ...consoleVersionHeaders(),
    ...headers,
  };
}

export async function createRegion(
  baseUrl: string,
  body: RegionRequest = {},
  requestOptions: OpenApiRequestOptions = {},
) {
  const { headers, ...options } = requestOptions;
  const client = createClient<paths>({
    baseUrl,
    fetch: fetchWithTokenRefresh,
  });
  const { data, response } = await client.PATCH("/api/region", {
    headers: buildHeaders({
      "x-materialize-api-version": "1",
      ...headers,
    }),
    ...options,
    body,
  });
  return handleOpenApiResponse(data, response);
}

export async function getRegion(
  baseUrl: string,
  requestOptions: OpenApiRequestOptions = {},
): Promise<ReturnType<typeof handleOpenApiResponseWithBody<Region>>> {
  const { headers, ...options } = requestOptions;
  const client = createClient<paths>({
    baseUrl,
    fetch: fetchWithTokenRefresh,
  });
  const { data, response } = await client.GET("/api/region", {
    headers: {
      authorization: `Bearer ${getFronteggToken()}`,
      ...headers,
    },
    ...options,
  });
  if (response.status === 204) {
    return { data: { regionState: "disabled" as const }, ...response };
  }
  return handleOpenApiResponseWithBody(data, response);
}
