import { useSteps } from "@chakra-ui/react";
import { useCallback } from "react";

import { WizardStep } from "~/components/formComponentsV2";

export default function useNormalizedSteps({
  initialSteps = [],
  sourceSteps,
  indexOffset,
}: {
  initialSteps?: WizardStep[];
  sourceSteps: WizardStep[];
  indexOffset?: number;
}) {
  const wizardSteps = useSteps({
    index: initialSteps.length + (indexOffset ?? 0),
    count: sourceSteps.length,
  });
  const { setActiveStep } = wizardSteps;

  // Determine if the active step matches the corresponding index of the route-dependent steps.
  const isNormalizedStep = (stepIdx: number): boolean => {
    const activeStep = wizardSteps.activeStep - initialSteps.length;
    return activeStep === stepIdx;
  };

  const setNormalizedActiveStep = useCallback(
    (stepIdx: number): void => {
      const targetIdx = initialSteps.length + stepIdx;
      return setActiveStep(targetIdx);
    },
    [setActiveStep, initialSteps],
  );

  return {
    isNormalizedStep,
    setNormalizedActiveStep,
    steps: [...initialSteps, ...sourceSteps],
    wizardSteps,
  };
}
