import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const ActivityIcon = (props: IconProps) => {
  return (
    <Icon
      width="4"
      height="4"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#949197"
      {...props}
    >
      <g id="Activity">
        <rect
          id="Rectangle 198"
          x="3"
          y="2"
          width="10"
          height="12"
          rx="1"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector 112"
          d="M5 5H7"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector 113"
          d="M5 8H11"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector 114"
          d="M5 11H11"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Icon>
  );
};

export default ActivityIcon;
