import { prettyStr } from "@materializeinc/sql-pretty";
import * as Sentry from "@sentry/react";
import React from "react";

import { PermissionError } from "~/api/materialize/DatabaseError";
import { ShowCreateObjectType } from "~/api/materialize/showCreate";
import Alert from "~/components/Alert";
import { useShowCreate } from "~/queries/showCreate";

import { ExpandableCodeBlock } from "./ExpandableCodeBlock";
import { ExpectedErrorBoundary } from "./ExpectedErrorBoundary";

export interface ShowCreateBlockProps {
  databaseName: string | null;
  schemaName: string;
  name: string;
  objectType: ShowCreateObjectType;
}

function tryPrettyStr(sql: string, width: number) {
  try {
    return prettyStr(sql, width);
  } catch (e) {
    Sentry.captureException(e);
    return sql;
  }
}

export const ShowCreateBlock = (props: ShowCreateBlockProps) => {
  if (!props.databaseName) return null;

  return (
    <ExpectedErrorBoundary
      onError={(error) => {
        // Rethrow anything besides a permission error
        if (!(error instanceof PermissionError)) {
          throw error;
        }
      }}
      fallback={
        <Alert
          width="100%"
          variant="info"
          message="You don't have usage permission on this schema."
        />
      }
    >
      <ShowCreateBlockInner {...props} />
    </ExpectedErrorBoundary>
  );
};

export const ShowCreateBlockInner = ({
  databaseName,
  schemaName,
  name,
  objectType,
}: ShowCreateBlockProps) => {
  const showCreateParams = React.useMemo(
    () => ({
      objectType,
      object: { databaseName, schemaName, name },
    }),
    [databaseName, name, schemaName, objectType],
  );
  const { data: showCreate } = useShowCreate(showCreateParams);

  return <ExpandableCodeBlock text={tryPrettyStr(showCreate.sql, 100)} />;
};
