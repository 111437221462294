import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const RedpandaLogoIcon = (props: IconProps) => {
  return (
    <Icon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        d="M20.9632 20.5838C20.5803 19.8866 20.235 19.1693 19.9289 18.4349C21.0665 17.9375 22.0914 17.2134 22.9414 16.3067C24.2713 15.0031 24.9361 13.1617 24.9361 10.7824C24.9361 8.02434 24.0359 5.88103 22.2355 4.35244C20.4235 2.82053 18.116 2.00516 15.7468 2.05956H4.19336V28.405H9.81615V18.9371H11.9544L13.2596 18.9042C13.7439 20.0856 14.8233 22.6831 15.4513 24.0662L20.9632 20.5838ZM9.81615 7.31632H14.9915C17.5909 7.31632 18.8906 8.36872 18.8906 10.4736C18.8906 12.7349 17.4405 13.8642 14.5401 13.8616H9.81615V7.31632Z"
        fill="#E2401B"
      />
      <path
        d="M26.7752 26.5115L25.3018 25.4659L19.207 29.426L21.3412 30.2575L25.0596 30.2493L27.7561 28.3228L26.7752 26.5115Z"
        fill="#E2401B"
      />
      <path
        d="M21.9015 22.111L16.25 25.6799L17.8384 28.1746L23.8511 24.375L21.9015 22.111Z"
        fill="#E2401B"
      />
    </Icon>
  );
};

export default RedpandaLogoIcon;
