import { BuildConstants } from "./buildConstants";

export const getCloudGlobalApiUrl = ({
  stack,
  isImpersonation,
}: {
  stack: string;
  isImpersonation: boolean;
}) => {
  if (stack === "local") {
    return "http://127.0.0.1:8003";
  }
  if (isImpersonation) {
    if (window.location.hash === "#staging") {
      console.warn("Detected request for staging APIs");
      stack = "staging";
    }
    return `https://global-api-aws-us-east-1-${stack.replaceAll(
      ".",
      "-",
    )}.materialize.teleport.sh`;
  }
  if (stack === "production") {
    return "https://api.cloud.materialize.com";
  }
  return `https://api.${stack}.cloud.materialize.com`;
};

export const getFronteggUrl = (stack: string) => {
  if (stack === "production") {
    return `https://admin.cloud.materialize.com`;
  }
  if (stack === "local") {
    // local development against cloud services uses staging frontegg
    return `https://admin.staging.cloud.materialize.com`;
  }
  return `https://admin.${stack}.cloud.materialize.com`;
};

export const getEnvironmentdScheme = ({
  buildConstants,
  isLocalImpersonation,
}: {
  buildConstants: BuildConstants;
  isLocalImpersonation: boolean;
}): ["http" | "https", "ws" | "wss"] => {
  // flexible deployment doesn't support SSL
  if (
    buildConstants.consoleDeploymentMode === "flexible-deployment" ||
    isLocalImpersonation ||
    // SQL testing with mzcompose uses http
    process.env.NODE_ENV === "test"
  ) {
    if (location.protocol === "https:") {
      return ["https", "wss"];
    }
    return ["http", "ws"];
  }
  // local development via staging and production use https
  return ["https", "wss"];
};
