import { useDatePicker } from "@rehookify/datepicker";

/**
 *
 * @param selectedDates: A list of dates of length 2 that are currently selected.
 *  The first element is always the earliest date.
 * @param onDatesChange: A callback that will be called when the user selects a date
 */
export const useRangeDatePicker = ({
  selectedDates,
  onDatesChange,
  maxDate,
  minDate,
}: {
  selectedDates: Date[];
  onDatesChange: (d: Date[]) => void;
  maxDate: Date;
  minDate: Date;
}) => {
  const datePickerState = useDatePicker({
    selectedDates,
    onDatesChange,
    calendar: {
      mode: "fluid",
    },
    dates: {
      mode: "range",
      selectSameDate: true,
      maxDate,
      minDate,
    },
  });

  return datePickerState;
};
