import { useToast } from "@chakra-ui/toast";
import * as Sentry from "@sentry/react";
import { useAtom } from "jotai";
import React from "react";

import { useFlags } from "~/hooks/useFlags";
import { currentEnvironmentState } from "~/store/environments";

const ENVIRONMENT_ERROR_TOAST_ID = "environment-error";

export const useShowEnvironmentErrors = () => {
  const flags = useFlags();
  const logDetails = flags["layout-environment-health-details"];
  const [currentEnvironment] = useAtom(currentEnvironmentState);
  const toast = useToast();

  React.useEffect(() => {
    if (!currentEnvironment) return;

    const regionErrors = currentEnvironment.errors;
    const environmentErrors =
      currentEnvironment.state === "enabled"
        ? currentEnvironment.status.errors
        : [];

    const isBlocked =
      currentEnvironment.state === "enabled" &&
      currentEnvironment.status.health === "blocked";

    if (logDetails) {
      for (const error of regionErrors) {
        console.error(error.message, error.details);
      }
      for (const error of environmentErrors) {
        console.error(error.message, error.details);
      }
    }
    if (environmentErrors.length > 0 && !isBlocked) {
      Sentry.captureException(new Error("Health check failed"), {
        extra: {
          regionErrors,
          environmentErrors,
        },
      });
      if (!toast.isActive(ENVIRONMENT_ERROR_TOAST_ID)) {
        toast({
          id: ENVIRONMENT_ERROR_TOAST_ID,
          position: "bottom-right",
          duration: null, // never dismiss
          title: "Error",
          description: "We're having trouble reaching your environment",
          status: "warning",
          isClosable: true,
        });
      }
    } else {
      toast.close(ENVIRONMENT_ERROR_TOAST_ID);
    }
  }, [currentEnvironment, logDetails, toast]);
};
