import { SinkStatisticsRow } from "../queries";

export function calculateRate(
  current: SinkStatisticsRow | undefined,
  previous: SinkStatisticsRow | undefined,
  key: keyof Omit<SinkStatisticsRow["data"], "id">,
) {
  if (current === undefined || previous === undefined) return null;

  const timeDiff = current.mzTimestamp - previous.mzTimestamp;
  const currentDatum = current.data[key];
  const previousDataum = previous.data[key];
  if (currentDatum === null || previousDataum === null) return null;

  const result = ((currentDatum - previousDataum) / timeDiff) * 1000;
  if (Number.isNaN(result)) return 0;
  if (result < 0) return 0;
  return result;
}
