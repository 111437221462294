import React from "react";

import { ConnectorErrorGraph } from "~/components/ConnectorErrorsGraph";

import { useBucketedSinkErrors } from "./queries";

export interface Props {
  sinkId: string;
  timePeriodMinutes: number;
}

const SinkErrorsGraph = ({ sinkId, timePeriodMinutes }: Props) => {
  const bucketSizeSeconds = React.useMemo(() => {
    return (timePeriodMinutes / 15) * 60;
  }, [timePeriodMinutes]);
  const { data } = useBucketedSinkErrors({
    sinkId,
    timePeriodMinutes,
    bucketSizeSeconds,
  });

  const bucketSizeMs = bucketSizeSeconds * 1000;

  return (
    <ConnectorErrorGraph
      startTime={data.startTime}
      endTime={data.endTime}
      connectorStatuses={data.rows}
      bucketSizeMs={bucketSizeMs}
      timePeriodMinutes={timePeriodMinutes}
    />
  );
};

export default SinkErrorsGraph;
