import { Box, BoxProps, useTheme } from "@chakra-ui/react";
import React from "react";

import ChevronRightIcon from "~/svg/ChevronRightIcon";
import { MaterializeTheme } from "~/theme";

type CommandChevronProps = BoxProps;

const CommandChevron = (props: CommandChevronProps) => {
  const { colors } = useTheme<MaterializeTheme>();

  return (
    <Box
      {...props}
      fontSize="lg"
      lineHeight="5"
      height="5"
      color={props.color ?? colors.accent.purple}
      userSelect="none"
    >
      <ChevronRightIcon
        height="5"
        width="5"
        color={colors.accent.brightPurple}
      />
    </Box>
  );
};

export default CommandChevron;
