import { QueryKey } from "@tanstack/react-query";
import { InferResult } from "kysely";

import { queryBuilder } from "./db";
import { executeSqlV2 } from "./executeSqlV2";
import { getOwners } from "./expressionBuilders";

export function buildAllObjectsQuery() {
  return queryBuilder
    .selectFrom("mz_object_fully_qualified_names as ofqn")
    .innerJoin("mz_objects as o", "o.id", "ofqn.id")
    .leftJoin("mz_sources as s", "s.id", "ofqn.id")
    .select([
      "ofqn.id",
      "ofqn.name",
      "ofqn.object_type as objectType",
      "ofqn.schema_id as schemaId",
      "ofqn.schema_name as schemaName",
      "ofqn.database_id as databaseId",
      "ofqn.database_name as databaseName",
      "s.type as sourceType",
      "o.cluster_id as clusterId",
    ])
    .where("ofqn.object_type", "<>", "function");
}

export type DatabaseObject = InferResult<
  ReturnType<typeof buildAllObjectsQuery>
>[0];

export type IsOwnerParameters = {
  objectId: string;
};

export function buildIsOwnerQuery(params: IsOwnerParameters) {
  return queryBuilder
    .selectFrom("mz_objects as o")
    .innerJoin(getOwners().as("owners"), "owners.id", "o.owner_id")
    .where("o.id", "=", params.objectId)
    .select("owners.isOwner");
}

export function fetchIsOwner({
  parameters,
  queryKey,
  requestOptions,
}: {
  parameters: IsOwnerParameters;
  queryKey: QueryKey;
  requestOptions?: RequestInit;
}) {
  const compiledQuery = buildIsOwnerQuery(parameters).compile();
  return executeSqlV2({
    queries: compiledQuery,
    queryKey: queryKey,
    requestOptions,
  });
}
