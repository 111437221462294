import { QueryKey } from "@tanstack/react-query";
import { sql } from "kysely";

import { kebabToScreamingSpaceCase } from "~/util";

import { buildFullyQualifiedObjectName, executeSqlV2, queryBuilder } from ".";

export const SHOW_CREATE_OBJECT_TYPES = [
  "index",
  "connection",
  "view",
  "materialized-view",
  "source",
  "sink",
  "table",
] as const;

export type ShowCreateObjectType = (typeof SHOW_CREATE_OBJECT_TYPES)[number];

export type ShowCreateStatementParameters = {
  objectType: ShowCreateObjectType;
  object: {
    databaseName: string | null;
    schemaName: string;
    name: string;
  };
};

export function buildShowCreateStatement(
  parameters: ShowCreateStatementParameters,
) {
  const type = sql.raw(kebabToScreamingSpaceCase(parameters.objectType));
  return sql<{
    sql: string;
  }>`SELECT create_sql AS sql FROM (SHOW CREATE ${type} ${buildFullyQualifiedObjectName(
    parameters.object,
  )})`;
}

export function fetchShowCreate(
  queryKey: QueryKey,
  parameters: ShowCreateStatementParameters,
  requestOptions?: RequestInit,
) {
  return executeSqlV2({
    queries: buildShowCreateStatement(parameters).compile(queryBuilder),
    queryKey,
    requestOptions,
  });
}
