import { MenuItem, useColorMode, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const ThemeSwitcher = () => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue("dark", "light");
  return (
    <MenuItem
      as={RouterLink}
      fontWeight="medium"
      onClick={() => toggleColorMode()}
    >
      Switch to {text} theme
    </MenuItem>
  );
};

export default ThemeSwitcher;
