import { Alert, CloseButton, Flex, Text, useTheme } from "@chakra-ui/react";
import { AdminPortal } from "@frontegg/react";
import { addDays } from "date-fns";
import * as React from "react";

import { isSuperUser } from "~/api/auth";
import { withConfigCheck } from "~/hoc/withAppConfig";
import useLocalStorage from "~/hooks/useLocalStorage";
import { useCurrentUser, useMfaPolicy, usePrelogin } from "~/queries/frontegg";
import { MaterializeTheme } from "~/theme";

import TextLink from "./TextLink";

export const MFA_DISMISSED_KEY = "mz-mfa-alert-dismissed";

export const MfaAlert = withConfigCheck(
  () => {
    const { colors } = useTheme<MaterializeTheme>();
    const { data: user } = useCurrentUser();
    const { data: mfaPolicy } = useMfaPolicy();
    const { data: preloginResult } = usePrelogin({ email: user.email });
    const [dismissedAt, setDismissedAt] = useLocalStorage<Date | null>(
      MFA_DISMISSED_KEY,
      null,
    );

    // Only show this banner to org admins
    if (!isSuperUser(user)) return null;

    // If this is an object, they have SSO enabled, so we won't prompt them to enable MFA
    if (preloginResult) return null;

    // Don't show if MFA is already required
    if (
      mfaPolicy.enforceMFAType === "Force" ||
      mfaPolicy.enforceMFAType === "ForceExceptSAML"
    ) {
      return null;
    }

    if (dismissedAt && new Date() < addDays(dismissedAt, 30)) {
      return null;
    }

    return (
      <Alert
        backgroundColor={colors.background.info}
        borderTopWidth="1px"
        borderTopColor={colors.border.info}
        borderBottomWidth="1px"
        borderBottomColor={colors.border.info}
        py={2}
        fontSize="sm"
        lineHeight="20px"
        color={colors.foreground.primary}
        status="info"
        justifyContent="center"
        data-testid="mfa-required-alert"
        flexShrink="0"
      >
        <Flex justifyContent="center" width="100%">
          <Text>
            Please{" "}
            <TextLink
              href={`${location.href}#/admin-box/security/mfa`}
              onClick={(e) => {
                e.preventDefault();
                location.hash = "/admin-box/security/mfa";
                AdminPortal.show();
              }}
            >
              require MFA
            </TextLink>{" "}
            for your organization.
          </Text>
        </Flex>
        <CloseButton
          position="relative"
          right="0"
          size="sm"
          onClick={() => {
            setDismissedAt(new Date());
          }}
        />
      </Alert>
    );
  },
  ({ hasAuthProvider }) => hasAuthProvider,
);
