import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const PostgresLogoIcon = (props: IconProps) => {
  return (
    <Icon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        d="M22.5856 21.9282C22.7596 20.4776 22.7075 20.2649 23.7875 20.4999L24.0617 20.5239C24.8921 20.5618 25.9785 20.3903 26.6167 20.0937C27.9906 19.456 28.8052 18.3911 27.4505 18.6709C24.3604 19.3087 24.1479 18.2619 24.1479 18.2619C27.411 13.4183 28.775 7.26992 27.5976 5.76492C24.3863 1.6598 18.8275 3.60102 18.7345 3.65142L18.7049 3.65689C18.0943 3.53015 17.411 3.45443 16.6434 3.44201C15.245 3.41902 14.1839 3.80877 13.379 4.41953C13.379 4.41953 3.46248 0.332303 3.92381 9.55964C4.02193 11.5226 6.73625 24.4127 9.97359 20.5193C11.1568 19.0956 12.3003 17.8918 12.3003 17.8918C12.8681 18.2692 13.5479 18.4617 14.2609 18.3926L14.316 18.3455C14.2987 18.5223 14.3064 18.6952 14.3381 18.8999C13.5042 19.8321 13.7492 19.9958 12.0819 20.3391C10.395 20.687 11.386 21.3062 12.0329 21.468C12.8174 21.6643 14.632 21.9423 15.8585 20.225L15.8097 20.4209C16.1368 20.6828 16.1147 22.3029 16.1611 23.4605C16.2075 24.6181 16.285 25.6983 16.5209 26.3352C16.7567 26.9719 17.0351 28.6124 19.2264 28.1426C21.0578 27.75 22.458 27.1849 22.5856 21.9282Z"
        fill="black"
        stroke="black"
        strokeWidth="4.41189"
      />

      <path
        d="M27.4492 18.6708C24.3587 19.3085 24.1463 18.2617 24.1463 18.2617C27.4093 13.4179 28.7733 7.26934 27.5962 5.76451C24.3849 1.65964 18.8258 3.60098 18.7331 3.65138L18.7032 3.65673C18.0926 3.52993 17.4093 3.45439 16.6414 3.44185C15.243 3.41893 14.1823 3.80861 13.3774 4.41925C13.3774 4.41925 3.46064 0.332207 3.92185 9.55948C4.01997 11.5225 6.73423 24.4127 9.97169 20.5192C11.155 19.0956 12.2983 17.8918 12.2983 17.8918C12.8661 18.2692 13.5459 18.4617 14.2586 18.3925L14.314 18.3455C14.2968 18.5223 14.3046 18.6952 14.3362 18.8999C13.5021 19.8321 13.7472 19.9958 12.08 20.3391C10.393 20.6869 11.384 21.3062 12.0311 21.468C12.8155 21.6643 14.6303 21.9423 15.8566 20.225L15.8077 20.4209C16.1344 20.6827 16.3639 22.1241 16.3255 23.4307C16.287 24.7374 16.2613 25.6345 16.5188 26.3351C16.7763 27.0358 17.0329 28.6124 19.2246 28.1426C21.056 27.7499 22.0051 26.7325 22.1371 25.0352C22.2308 23.8286 22.4428 24.007 22.4562 22.9283L22.6263 22.4176C22.8224 20.7819 22.6574 20.2543 23.7857 20.4997L24.0599 20.5238C24.8904 20.5616 25.9773 20.3902 26.6152 20.0935C27.9888 19.4558 28.8035 18.391 27.4491 18.6708H27.4492Z"
        fill="#336791"
      />
      <path
        d="M15.9893 19.6084C15.9042 22.6518 16.0107 25.7165 16.3083 26.4613C16.6062 27.2061 17.2436 28.6548 19.4355 28.1851C21.2667 27.7923 21.933 27.0323 22.2221 25.3546C22.4351 24.1203 22.8456 20.6923 22.8983 19.99"
        stroke="white"
        strokeWidth="1.47063"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3625 4.35137C13.3625 4.35137 3.43903 0.293764 3.90037 9.52104C3.99848 11.4841 6.71292 24.3747 9.95032 20.481C11.1334 19.0572 12.2033 17.9404 12.2033 17.9404"
        stroke="white"
        strokeWidth="1.47063"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7234 3.6107C18.3799 3.71845 24.2435 1.46629 27.5758 5.72611C28.7529 7.23099 27.3889 13.3795 24.1259 18.2235"
        stroke="white"
        strokeWidth="1.47063"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1248 18.2235C24.1248 18.2235 24.3374 19.2706 27.4279 18.6324C28.782 18.3526 27.9671 19.4176 26.5938 20.0556C25.4667 20.5789 22.9398 20.713 22.8985 19.9899C22.7923 18.1242 24.2284 18.691 24.1248 18.2235ZM24.1248 18.2235C24.0311 17.8025 23.3889 17.3892 22.964 16.3588C22.5931 15.4594 17.8772 8.56216 24.2718 9.58652C24.5061 9.53802 22.6041 3.50146 16.6196 3.4033C10.6365 3.30515 10.8328 10.7644 10.8328 10.7644"
        stroke="white"
        strokeWidth="1.47063"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M14.3159 18.8616C13.4817 19.7937 13.727 19.9574 12.0597 20.3009C10.3727 20.6487 11.3638 21.2679 12.0107 21.4296C12.7952 21.626 14.61 21.904 15.8362 20.1863C16.2096 19.6633 15.834 18.8288 15.321 18.6161C15.0732 18.5134 14.7418 18.3847 14.3159 18.8616Z"
        stroke="white"
        strokeWidth="1.47063"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2604 18.8453C14.1763 18.2972 14.4404 17.645 14.7234 16.882C15.1486 15.7372 16.1299 14.5922 15.3449 10.9606C14.7599 8.25445 10.8356 10.3974 10.8332 10.7644C10.8308 11.1312 11.0107 12.6243 10.7676 14.363C10.4505 16.6318 12.2107 18.5506 14.2376 18.3543"
        stroke="white"
        strokeWidth="1.47063"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3271 10.7094C13.3094 10.8347 13.5564 11.1691 13.8785 11.2138C14.2001 11.2587 14.4753 10.9973 14.4928 10.8721C14.5102 10.7468 14.2635 10.6087 13.9413 10.5639C13.6195 10.5189 13.3441 10.5843 13.3271 10.7094H13.3271Z"
        fill="white"
        stroke="white"
        strokeWidth="0.490206"
      />
      <path
        d="M23.1221 10.4538C23.1395 10.5792 22.8928 10.9136 22.5706 10.9583C22.2488 11.0031 21.9735 10.7418 21.9559 10.6166C21.9389 10.4913 22.1857 10.3532 22.5076 10.3083C22.8294 10.2635 23.1046 10.3288 23.1221 10.4539V10.4538Z"
        fill="white"
        stroke="white"
        strokeWidth="0.245103"
      />
      <path
        d="M24.2727 9.58655C24.3258 10.5696 24.0611 11.2392 24.0277 12.2857C23.9783 13.8068 24.7526 15.5478 23.5859 17.291"
        stroke="white"
        strokeWidth="1.47063"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default PostgresLogoIcon;
