import { appConfig } from "~/config/AppConfig";

export const AUTH_ROUTES = {
  loginPath: `${appConfig.basename}/account/login`,
  logoutPath: `${appConfig.basename}/account/logout`,
  logoutUrl: `${appConfig.basename}/account/logout`,
  activateUrl: `${appConfig.basename}/account/activate`,
  impersonationUrl: `${appConfig.basename}/account/impersonate`,
  acceptInvitationUrl: `${appConfig.basename}/account/invitation/accept`,
  forgetPasswordUrl: `${appConfig.basename}/account/forget-password`,
  resetPhoneNumberUrl: `${appConfig.basename}/account/reset-phone-number`,
  resetPasswordUrl: `${appConfig.basename}/account/reset-password`,
  socialLoginCallbackUrl: `${appConfig.basename}/account/social/success`,
  signUpUrl: `${appConfig.basename}/account/sign-up`,
  oidcRedirectUrl: `${appConfig.basename}/account/oidc/callback`,
  samlCallbackUrl: `${appConfig.basename}/account/saml/callback`,
  magicLinkCallbackUrl: `${appConfig.basename}/account/login/magic-link`,
  hostedLoginRedirectUrl: `${appConfig.basename}/oauth/callback`,
  openAppUrl: `${appConfig.basename}/account/redirect`,
};

const AUTH_ROUTE_PATHS = new Set(Object.values(AUTH_ROUTES));

export function isAuthRoute() {
  const pathname = window.location.pathname;
  return AUTH_ROUTE_PATHS.has(pathname);
}
