export function getBuildConstants() {
  return {
    basename: __BASENAME__,
    consoleDeploymentMode: __CONSOLE_DEPLOYMENT_MODE__,
    defaultStack: __DEFAULT_STACK__,
    forceOverrideStack: __FORCE_OVERRIDE_STACK__,
    impersonationHostname: __IMPERSONATION_HOSTNAME__,
    mzConsoleImageTag: __MZ_CONSOLE_IMAGE_TAG__,
    sentryEnabled: __SENTRY_ENABLED__ === "true",
    sentryRelease: __SENTRY_RELEASE__,
  };
}

const isBrowser =
  typeof window !== "undefined" && typeof window.document !== "undefined";

export type BuildConstants = ReturnType<typeof getBuildConstants>;

export const buildConstants: BuildConstants = isBrowser
  ? getBuildConstants()
  : {
      basename: "",
      consoleDeploymentMode: "mz-cloud",
      defaultStack: "local",
      forceOverrideStack: undefined,
      impersonationHostname: "",
      mzConsoleImageTag: undefined,
      sentryEnabled: false,
      sentryRelease: "sentry-release",
    };
