import { BoxProps, Text, Tooltip, useTheme } from "@chakra-ui/react";
import React from "react";

import { MaterializeTheme } from "~/theme";

const PreviewNotice = (props: BoxProps) => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <Tooltip label="This is a preview feature and may undergo changes.">
      <Text
        textStyle="text-small"
        fontWeight="500"
        py={1}
        px={3}
        ml={2}
        borderRadius="full"
        border="1px solid"
        borderColor={colors.border.info}
        background={colors.background.info}
        {...props}
      >
        Preview
      </Text>
    </Tooltip>
  );
};

export default PreviewNotice;
