import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { appConfig } from "~/config/AppConfig";

import { useCurrentUser } from "./queries/frontegg";
import { isMzInternalEmail } from "./util";

// We have to initialize Sentry before calling withSentryReactRouterV6Routing
// which prevents us from putting this in a function.

if (appConfig.sentryConfig !== null) {
  Sentry.init({
    dsn: appConfig.sentryConfig.dsn,
    enabled: true,
    environment: appConfig.sentryConfig.environment,
    release: appConfig.sentryConfig.release,
    beforeSend: (event) => {
      if (appConfig.sentryConfig !== null) return event;

      // If sentry is disabled, log events to the console
      if (event.exception?.values) {
        for (const e of event.exception.values) {
          console.error(e);
        }
      } else {
        console.debug("Sentry event", event);
      }

      return event;
    },
    beforeSendTransaction: (txEvent) => {
      // If an e2e user, do not log any transactions. We've seen our e2e tests
      // spam Sentry, bleeding quota.
      if (
        isMzInternalEmail(txEvent.user?.email) &&
        txEvent.user?.email?.startsWith("infra+cloud-integration-tests")
      ) {
        return null;
      }
      return txEvent;
    },
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    normalizeDepth: 8,
    tracesSampler: (samplingContext) => {
      if (samplingContext.attributes?.polled) {
        return 0.01;
      } else {
        return 0.3;
      }
    },
  });
}

export const useSentryIdentifyOrganization =
  appConfig.sentryConfig !== null
    ? () => {
        const { data: user } = useCurrentUser();
        if (!user) {
          return;
        }
        Sentry.setUser({
          id: user.id,
          email: user.email,
        });
      }
    : () => null;

/** React router <Routes /> component wrapped with Sentry tracing */
export const SentryRoutes =
  appConfig.sentryConfig !== null
    ? Sentry.withSentryReactRouterV6Routing(Routes)
    : Routes;
