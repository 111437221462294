import { useAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useEffect } from "react";

import {
  HistoryId,
  historyItemAtom,
  updateDisplayStateAtomCallback,
} from "./store/shell";

function calculateTotalPages(numRows: number, pageSize: number) {
  return numRows === 0 ? 1 : Math.ceil(numRows / pageSize);
}

/**
 * Given the current page (zero-indexed), total number of rows, and rows per page, calculate the start and end indexes of the current page.
 *
 * i.e. If there are 20 rows, 10 rows per page, and the current page is 0, the pagination range is [0-10]
 */
function calculateCurrentPageIndexes(
  currentPage: number,
  numRows: number,
  pageSize: number,
) {
  const startIndex = Math.min(numRows, pageSize * currentPage);
  const nextPage = currentPage + 1;
  const endIndex = Math.min(numRows, pageSize * nextPage);

  return [startIndex, endIndex];
}

const usePaginationParameters = (
  historyId: HistoryId,
  commandIndex: number,
  totalPages: number,
) => {
  const [historyItem] = useAtom(historyItemAtom(historyId));
  if (!historyItem || historyItem.kind !== "command") {
    return undefined;
  }
  const displayState = historyItem.commandResultsDisplayStates[commandIndex];

  const { isRawSubscribe, isFollowingRawSubscribe } = displayState;
  const shouldFollowLastPage = isRawSubscribe && isFollowingRawSubscribe;

  const currentPage = isRawSubscribe
    ? displayState.currentRawSubscribeTablePage
    : displayState.currentTablePage;

  const isFirstPage = currentPage === 0;

  const isLastPage = currentPage === totalPages - 1;

  return {
    isRawSubscribe,
    isFollowingRawSubscribe,
    shouldFollowLastPage,
    currentPage,
    isFirstPage,
    isLastPage,
  };
};

export const useCommandOutputPagination = ({
  historyId,
  commandIndex,
  pageSize,
  totalNumRows,
}: {
  historyId: HistoryId;
  commandIndex: number;
  pageSize: number;
  totalNumRows: number;
}) => {
  const updateDisplayState = useAtomCallback(updateDisplayStateAtomCallback);
  const totalPages = calculateTotalPages(totalNumRows, pageSize);
  const paginationParameters = usePaginationParameters(
    historyId,
    commandIndex,
    totalPages,
  );

  const changePage = useCallback(
    (newPage: number, options?: { isManuallyInvoked: boolean }) => {
      updateDisplayState(historyId, commandIndex, (curDisplayState) => {
        const { isRawSubscribe, isFollowingRawSubscribe } = curDisplayState;

        const shouldStopFollowing = !!(
          options?.isManuallyInvoked && isRawSubscribe
        );

        return {
          ...curDisplayState,
          [isRawSubscribe
            ? "currentRawSubscribeTablePage"
            : "currentTablePage"]: newPage,
          isFollowingRawSubscribe: shouldStopFollowing
            ? false
            : isFollowingRawSubscribe,
        };
      });
    },
    [commandIndex, historyId, updateDisplayState],
  );

  useEffect(() => {
    if (
      paginationParameters &&
      paginationParameters.shouldFollowLastPage &&
      !paginationParameters.isLastPage
    ) {
      changePage(totalPages - 1);
    }
  }, [changePage, totalNumRows, totalPages, paginationParameters]);

  if (paginationParameters === undefined) {
    return undefined;
  }

  const {
    currentPage,
    isLastPage,
    isFirstPage,
    isRawSubscribe,
    isFollowingRawSubscribe,
  } = paginationParameters;

  const [startIndex, endIndex] = calculateCurrentPageIndexes(
    currentPage,
    totalNumRows,
    pageSize,
  );

  const onNextPage = () => {
    if (isLastPage) {
      return;
    }
    const nextPage = currentPage + 1;

    changePage(nextPage, { isManuallyInvoked: true });
  };

  const onPrevPage = () => {
    if (isFirstPage) {
      return;
    }

    const prevPage = currentPage - 1;
    changePage(prevPage, { isManuallyInvoked: true });
  };

  const onToggleFollow = () => {
    updateDisplayState(historyId, commandIndex, (curDisplayState) => {
      const { isFollowingRawSubscribe: currentFollowing } = curDisplayState;
      return {
        ...curDisplayState,
        isFollowingRawSubscribe: !currentFollowing,
      };
    });
  };

  // Change the current page if a SUBSCRIBE table decreases its total number of pages
  if (currentPage > totalPages - 1) {
    changePage(totalPages - 1);
  }

  return {
    startIndex,
    endIndex,
    currentPage,
    onNextPage,
    onPrevPage,
    prevEnabled: !isFirstPage,
    nextEnabled: !isLastPage,
    isFollowing: isRawSubscribe ? isFollowingRawSubscribe : null,
    onToggleFollow,
    totalPages,
  };
};

export default useCommandOutputPagination;
