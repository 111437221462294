import { stepperAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(stepperAnatomy.keys);

const variantModalHeader = definePartsStyle(({ theme }) => {
  const { colors, textStyles, space } = theme;
  return {
    indicator: {
      width: 3,
      height: 3,
      "&[data-status=active]": {
        borderColor: colors.accent.brightPurple,
      },
      "&[data-status=complete]": {
        bg: colors.border.secondary,
      },
    },
    title: {
      ...textStyles["text-ui-med"],
      whiteSpace: "nowrap",
    },
    separator: {
      minWidth: space["16"],
      "&[data-status=complete]": {
        bg: colors.border.secondary,
      },
    },
  };
});

export const Stepper = defineMultiStyleConfig({
  variants: {
    "modal-header": variantModalHeader,
  },
});
