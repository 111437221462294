/**
 * Given the list of feature flags we want to stub in flexible deployment mode
 * is too big, we default all flags to true and specify the ones we want
 * to disable.
 */
export const disabledFlexibleDeploymentFlags: Record<string, boolean> = {
  "console-freshness-2855": false,
};

export const flexibleDeploymentFlags = new Proxy(
  {},
  {
    get: (_, prop: string) => disabledFlexibleDeploymentFlags[prop] ?? true,
  },
) as Record<string, boolean>;
