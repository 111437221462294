export const NAV_MIN_WIDTH_PX = 240;
export const NAV_MIN_HEIGHT_PX = 52;
export const NAV_HORIZONTAL_SPACING = 4;
export const NAV_HOVER_STYLES = {
  bg: "background.accent",
  color: "accent.brightPurple",
  "*": {
    color: "accent.brightPurple",
  },
};
