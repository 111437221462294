/**
 * Returns a string that represents the environment of the console build, which is
 * separate from the current stack. For example, you might be on staging console, and
 * use the stack switcher to point at a personal stack.
 */
export const getConsoleEnvironment = ({
  hostname,
  isImpersonating,
}: {
  hostname: string;
  isImpersonating: boolean;
}) => {
  if (isImpersonating) {
    return "production";
  }
  if (hostname === "console.materialize.com") {
    return "production";
  }
  if (hostname === "staging.console.materialize.com") {
    return "staging";
  }
  if (hostname.match(/^.*\.preview/)) {
    // *.preview.console.materialize.com
    return "preview";
  }
  return "development";
};
