import { Box, BoxProps, Button, Text, useTheme } from "@chakra-ui/react";
import { differenceInDays } from "date-fns";
import React from "react";

import { useCurrentOrganization } from "~/api/auth";
import { withConfigCheck } from "~/hoc/withAppConfig";
import { InfoIcon } from "~/icons";
import * as chilipiper from "~/marketing/chilipiper";
import docUrls from "~/mz-doc-urls.json";
import { useCurrentUser } from "~/queries/frontegg";
import { MaterializeTheme } from "~/theme";

const FreeTrialNotice = withConfigCheck(
  (props: BoxProps) => {
    const { colors } = useTheme<MaterializeTheme>();
    const { data: user } = useCurrentUser();
    const { organization } = useCurrentOrganization();

    if (!organization || !organization.trialExpiresAt) return null;

    const trialExpiresAt = new Date(organization.trialExpiresAt);
    const now = new Date();
    const daysRemaining = differenceInDays(trialExpiresAt, now);
    const expired = trialExpiresAt <= now;
    return (
      <Box
        borderWidth="1px"
        borderColor={colors.border.info}
        borderRadius="8"
        background={colors.background.info}
        p="4"
        display={{ base: "none", lg: "block" }}
        {...props}
      >
        <Text
          display="flex"
          textStyle="text-small"
          color={colors.foreground.secondary}
        >
          Free trial
          <Box
            as="a"
            href={docUrls["/docs/free-trial-faqs/"]}
            ml="1"
            rel="noopener"
            target="_blank"
          >
            <InfoIcon />
          </Box>
        </Text>
        <Text mt="1" textStyle="heading-sm">
          {expired
            ? "Trial Expired"
            : `${daysRemaining} ${
                daysRemaining === 1 ? "day" : "days"
              } remaining`}
        </Text>
        <Button
          mt="4"
          size="sm"
          variant="primary"
          width="100%"
          onClick={() => {
            if (user) {
              chilipiper.submit({
                name: user.name,
                email: user.email,
                meetingType: "guided-trial",
                organizationId: user.tenantId,
              });
            }
          }}
        >
          Talk with our team
        </Button>
      </Box>
    );
  },
  ({ hasAuthProvider }) => hasAuthProvider,
);

export default FreeTrialNotice;
