import { useForm } from "react-hook-form";

import { DatabaseType } from "./constants";
import { DatabaseConnectionFormState } from "./NewDatabaseConnectionForm";
import { DatabaseSourceFormState } from "./NewDatabaseSourceForm";

export function useDatabaseConnectionForm() {
  return useForm<DatabaseConnectionFormState>({
    defaultValues: {
      connectionAction: "existing",
      name: "",
      host: "",
      port: "",
      sslAuthentication: false,
      sslMode: "require",
    },
    mode: "onTouched",
  });
}

export function useDatabaseSourceForm(databaseType: DatabaseType) {
  return useForm<DatabaseSourceFormState>({
    defaultValues: {
      name: "",
      cluster: null,
      publication: "",
      allTables: false,
      tables: [
        {
          name: "",
          alias: "",
          schemaName: databaseType === "mysql" ? "" : undefined,
        },
      ],
    },
    mode: "onTouched",
  });
}
