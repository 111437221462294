import { useParams } from "react-router-dom";

import { ObjectExplorerParams } from "./routerHelpers";

export type ObjectDetailsParams = ReturnType<typeof useSchemaObjectParams>;

export function useSchemaObjectParams() {
  const { databaseName, schemaName, objectName, id } =
    useParams<ObjectExplorerParams>();
  if (!databaseName || !schemaName || !objectName || !id) {
    // This route won't match unless these parameters are present
    const paramString = JSON.stringify({
      databaseName,
      schemaName,
      objectName,
      id,
    });
    throw new Error(
      `useSchemaObjectParams expects all params to be defined ${paramString}`,
    );
  }
  // Narrow the type
  return {
    databaseName,
    id,
    objectName,
    schemaName,
  };
}
