import { BoxProps, Text, Tooltip, useTheme } from "@chakra-ui/react";
import React from "react";

import { MaterializeTheme } from "~/theme";

const InternalOnlyNotice = (props: BoxProps) => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <Tooltip label="This feature is only avaiable to Materialize employees.">
      <Text
        textStyle="text-small"
        fontWeight="500"
        py={1}
        px={3}
        ml={2}
        borderRadius="full"
        border="1px solid"
        borderColor={colors.border.warn}
        background={colors.background.warn}
        {...props}
      >
        Internal Only
      </Text>
    </Tooltip>
  );
};

export default InternalOnlyNotice;
