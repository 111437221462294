import { forwardRef, Icon, IconProps, useTheme } from "@chakra-ui/react";
import React from "react";

import { MaterializeTheme } from "~/theme";

export const PlusIcon = forwardRef<IconProps, "svg">((props, ref) => {
  const { colors } = useTheme<MaterializeTheme>();

  return (
    <Icon
      ref={ref}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="4"
      color={colors.foreground.secondary}
      {...props}
    >
      <path d="M8 3V13" stroke="currentColor" strokeLinecap="round" />
      <path d="M13 8L3 8" stroke="currentColor" strokeLinecap="round" />
    </Icon>
  );
});
