import React from "react";

export type ErrorBoundaryProps = {
  children?: React.ReactNode;
  fallback: React.ReactElement;
  onError?: (error: unknown, errorInfo: React.ErrorInfo) => void;
};

export class ExpectedErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { error: null | unknown }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  public componentDidCatch(error: unknown, errorInfo: React.ErrorInfo): void {
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }
  }

  render() {
    if (this.state.error) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}
