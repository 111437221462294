import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig: defineSelectConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

export const Select = defineSelectConfig({
  baseStyle: definePartsStyle({
    icon: {
      color: "foreground.secondary",
    },
  }),
  variants: {
    borderless: definePartsStyle({
      field: {
        color: "foreground.secondary",
        fontSize: "14px",
        height: "32px",
        lineHeight: "16px",
        rounded: "8px",
        _hover: {
          cursor: "pointer",
        },
        _focus: {
          background: "background.secondary",
        },
      },
    }),
  },
});
