import { forwardRef, Icon, IconProps, useTheme } from "@chakra-ui/react";
import React from "react";

import { MaterializeTheme } from "~/theme";

export const FilterIcon = forwardRef<IconProps, "svg">((props, ref) => {
  const { colors } = useTheme<MaterializeTheme>();

  return (
    <Icon
      height="4"
      width="4"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      color={colors.foreground.tertiary}
      {...props}
    >
      <path
        d="M2 4H14"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
      />
      <path
        d="M4 8L12 8"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
      />
      <path
        d="M6 12L10 12"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
      />
    </Icon>
  );
});
