import { useAtom, useSetAtom } from "jotai";
import React from "react";

import {
  currentRegionIdAtom,
  defaultRegionSelector,
} from "~/store/environments";

export const useSetInitialRegion = () => {
  const setCurrentRegionId = useSetAtom(currentRegionIdAtom);
  const [defaultRegion] = useAtom(defaultRegionSelector);
  React.useEffect(() => {
    if (!defaultRegion) return;
    setCurrentRegionId(defaultRegion);
  }, [defaultRegion, setCurrentRegionId]);
};
