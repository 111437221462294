export const getTempoDatasourceIds = (
  stack: string,
): Record<string, string> => {
  if (stack === "production") {
    return {
      "aws-us-east-1": "N0Q_O-14z",
      "aws-us-west-2": "f1fa3361-9db3-49a0-b9b6-f5e10bc4375c",
      "aws-eu-west-1": "2EllOa14z",
    };
  }
  if (stack === "staging") {
    return {
      "aws-us-east-1": "e47e25f5-623b-4790-b2eb-a11c3137d7b4",
      "aws-eu-west-1": "e493dba3-b407-4300-8211-7ab8d29671a2",
    };
  }
  return {};
};
