import React from "react";

import ColumnIcon from "~/svg/ColumnIcon";

import MultiSelect from "./MultiSelect";
import {
  COLUMN_FILTER_ITEMS,
  ColumnItem,
  UseColumnsReturn,
} from "./useColumns";

type ColumnFilterProps = {
  selectedColumnFilterItems: ColumnItem[];
  onColumnChange: UseColumnsReturn["onColumnChange"];
};

const ColumnFilter = ({
  selectedColumnFilterItems,
  onColumnChange,
}: ColumnFilterProps) => {
  return (
    <MultiSelect
      items={COLUMN_FILTER_ITEMS}
      selectedItems={selectedColumnFilterItems}
      onSelectItem={onColumnChange}
      leftIcon={<ColumnIcon />}
      toggleButtonContent="Columns"
      getItemLabel={(item) => item.label}
    />
  );
};

export default ColumnFilter;
