import { User } from "@sentry/react";
import { initialize, LDClient, LDContext } from "launchdarkly-js-client-sdk";

import { appConfig } from "~/config/AppConfig";

// We use module state here because we need to ensure the client is only initialized
// once. Because of suspense and concurrent rendering, multiple renders may happen with
// the ref value uninitialized.
let ldClient: LDClient | null = null;
let ldClientReadyResolve: () => void | null;
let ldClientReady: Promise<void> | null = new Promise((resolve) => {
  ldClientReadyResolve = resolve;
});

export function buildLdContext(user: User): LDContext {
  return {
    kind: "multi",
    ...(appConfig.impersonation?.organizationId
      ? {
          impersonated_organization: {
            key: appConfig.impersonation.organizationId,
          },
        }
      : {}),
    ...(user
      ? {
          user: {
            key: user.id,
            email: user.email,
          },
        }
      : {}),
    ...(user.tenantId
      ? {
          organization: {
            key: user.tenantId,
          },
        }
      : {}),
  };
}

export function useLaunchDarklyClient(user: User) {
  if (ldClient) return ldClient;

  ldClient = initialize(appConfig.launchDarklyKey ?? "", buildLdContext(user), {
    bootstrap: "localStorage",
    sendEventsOnlyForVariation: true,
  });
  ldClient.on("ready", () => {
    ldClientReadyResolve();
    ldClientReady = null;
  });
  if (ldClientReady) {
    // Trigger suspense if the client isn't ready yet
    throw ldClientReady;
  }
  return ldClient;
}
