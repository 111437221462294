import { QueryKey } from "@tanstack/react-query";
import { InferResult, sql } from "kysely";

import { queryBuilder } from "~/api/materialize";
import { executeSqlV2 } from "~/api/materialize/executeSqlV2";
import { buildClusterReplicaUtilizationTable } from "~/api/materialize/expressionBuilders";

export function buildClusterReplicaMetricsQuery({
  clusterId,
}: ClusterReplicaMetricsParameters) {
  return queryBuilder
    .selectFrom("mz_cluster_replicas as cr")
    .innerJoin("mz_cluster_replica_sizes as crs", "crs.size", "cr.size")
    .innerJoin(buildClusterReplicaUtilizationTable().as("cru"), (join) =>
      join.onRef("cr.id", "=", "cru.replica_id"),
    )
    .select((eb) => [
      "cr.id",
      "cr.name",
      "cr.size",
      sql<bigint>`${eb.ref("crs.cpu_nano_cores")} * ${eb.ref("crs.processes")}`.as(
        "cpuNanoCores",
      ),
      sql<bigint>`${eb.ref("crs.memory_bytes")} * ${eb.ref("crs.processes")}`.as(
        "memoryBytes",
      ),
      sql<bigint>`${eb.ref("crs.disk_bytes")} * ${eb.ref("crs.processes")}`.as(
        "diskBytes",
      ),
      "cru.cpu_percent as cpuPercent",
      "cru.memory_percent as memoryPercent",
      "cru.disk_percent as diskPercent",
    ])
    .where("cr.cluster_id", "=", clusterId)
    .orderBy("cr.id");
}

export type ClusterReplicaMetricsResult = InferResult<
  ReturnType<typeof buildClusterReplicaMetricsQuery>
>;

export type ClusterReplicaMetricsParameters = {
  clusterId: string;
};

/**
 * Fetches metrics for the replicas of a given cluster
 */
export async function fetchClusterReplicaMetrics({
  parameters,
  queryKey,
  requestOptions,
}: {
  parameters: ClusterReplicaMetricsParameters;
  queryKey: QueryKey;
  requestOptions?: RequestInit;
}) {
  const compiledQuery = buildClusterReplicaMetricsQuery(parameters).compile();
  return executeSqlV2({
    queries: compiledQuery,
    queryKey: queryKey,
    requestOptions,
  });
}
