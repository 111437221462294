import React from "react";

import { ConnectorErrorGraph } from "~/components/ConnectorErrorsGraph";

import { useBucketedSourceErrors } from "./queries";

export interface Props {
  sourceId: string;
  timePeriodMinutes: number;
}

const SourceErrorsGraph = ({ sourceId, timePeriodMinutes }: Props) => {
  const bucketSizeSeconds = React.useMemo(() => {
    return (timePeriodMinutes / 15) * 60;
  }, [timePeriodMinutes]);
  const { data } = useBucketedSourceErrors({
    sourceId,
    bucketSizeSeconds,
    timePeriodMinutes,
  });

  return (
    <ConnectorErrorGraph
      startTime={data.startTime}
      endTime={data.endTime}
      connectorStatuses={data.rows}
      bucketSizeMs={bucketSizeSeconds * 1000}
      timePeriodMinutes={timePeriodMinutes}
    />
  );
};

export default SourceErrorsGraph;
