import React from "react";
import { Navigate, Route } from "react-router-dom";

import { SentryRoutes } from "~/sentry";

import EnableRegion from "./EnableRegion";
import { EnvironmentNotReadyLayout } from "./Layout";
import { OnboardingSteps } from "./OnboardingSteps";
import OnboardingSurvey from "./OnboardingSurvey";

export const EnvironmentNotReadyRoutes = () => {
  return (
    <EnvironmentNotReadyLayout>
      <SentryRoutes>
        <Route path="onboarding-survey" element={<OnboardingSurvey />} />
        <Route path="enable-region" element={<EnableRegion />} />
        <Route path=":step" element={<OnboardingSteps />} />
        <Route
          path="*"
          element={<Navigate to="../onboarding-survey" replace />}
        />
      </SentryRoutes>
    </EnvironmentNotReadyLayout>
  );
};
