import { QueryKey } from "@tanstack/react-query";
import { InferResult } from "kysely";

import { queryBuilder } from "./db";
import { executeSqlV2 } from "./executeSqlV2";

export const DEFAULT_DATABASE_NAME = "materialize";

function buildDatabaseListQuery() {
  return queryBuilder
    .selectFrom("mz_databases")
    .select(["id", "name"])
    .orderBy("name");
}

/**
 * Fetches all databases in the current environment
 */
export async function fetchDatabaseList({
  queryKey,
  requestOptions,
}: {
  queryKey: QueryKey;
  requestOptions: RequestInit;
}) {
  const compiledQuery = buildDatabaseListQuery().compile();
  return executeSqlV2({
    queries: compiledQuery,
    queryKey: queryKey,
    requestOptions,
  });
}

export type Database = InferResult<
  ReturnType<typeof buildDatabaseListQuery>
>[0];
