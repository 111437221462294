import { forwardRef, Input, InputProps } from "@chakra-ui/react";
import React from "react";

const ObjectNameInput = forwardRef<InputProps, "input">((props, ref) => {
  return (
    <Input ref={ref} autoCorrect="off" size="sm" data-1p-ignore {...props} />
  );
});

export default ObjectNameInput;
