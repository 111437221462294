import { VStack } from "@chakra-ui/react";
import React from "react";

import { CommandBlockOutputContainer } from "./CommandBlockOutputContainer";
import SqlSelectTable from "./SqlSelectTable";

const LocalCommandOutput = ({
  command,
  commandResults,
}: {
  command: string;
  commandResults: string[][];
}) => {
  return (
    <VStack spacing="3" alignItems="flex-start" minWidth="0">
      <CommandBlockOutputContainer command={command}>
        <SqlSelectTable
          colNames={["Command", "Description"]}
          paginatedRows={commandResults}
        />
      </CommandBlockOutputContainer>
    </VStack>
  );
};

export default LocalCommandOutput;
