import { CloudRegion } from "~/store/cloudRegions";

import { ImpersonationConfig } from "./impersonation";

export const getEnvironmentdConfig = ({
  consoleHost,
  cloudRegionOverride,
  impersonation,
}: {
  consoleHost: string;
  cloudRegionOverride: CloudRegion | undefined;
  impersonation: ImpersonationConfig;
}) => {
  if (impersonation) {
    return {
      environmentdHttpAddress: impersonation.environmentdHttpAddress,
      regionId: impersonation.regionId,
    };
  }
  if (cloudRegionOverride) {
    return {
      environmentdHttpAddress: consoleHost,
      regionId: `${cloudRegionOverride.provider}/${cloudRegionOverride.region}`,
    };
  }

  return null;
};
