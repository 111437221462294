import { usePrevious } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useEffect } from "react";

import { currentRegionIdAtom } from "~/store/environments";

import { resetShellState } from "./shell";

export const useResetShellStateOnRegionChange = () => {
  const resetShellStateCallback = useAtomCallback(resetShellState);
  const [currentRegionId] = useAtom(currentRegionIdAtom);

  const prevCurrentRegionId = usePrevious(currentRegionId);

  useEffect(() => {
    if (prevCurrentRegionId !== currentRegionId) {
      resetShellStateCallback();
    }
  }, [currentRegionId, resetShellStateCallback, prevCurrentRegionId]);
};
