export const FormLabel = {
  baseStyle: {
    fontSize: "sm",
  },
  variants: {
    inline: {
      m: 0,
      whiteSpace: "nowrap",
    },
  },
};
