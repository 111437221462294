export const getLaunchDarklyKey = (consoleEnv: string) => {
  if (consoleEnv === "production") {
    return "63604cf8f9860a0c1f3c7099";
  }
  if (consoleEnv === "staging" || consoleEnv === "preview") {
    // Staging key
    return "6388e8a24ac9d112339757f3";
  }

  // Fall back to development key
  return "6388e8b9750ee71144183456";
};

export const getSegmentApiKey = (consoleEnv: string) => {
  if (consoleEnv === "production") {
    // Production key
    return "NCe6YQCHM9g04X9yEBUFtoWOuqZU8J1m";
  }

  // Since segment staging isn't hooked up to anything, use the development key for
  // everything that's not production
  return "dGeQYRjmGVsqDI0KIARrAhTvk1BdJJhk";
};
