import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

import { useToast } from "~/hooks/useToast";
import { useAllObjects } from "~/store/allObjects";
import { useRegionSlug } from "~/store/environments";

import { useSchemaObjectParams } from "./useSchemaObjectParams";

export const useToastIfObjectNotExtant = () => {
  const regionSlug = useRegionSlug();
  const queryClient = useQueryClient();
  const params = useSchemaObjectParams();
  const { data: allObjects } = useAllObjects();
  const shouldShowToast = !allObjects.some((object) => object.id === params.id);

  const message = `Object ${params.databaseName}.${params.schemaName}.${params.objectName} does not exist.`;

  const toast = useToast(
    useMemo(
      () => ({
        status: "error",
        description: message,
        isClosable: true,
        duration: null,
      }),
      [message],
    ),
  );

  useEffect(() => {
    if (shouldShowToast) {
      toast();
    }
  }, [regionSlug, queryClient, toast, shouldShowToast]);
};
