import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Heading,
  HStack,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import React, { forwardRef } from "react";
import { LinkProps } from "react-router-dom";

import { MaterializeTheme } from "~/theme";
import { kebabToTitleCase } from "~/util";

export interface AlertProps extends BoxProps {
  variant: "error" | "info" | "warning";
  children?: React.ReactNode;
  showLabel?: boolean;
  label?: React.ReactNode;
  message?: React.ReactNode;
  showButton?: boolean;
  buttonText?: string;
  buttonProps?: ButtonProps & Partial<LinkProps>;
}

export const Alert = forwardRef(
  (
    {
      variant,
      children,
      showLabel = true,
      label = "",
      message = "",
      showButton = false,
      buttonText = "View details",
      buttonProps = {},
      ...props
    }: AlertProps,
    ref,
  ) => {
    const { colors } = useTheme<MaterializeTheme>();

    const colorScheme = {
      error: {
        border: colors.border.error,
        background: colors.background.error,
      },
      info: {
        border: colors.border.info,
        background: colors.background.info,
      },
      warning: {
        border: colors.border.warn,
        background: colors.background.warn,
      },
    };

    return (
      <Box
        borderRadius="lg"
        borderColor={colorScheme[variant].border}
        borderWidth="1px"
        overflow="hidden"
        ref={ref}
        minWidth="400px"
        {...props}
      >
        <Box py="4" px="6" background={colorScheme[variant].background}>
          <HStack spacing={6} justifyContent="space-between">
            <VStack spacing="1" alignItems="start">
              {showLabel ? (
                <Heading
                  as="h6"
                  fontSize="sm"
                  lineHeight="16px"
                  fontWeight="500"
                  color={colors.foreground.primary}
                >
                  {label ? label : kebabToTitleCase(variant)}
                </Heading>
              ) : null}
              <Text
                as="div"
                fontSize="sm"
                lineHeight="20px"
                color={colors.foreground.primary}
              >
                {message}
              </Text>
            </VStack>
            {showButton ? (
              <Box
                height="100%"
                borderLeft="1px solid"
                borderColor={colorScheme[variant].border}
                pl={4}
              >
                <Button
                  variant="text-only"
                  textStyle="text-ui-med"
                  {...buttonProps}
                >
                  {buttonText}
                </Button>
              </Box>
            ) : null}
          </HStack>
        </Box>
        {children}
      </Box>
    );
  },
);

export default Alert;
