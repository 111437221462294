import React from "react";
import { Navigate, Route } from "react-router-dom";

import { SentryRoutes } from "~/sentry";
import { fronteggLoginDisabled } from "~/utils/frontegg";

import { AcceptInvitation } from "./AcceptInvitation";
import { ActivateAccount } from "./ActivateAccount";
import { ForgotPassword } from "./ForgotPassword";
import { Login } from "./Login";
import { Logout } from "./Logout";
import { OauthCallback } from "./OauthCallback";
import { OidcCallback } from "./OidcCallback";
import { ResetPassword } from "./ResetPassword";
import { Signup } from "./Signup";

export const AuthRoutes = () => {
  if (!fronteggLoginDisabled()) return null;

  return (
    <SentryRoutes>
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="oidc/callback" element={<OidcCallback />} />
      <Route path="activate" element={<ActivateAccount />} />
      <Route path="invitation/accept" element={<AcceptInvitation />} />
      <Route path="sign-up" element={<Signup />} />
      <Route path="social/success" element={<OauthCallback />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      {/* legacy route for frontegg compatability */}
      <Route path="forget-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/" />} />
    </SentryRoutes>
  );
};
