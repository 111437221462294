import { QueryKey } from "@tanstack/react-query";
import { sql } from "kysely";

import {
  buildSessionVariables,
  executeSqlV2,
  queryBuilder,
} from "~/api/materialize";

export type LargestMaintainedQueriesParams = {
  replicaMemoryBytes: number;
  limit: number;
  clusterName: string;
  replicaName: string;
};

export function buildLargestMaintainedQueriesQuery({
  replicaMemoryBytes,
  limit,
}: Omit<LargestMaintainedQueriesParams, "replicaName" | "clusterName">) {
  return (
    queryBuilder
      .selectFrom("mz_dataflow_arrangement_sizes as s")
      .innerJoin("mz_compute_exports as ce", "ce.dataflow_id", "s.id")
      .leftJoin("mz_objects as o", "o.id", "ce.export_id")
      .leftJoin("mz_schemas as sc", "sc.id", "o.schema_id")
      .leftJoin("mz_databases as da", "da.id", "sc.database_id")
      .select((eb) => [
        "o.id",
        "o.name",
        eb.ref("s.size").$castTo<number>().as("size"),
        sql<number>`${sql.id("s", "size")} / ${sql.raw(
          replicaMemoryBytes.toString(),
        )} * 100`.as("memoryPercentage"),
        sql<"materialized-view" | "index">`o.type`.as("type"),
        "sc.name as schemaName",
        "da.name as databaseName",
        "s.id as dataflowId",
        "s.name as dataflowName",
      ])
      // Filter out transient dataflows
      .where("ce.export_id", "not like", "t%")
      .orderBy("memoryPercentage", "desc")
      .limit(() => sql.raw(limit.toString()))
  );
}

/**
 * Fetches the largest cluster replica for a given cluster.
 */
export async function fetchLargestMaintainedQueries({
  params,
  queryKey,
  requestOptions,
}: {
  params: LargestMaintainedQueriesParams;
  queryKey: QueryKey;
  requestOptions?: RequestInit;
}) {
  const query = buildLargestMaintainedQueriesQuery(params);
  return executeSqlV2({
    sessionVariables: buildSessionVariables({
      cluster: params.clusterName,
      cluster_replica: params.replicaName,
    }),
    queries: query.compile(),
    queryKey: queryKey,
    requestOptions,
  });
}
