import React from "react";

import { appConfig } from "~/config/AppConfig";
import { useLaunchDarklyClient } from "~/hooks/useLaunchDarklyClient";
import { useCurrentUser } from "~/queries/frontegg";

import { LaunchDarklyContext } from "./Context";

/**
 * Provides our LaunchDarklyContext, which holds an LDClient instance. Used by the
 * useFlags hook.
 */
export const LaunchDarklyProvider = appConfig.isFlexibleDeployment
  ? ({ children }: { children?: React.ReactNode }) => children
  : ({ children }: { children?: React.ReactNode }) => {
      const { data: user } = useCurrentUser();
      const ldClient = useLaunchDarklyClient(user);

      return (
        <LaunchDarklyContext.Provider
          value={{
            client: ldClient,
          }}
        >
          {children}
        </LaunchDarklyContext.Provider>
      );
    };
