import { useSuspenseQuery } from "@tanstack/react-query";

import {
  buildQueryKeyPart,
  buildRegionQueryKey,
} from "~/api/buildQueryKeySchema";
import { assertExactlyOneRow } from "~/api/materialize/assertExactlyOneRow";
import {
  fetchShowCreate,
  ShowCreateStatementParameters,
} from "~/api/materialize/showCreate";

export const showCreateQueryKey = (params: ShowCreateStatementParameters) =>
  [
    ...buildRegionQueryKey("showCreate"),
    buildQueryKeyPart("showCreate", params),
  ] as const;

/**
 * Exectues a `SHOW CREATE` statement.
 */
export function useShowCreate(params: ShowCreateStatementParameters) {
  const { data, ...rest } = useSuspenseQuery({
    refetchInterval: 5000,
    queryKey: showCreateQueryKey(params),
    queryFn: async ({ queryKey, signal }) => {
      const [, parameters] = queryKey;
      const result = await fetchShowCreate(queryKey, parameters, { signal });

      assertExactlyOneRow(result.rows.length, { skipQueryRetry: true });
      return result;
    },
  });
  return { data: data.rows[0], ...rest };
}
