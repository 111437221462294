import { Text, TextProps, useTheme } from "@chakra-ui/react";
import React from "react";

import TextLink from "~/components/TextLink";
import * as chilipiper from "~/marketing/chilipiper";
import { useCurrentUser } from "~/queries/frontegg";
import { MaterializeTheme } from "~/theme";

const ContactSalesCta = (props: TextProps) => {
  const { colors } = useTheme<MaterializeTheme>();
  const { data: user } = useCurrentUser();

  return (
    <Text textStyle="text-small" color={colors.foreground.secondary} {...props}>
      Have questions?{" "}
      <TextLink
        onClick={() => {
          chilipiper.submit({
            name: user.name,
            email: user.email,
            organizationId: user.tenantId,
          });
        }}
      >
        Talk to sales
      </TextLink>
    </Text>
  );
};

export default ContactSalesCta;
