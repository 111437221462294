export interface MoreThanOneRowErrorDetails {
  rows: number;
  skipQueryRetry?: boolean;
}
/**
 * A custom error object for queries that are expected to return a specific number of rows.
 *
 * This implementation is derived from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#custom_error_types
 */
export class MoreThanOneRowError extends Error {
  details: MoreThanOneRowErrorDetails;
  skipQueryRetry: boolean;

  constructor(
    details: MoreThanOneRowErrorDetails,
    ...params: Parameters<ErrorConstructor>
  ) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MoreThanOneRowError);
    }

    this.details = details;
    this.skipQueryRetry = details.skipQueryRetry ?? false;
    if (!this.message) {
      this.message = "Query expected exactly one row, got " + details.rows;
    }
  }
}

export function assertNoMoreThanOneRow(
  rowCount: number,
  options?: { skipQueryRetry: boolean },
) {
  if (rowCount > 1) {
    throw new MoreThanOneRowError({
      rows: rowCount,
      skipQueryRetry: options?.skipQueryRetry,
    });
  }
}
