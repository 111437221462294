import React from "react";
import { Route } from "react-router-dom";

import { SentryRoutes } from "~/sentry";

import { ObjectExplorer } from "./ObjectExplorer";

export const ObjectExplorerRoutes = () => {
  return (
    <SentryRoutes>
      <Route
        path=":databaseName?/schemas?/:schemaName?/:objectType?/:objectName?/:id?/*"
        element={<ObjectExplorer />}
      />
    </SentryRoutes>
  );
};
