import * as Sentry from "@sentry/react";

export function addChunkLoadErrorListener() {
  // Vite triggers this event if it fails to load a chunk, which can happen when we deploy
  // during a user's session,then they try to load a chunk that no longer exists in the
  // current deployment.
  // https://vitejs.dev/guide/build.html#load-error-handling
  window.addEventListener("vite:preloadError", (event) => {
    Sentry.captureException(event.payload);
    window.location.reload();
  });
}
