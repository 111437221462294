import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const welcomeDialogSeenAtom = atomWithStorage(
  "welcomeDialogSeen",
  false,
  undefined,
  {
    getOnInit: true,
  },
);

export const useWelcomeDialog = () => {
  return useAtom(welcomeDialogSeenAtom);
};
