/**
 * A custom error that for errors responses from our openapi-fetch library.
 *
 * This implementation is derived from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#custom_error_types
 */
export class OpenApiFetchError extends Error {
  status: number;
  body: string | object;

  constructor(
    status: number,
    body: string | object,
    ...params: Parameters<ErrorConstructor>
  ) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, OpenApiFetchError);
    }

    this.status = status;
    this.body = body;
    if (!this.message) {
      this.message = `OpenApiFetchError: ${status} - ${body}`;
    }
  }
}
