import { Button, ButtonProps, Tooltip, useTheme } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import React from "react";

import PlayIcon from "~/svg/PlayIcon";
import StopIcon from "~/svg/StopIcon";
import { MaterializeTheme } from "~/theme";
import { controlOrCommand } from "~/util";

import { isCommandProcessing as webSocketCommandProcessing } from "./machines/webSocketFsm";
import { currentPromptValue, saveClearPrompt } from "./store/prompt";
import { shellStateAtom } from "./store/shell";

type RunCommandButtonProps = ButtonProps & {
  runCommand: (command: string) => void;
  isSocketAvailable: boolean;
  cancelQuery: () => void;
};

const RunCommandButton = ({
  isSocketAvailable,
  runCommand,
  cancelQuery,
  ...rest
}: RunCommandButtonProps) => {
  const { colors } = useTheme<MaterializeTheme>();
  const [prompt] = useAtom(currentPromptValue);
  const clearPrompt = useAtomCallback(saveClearPrompt);
  const [{ webSocketState }] = useAtom(shellStateAtom);

  const isStreaming = webSocketState === "commandInProgressStreaming";

  const isCommandProcessing =
    webSocketState && webSocketCommandProcessing(webSocketState);

  const isButtonDisabled =
    !isSocketAvailable || (!isCommandProcessing && prompt.trim().length === 0);

  const buttonText = isStreaming
    ? "Stop Streaming"
    : isCommandProcessing
      ? "Cancel Query"
      : "Run Query";

  const kbdModifier = controlOrCommand();

  return (
    <Tooltip
      label={`Run your query (${kbdModifier} + Enter)`}
      isDisabled={isButtonDisabled}
      background={colors.background.secondary}
      color={colors.foreground.secondary}
    >
      <Button
        variant={isCommandProcessing ? "primary" : "tertiary"}
        position="absolute"
        right="3"
        bottom="3"
        leftIcon={isCommandProcessing ? <StopIcon /> : <PlayIcon />}
        onClick={() => {
          if (isCommandProcessing) {
            cancelQuery();
          } else if (prompt.trim().length > 0) {
            runCommand(prompt);
            clearPrompt();
          }
        }}
        isDisabled={isButtonDisabled}
        loadingText={buttonText}
        {...rest}
        _hover={{
          opacity: isButtonDisabled ? 0.4 : 1,
        }}
      >
        {buttonText}
      </Button>
    </Tooltip>
  );
};

export default RunCommandButton;
