import React from "react";
import { Navigate, Route } from "react-router-dom";

import StripSearchIfInvalidZodSchema from "~/components/StripSearchIfInvalidZodSchema";
import { appConfig } from "~/config/AppConfig";
import { useCurrentUser } from "~/queries/frontegg";
import { SentryRoutes } from "~/sentry";

import QueryHistoryDetail from "./QueryHistoryDetail";
import QueryHistoryList from "./QueryHistoryList";
import { mapQueryHistorySearchParams } from "./queryHistoryUtils";
import { queryHistoryListUrlSchema } from "./utils";

export type ListToDetailsPageLocationState = {
  from?: {
    search?: string;
  };
};

const QueryHistoryRoutes = () => {
  const { data: user } = useCurrentUser();

  return (
    <SentryRoutes>
      <Route
        path="/"
        element={
          <StripSearchIfInvalidZodSchema
            schema={queryHistoryListUrlSchema}
            mapSearchParamsToSchema={(searchParams) =>
              mapQueryHistorySearchParams(
                searchParams,
                appConfig.hasAuthProvider ? user?.email : undefined,
              )
            }
          >
            {(validatedSearchParamData) => {
              const { columns, ...initialFilters } = validatedSearchParamData;
              return (
                <QueryHistoryList
                  initialFilters={initialFilters}
                  initialColumns={columns}
                />
              );
            }}
          </StripSearchIfInvalidZodSchema>
        }
      />
      <Route path=":id" element={<QueryHistoryDetail />} />
      <Route path="*" element={<Navigate to="/" />} />
    </SentryRoutes>
  );
};

export default QueryHistoryRoutes;
