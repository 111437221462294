import {
  BoxProps,
  forwardRef,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  List,
  ListItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { useSelect } from "downshift";
import React from "react";

import { OptionBase } from "~/components/reactSelectComponents";
import ChevronDownIcon from "~/svg/ChevronDownIcon";
import { MaterializeTheme } from "~/theme";
import { viewportOverflowModifier } from "~/theme/components/Popover";

export const SelectToggleButton = forwardRef(
  (
    {
      inputProps,
      children,
      leftIcon,
      ...rest
    }: {
      children?: React.ReactNode;
      leftIcon?: React.ReactNode;
      inputProps?: InputProps;
    } & BoxProps,
    ref,
  ) => {
    const { colors } = useTheme<MaterializeTheme>();

    return (
      <InputGroup ref={ref} width="auto" outline="none" tabIndex={-1} {...rest}>
        <InputLeftElement w="10" pointerEvents="none">
          {leftIcon}
        </InputLeftElement>
        <Input
          as="button"
          backgroundColor={colors.background.primary}
          px="8"
          {...inputProps}
        >
          <Text noOfLines={1}>{children}</Text>
        </Input>
        <InputRightElement w="8" pointerEvents="none">
          <ChevronDownIcon color={colors.foreground.secondary} />
        </InputRightElement>
      </InputGroup>
    );
  },
);

export type MultiSelectProps<Item> = {
  /** Content to render inside the toggle button */
  items: Item[];
  leftIcon?: React.ReactNode;
  selectedItems: Item[];
  /** A callback when a valid item has been selected or deselected */
  onSelectItem: (selectedItem: Item) => void;
  toggleButtonContent?: React.ReactNode;
  getItemLabel: (item: Item) => React.ReactNode;
};

const MultiSelect = <Item,>({
  getItemLabel,
  items,
  leftIcon,
  selectedItems,
  onSelectItem,
  toggleButtonContent,
}: MultiSelectProps<Item>) => {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    selectedItem: null,
    stateReducer: (state, { changes, type }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick: {
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: state.highlightedIndex, // don't change the highlightedIndex after selection.
          };
        }
      }

      return changes;
    },
    onStateChange(changes) {
      switch (changes.type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick: {
          changes.selectedItem && onSelectItem(changes.selectedItem);
        }
      }
    },
  });

  const buttonProps = getToggleButtonProps();

  return (
    <>
      <Popover
        modifiers={viewportOverflowModifier}
        gutter={0}
        placement="bottom-end"
        variant="queryHistory"
        autoFocus={false}
        isOpen={isOpen}
      >
        <PopoverTrigger>
          <SelectToggleButton
            inputProps={buttonProps}
            ref={buttonProps.ref}
            leftIcon={leftIcon}
          >
            {toggleButtonContent}
          </SelectToggleButton>
        </PopoverTrigger>
        <PopoverContent
          motionProps={{
            animate: false,
          }}
        >
          <List {...getMenuProps()} py="1">
            {items.map((item, index) => {
              const isSelected = selectedItems.includes(item);

              return (
                <ListItem key={index} {...getItemProps({ item, index })}>
                  <OptionBase
                    isHighlighted={highlightedIndex === index}
                    isSelected={isSelected}
                  >
                    {getItemLabel(item)}
                  </OptionBase>
                </ListItem>
              );
            })}
          </List>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default MultiSelect;
