import { useSuspenseQuery } from "@tanstack/react-query";

import {
  buildQueryKeyPart,
  buildRegionQueryKey,
} from "~/api/buildQueryKeySchema";
import {
  fetchWorkflowGraph,
  WorkflowGraphParams,
} from "~/api/materialize/workflowGraph";
import {
  fetchWorkflowGraphNodes,
  WorkflowGraphNodesParams,
} from "~/api/materialize/workflowGraphNodes";

export const workflowGraphQueryKeys = {
  all: () => buildRegionQueryKey("workflow"),
  graph: (params: WorkflowGraphParams) =>
    [
      ...workflowGraphQueryKeys.all(),
      buildQueryKeyPart("graph", params),
    ] as const,
  nodes: (params: WorkflowGraphNodesParams) =>
    [
      ...workflowGraphQueryKeys.all(),
      buildQueryKeyPart("nodes", params),
    ] as const,
};

export function useWorkflowGraph(params: WorkflowGraphParams) {
  return useSuspenseQuery({
    queryKey: workflowGraphQueryKeys.graph(params),
    queryFn: ({ queryKey, signal }) => {
      const [, paramsFromKey] = queryKey;
      return fetchWorkflowGraph({
        queryKey,
        params: paramsFromKey,
        requestOptions: { signal },
      });
    },
  });
}

export function useWorkflowGraphNodes(params: WorkflowGraphNodesParams) {
  return useSuspenseQuery({
    queryKey: workflowGraphQueryKeys.nodes(params),
    queryFn: ({ queryKey, signal }) => {
      const [, paramsFromKey] = queryKey;
      return fetchWorkflowGraphNodes({
        queryKey,
        params: paramsFromKey,
        requestOptions: { signal },
      });
    },
  });
}
