import {
  HStack,
  Spacer,
  StackProps,
  Text,
  Tooltip,
  useDisclosure,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Location, useLocation } from "react-router-dom";

import { useCanViewUsage } from "~/api/auth";
import ConnectModal from "~/components/ConnectModal";
import FreeTrialNotice from "~/components/FreeTrialNotice";
import { appConfig } from "~/config/AppConfig";
import { useFlags } from "~/hooks/useFlags";
import { shellPath } from "~/platform/routeHelpers";
import { useRegionSlug } from "~/store/environments";
import { MonitorIcon } from "~/svg/Monitor";
import AdminIcon from "~/svg/nav/AdminIcon";
import { ClustersIcon } from "~/svg/nav/ClustersIcon";
import { DataIcon } from "~/svg/nav/DataIcon";
import GalleryIcon from "~/svg/nav/GalleryIcon";
import { MonitoringIcon } from "~/svg/nav/MonitoringIcon";
import { ShellIcon } from "~/svg/nav/ShellIcon";
import { MaterializeTheme } from "~/theme";

import { NAV_HOVER_STYLES } from "../constants";
import { HideIfEnvironmentDisabled, NavItem } from "./NavItem";

export type NavItemType = {
  href: string;
  state?: object;
  icon?: JSX.Element;
  label: string;
  navItems?: NavItemType[];
  onClick?: () => void;
  forceShow?: boolean;
};

const getNavItems = ({
  regionSlug,
  flags,
  canViewUsage,
  location,
}: {
  regionSlug: string;
  flags: ReturnType<typeof useFlags>;
  canViewUsage: boolean;
  location: Location;
}): NavItemType[] => {
  return [
    {
      href: shellPath(regionSlug),
      icon: <ShellIcon />,
      label: "SQL Shell",
    },
    {
      icon: <DataIcon />,
      label: "Data",
      href: `/regions/${regionSlug}/objects`,
    },
    {
      href: `/regions/${regionSlug}/clusters`,
      icon: <ClustersIcon />,
      label: "Clusters",
    },
    {
      href: `/regions/${regionSlug}/integrations`,
      icon: <GalleryIcon />,
      label: "Integrations",
    },
    {
      href: flags["environment-overview-2855"]
        ? `/regions/${regionSlug}/environment-overview`
        : `/regions/${regionSlug}/query-history`,
      icon: <MonitoringIcon />,
      label: "Monitoring",
      navItems: [
        ...(flags["environment-overview-2855"]
          ? [
              {
                label: "Environment Overview",
                href: `/regions/${regionSlug}/environment-overview`,
              },
            ]
          : []),
        {
          label: "Query History",
          href: `/regions/${regionSlug}/query-history`,
        },
        {
          label: "Sources",
          href: `/regions/${regionSlug}/sources`,
        },
        {
          label: "Sinks",
          href: `/regions/${regionSlug}/sinks`,
        },
      ],
    },
    ...(appConfig.hasAuthProvider
      ? [
          {
            href: "/access",
            icon: <AdminIcon />,
            label: "Admin",
            forceShow: true,
            navItems: [
              {
                label: "App Passwords",
                href: "/access",
                forceShow: true,
              },

              ...(canViewUsage
                ? [
                    {
                      label: "Usage & Billing",
                      href: "/usage",
                      state: { from: location.pathname + location.search },
                      forceShow: true,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ];
};

export const NavMenuContainer = (props: StackProps) => {
  return (
    <VStack
      display={{ base: "none", lg: "flex" }}
      mx={4}
      spacing={2}
      flex="2"
      alignSelf="stretch"
      alignItems="stretch"
      mt={0}
      mb={{ base: 0, lg: 4 }}
      {...props}
    />
  );
};

export const NavMenu = (props: { isCollapsed?: boolean }) => {
  const flags = useFlags();
  const location = useLocation();
  const regionSlug = useRegionSlug();
  const canViewUsage = useCanViewUsage();

  const items = getNavItems({ regionSlug, flags, canViewUsage, location });

  return (
    <NavMenuContainer>
      {items.map((item) => (
        <HideIfEnvironmentDisabled key={item.label} forceShow={item.forceShow}>
          <NavItem key={item.label} {...item} isCollapsed={props.isCollapsed} />
        </HideIfEnvironmentDisabled>
      ))}
    </NavMenuContainer>
  );
};

export const NavMenuMobile = (props: {
  closeMenu: () => void;
  offsetY: number | undefined;
}) => {
  const flags = useFlags();
  const location = useLocation();
  const regionSlug = useRegionSlug();
  const canViewUsage = useCanViewUsage();
  const {
    isOpen: isConnectModalOpen,
    onClose: onCloseConnectModal,
    onOpen: onOpenConnectModal,
  } = useDisclosure();

  const items = getNavItems({ regionSlug, flags, canViewUsage, location });

  return (
    <VStack
      spacing={4}
      flex="2"
      alignSelf="stretch"
      alignItems="stretch"
      height={`calc(100vh - ${props.offsetY}px)`}
      overflowY="auto"
    >
      <VStack px="4" py="6">
        {items.map((item) => (
          <HideIfEnvironmentDisabled
            key={item.label}
            forceShow={item.forceShow}
          >
            <NavItem key={item.label} closeMenu={props.closeMenu} {...item} />
          </HideIfEnvironmentDisabled>
        ))}
      </VStack>
      <Spacer />
      <VStack align="stretch">
        <FreeTrialNotice />
        <VStack>
          <HideIfEnvironmentDisabled>
            <ConnectMenuItem
              width="100%"
              onClick={onOpenConnectModal}
              mb={{ base: 0, lg: 6 }}
            />
            <ConnectModal
              onClose={onCloseConnectModal}
              isOpen={isConnectModalOpen}
            />
          </HideIfEnvironmentDisabled>
        </VStack>
      </VStack>
    </VStack>
  );
};

export const ConnectMenuItem = ({
  isCollapsed,
  ...props
}: StackProps & { isCollapsed?: boolean }) => {
  const { colors } = useTheme<MaterializeTheme>();

  if (!appConfig.hasAuthProvider) return null;

  return (
    <HStack
      as="button"
      spacing="3"
      px={4}
      py={2}
      cursor="pointer"
      _hover={NAV_HOVER_STYLES}
      _active={NAV_HOVER_STYLES}
      aria-label="Connect"
      title="Connect"
      data-testid="connect-menu-button"
      borderRadius="sm"
      borderColor={colors.border.secondary}
      justifyContent={isCollapsed ? "center" : undefined}
      {...props}
    >
      {isCollapsed ? (
        <Tooltip label="Connect">
          <MonitorIcon w="4" h="4" />
        </Tooltip>
      ) : (
        <>
          <MonitorIcon w="4" h="4" />
          <Text textStyle="text-ui-med" color={colors.foreground.secondary}>
            Connect
          </Text>
        </>
      )}
    </HStack>
  );
};
