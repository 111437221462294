import { QueryKey } from "@tanstack/react-query";
import { sql } from "kysely";

import { executeSqlV2, queryBuilder } from "~/api/materialize";

export const buildAvailableClusterSizesQuery = () => {
  return sql<{
    allowed_cluster_replica_sizes: string;
  }>`SHOW allowed_cluster_replica_sizes`;
};
/**
 * Fetches available cluster sizes based on LaunchDarkly configuration
 */
export default async function fetchAvailableClusterSizes({
  queryKey,
  requestOptions,
}: {
  queryKey: QueryKey;
  requestOptions: RequestInit;
}) {
  const compiledQuery = buildAvailableClusterSizesQuery().compile(queryBuilder);

  let sizes: string[] | null = null;

  const response = await executeSqlV2({
    queries: compiledQuery,
    queryKey,
    requestOptions,
  });

  if (response.rows) {
    sizes = response.rows[0].allowed_cluster_replica_sizes
      .split(",")
      .map((v: string) => v.replaceAll('"', "").trim());
  }

  return sizes;
}
