import React from "react";

import SearchableSelect from "~/components/SearchableSelect/SearchableSelect";

type ClusterOption = { name: string };

export type ClusterDropdownProps = {
  onChange: (clusterName: string) => void;
  options: ClusterOption[];
  value: string;
  isDisabled: boolean;
  isLoading: boolean;
};

const ClusterDropdown = (props: ClusterDropdownProps) => {
  return (
    <SearchableSelect<{ name: string }>
      label="Cluster"
      ariaLabel="Cluster"
      isDisabled={props.isDisabled}
      placeholder="Select one"
      options={props.options}
      onChange={(value) => value && props.onChange(value.name)}
      value={{ name: props.value }}
      isLoading={props.isLoading}
      containerWidth="280px"
      menuWidth="280px"
    />
  );
};

export default ClusterDropdown;
