import React from "react";
import { Route } from "react-router-dom";

import { SentryRoutes } from "~/sentry";

import AppPasswordsPage from "./AppPasswordsPage";
import MzCliAppPasswordPage from "./MzCliAppPasswordPage";

export const AppPasswordRoutes = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<AppPasswordsPage />} />
      <Route path="cli" element={<MzCliAppPasswordPage />} />
    </SentryRoutes>
  );
};
