import { useQuery } from "@tanstack/react-query";
import { addDays, startOfDay, subDays } from "date-fns";

import {
  buildGlobalQueryKey,
  buildQueryKeyPart,
} from "~/api/buildQueryKeySchema";
import {
  getCredits,
  getDailyCosts,
  recentInvoices,
} from "~/api/cloudGlobalApi";
import { nowUTC } from "~/util";

import { ROLLING_AVG_TIME_RANGE_LOOKBACK_DAYS } from "./constants";

export const creditBalanceQueryKeys = {
  all: () => buildGlobalQueryKey("credits"),
};

export const invoiceQueryKeys = {
  all: () => buildGlobalQueryKey("invoices"),
};

export const dailyCostQueryKeys = {
  all: () => buildGlobalQueryKey("dailyCosts"),
  list: (timeSpan: number, queryTime: Date) =>
    [
      ...dailyCostQueryKeys.all(),
      buildQueryKeyPart("list", {
        timeSpan,
        queryTime,
      }),
    ] as const,
};

async function getCreditBalance(requestOptions?: RequestInit): Promise<number> {
  const { data: responseBody } = await getCredits(requestOptions);
  return responseBody.data.reduce(
    (totalBalance, creditBlock) => totalBalance + creditBlock.balance,
    0,
  );
}

export function useCreditBalance() {
  return useQuery({
    queryKey: creditBalanceQueryKeys.all(),
    queryFn: ({ signal }) => {
      return getCreditBalance({ signal });
    },
  });
}

export function useRecentInvoices() {
  return useQuery({
    queryKey: invoiceQueryKeys.all(),
    queryFn: async ({ signal }) => {
      const { data: responseBody } = await recentInvoices({}, { signal });
      return responseBody.data;
    },
  });
}

export function getTimeRange(span: number): [Date, Date] {
  // Some fields on the usage page, such as the rolling average in the plan
  // details component, requires a certain number of days' worth of data. That
  // span of time may be greater than what is being visually filtered, so set
  // the minimum-queried range to what the rolling average needs.
  span = Math.max(span, ROLLING_AVG_TIME_RANGE_LOOKBACK_DAYS);
  const endDate = startOfDay(addDays(nowUTC(), 1));
  endDate.setUTCHours(0, 0, 0, 0);
  const startDate = new Date(
    subDays(endDate, span)
      // Since we're bucketing these by day, start from the beginning of the
      // UTC day.
      .setUTCHours(0, 0, 0, 0),
  );
  return [startDate, endDate];
}

export function useDailyCosts(timeSpan: number, queryTime: Date) {
  return useQuery({
    queryKey: dailyCostQueryKeys.list(timeSpan, queryTime),
    queryFn: async ({ queryKey, signal }) => {
      const [_, { timeSpan: queryTimeSpan }] = queryKey;
      const [startDate, endDate] = getTimeRange(queryTimeSpan);
      const response = await getDailyCosts(startDate, endDate, { signal });
      return response.data;
    },
  });
}

export type CreditBalanceResponse = ReturnType<typeof useCreditBalance>;
