import { DeleteIcon } from "@chakra-ui/icons";
import { ButtonProps, Text, useTheme } from "@chakra-ui/react";
import React from "react";

import { ApiToken } from "~/api/frontegg/types";
import DangerActionModal from "~/components/DangerActionModal";
import { useDeleteApiToken } from "~/queries/frontegg";
import { MaterializeTheme } from "~/theme";

interface Props extends ButtonProps {
  token: ApiToken;
}

const DeleteAppPasswordModal = (props: Props) => {
  const { mutateAsync: deleteApiToken } = useDeleteApiToken();

  const { colors } = useTheme<MaterializeTheme>();

  return (
    <DangerActionModal
      title="Delete app password"
      aria-label="Delete app password"
      colorScheme="red"
      confirmIcon={<DeleteIcon />}
      actionText=""
      finalActionText="Delete"
      isDisabled={props.isDisabled}
      confirmText={props.token.description}
      onConfirm={async () => {
        await deleteApiToken({ token: props.token });
      }}
      size="sm"
      variant="outline"
    >
      <Text fontSize="sm" color={colors.foreground.primary}>
        Deleting this app password will revoke access to any devices or services
        using it to connect to Materialize.
      </Text>
    </DangerActionModal>
  );
};

export default DeleteAppPasswordModal;
