import { HStack, VStack } from "@chakra-ui/react";
import React from "react";

import PreviewNotice from "~/components/PreviewNotice";
import { useFlags } from "~/hooks/useFlags";
import {
  MainContentContainer,
  PageHeader,
  PageHeading,
} from "~/layouts/BaseLayout";

import ClusterFreshness from "./ClusterFreshness";
import MemDiskUtilization from "./MemDiskUtilization";

export const EnvironmentOverview = () => {
  const flags = useFlags();
  return (
    <MainContentContainer>
      <PageHeader boxProps={{ mb: 4 }}>
        <HStack>
          <PageHeading>Environment Overview</PageHeading>
          <PreviewNotice />
        </HStack>
      </PageHeader>
      <VStack alignItems="flex-start" width="100%" gap="10" paddingBottom="10">
        <MemDiskUtilization />
        {flags["console-freshness-2855"] && <ClusterFreshness />}
      </VStack>
    </MainContentContainer>
  );
};

export default EnvironmentOverview;
