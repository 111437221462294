import { fetchWithTokenRefresh } from "~/api/fetch";
import { getFronteggToken } from "~/api/fronteggToken";
import { buildFronteggUrl } from "~/utils/frontegg";

export async function tryParseFronteggErrors(
  response: Response,
): Promise<FronteggApiErrorDetails> {
  const body = await response.text();
  try {
    const json = JSON.parse(body);
    if ("errors" in json && json.errors instanceof Array) {
      return { ...json, status: response.status };
    }
  } catch {
    // fall back to the body text if we don't get valid json
  }
  return { errors: [body], status: response.status };
}

export async function assertResponseOk(response: Response) {
  if (!response.ok) {
    /**
     * When a response has a 302 status code, the browser typically handles the redirect
     * automatically by following the URL in the Location header (see
     * https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/302). However, Frontegg's API
     * returns the redirect URL in the response body instead, requiring us to handle the
     * redirection on the client side. We can safely assume that all application state will
     * be reset after the redirection occurs. This approach matches Frontegg's own implementation
     * in @frontegg/rest-api/FetchClient.js.
     */
    if (response.status === 302) {
      const redirectUrlText = await response.text();
      try {
        const redirectUrl = new URL(redirectUrlText);
        window.location.href = redirectUrl.toString();
      } catch (e) {
        console.error("Failed to parse redirect URL", redirectUrlText);
        throw e;
      }
      return;
    }

    const details = await tryParseFronteggErrors(response);
    throw new FronteggApiError(
      details,
      `Frontegg fetch failed: ${response.url} ${response.status}. ${JSON.stringify(details)}`,
      { cause: { status: response.status } },
    );
  }
}

export async function fetchAndThrowOnError(
  path: string,
  options?: RequestInit,
) {
  const response = await fetchWithTokenRefresh(
    buildFronteggUrl(path),
    appendAuthHeader(options ?? {}),
  );
  await assertResponseOk(response);
  return response;
}

export async function fronteggFetchWithoutTokenRefetch(
  path: string,
  options?: RequestInit,
) {
  return fetch(buildFronteggUrl(path), options);
}

export async function fetchAndThrowOnErrorWithoutTokenRefresh(
  path: string,
  options?: RequestInit,
) {
  const response = await fronteggFetchWithoutTokenRefetch(path, options);
  await assertResponseOk(response);
  return response;
}

export async function fetchJsonWithoutTokenRefresh(
  path: string,
  options?: RequestInit,
) {
  const response = await fetchAndThrowOnErrorWithoutTokenRefresh(path, options);
  return response.json();
}

export async function fetchJson(path: string, options?: RequestInit) {
  const response = await fetchAndThrowOnError(path, options);
  return response.json();
}

export function mergeHeaders(
  headers: Record<string, string>,
  options?: RequestInit,
): RequestInit {
  return {
    headers: {
      ...options?.headers,
      ...headers,
    },
    ...options,
  };
}

export function appendAuthHeader(options: RequestInit): RequestInit {
  return mergeHeaders(
    { Authorization: `Bearer ${getFronteggToken()}` },
    options,
  );
}

export interface FronteggApiErrorDetails {
  errors: string[];
  status: number;
}

/**
 * A custom error object for Frontegg api errors.
 *
 * This implementation is derived from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#custom_error_types
 */
export class FronteggApiError extends Error {
  details: FronteggApiErrorDetails;

  constructor(
    details: FronteggApiErrorDetails,
    ...params: Parameters<ErrorConstructor>
  ) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FronteggApiError);
    }

    this.details = details;
  }
}

export function getFronteggErrorMessage(
  error: Error | null,
  fallback?: string,
) {
  return (
    (error && error instanceof FronteggApiError && error.details.errors[0]) ||
    fallback ||
    "An error occurred"
  );
}
