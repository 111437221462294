import React from "react";
import { Route } from "react-router-dom";

import { SentryRoutes } from "~/sentry";

import { EnvironmentOverview } from "./EnvironmentOverview";

export const EnvironmentOverviewRoutes = () => {
  return (
    <SentryRoutes>
      <Route path="*" element={<EnvironmentOverview />} />
    </SentryRoutes>
  );
};
