import {
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import { AppErrorBoundary } from "~/components/AppErrorBoundary";
import { LoadingContainer } from "~/components/LoadingContainer";
import { MainContentContainer } from "~/layouts/BaseLayout";
import { truncateMaxWidth } from "~/theme/components/Table";
import { pluralize } from "~/util";

import { useObjectColumns } from "./queries";
import { useSchemaObjectParams } from "./useSchemaObjectParams";

export const ObjectColumns = () => {
  return (
    <AppErrorBoundary message="An error occurred loading object columns.">
      <React.Suspense fallback={<LoadingContainer />}>
        <ObjectColumnsContent />
      </React.Suspense>
    </AppErrorBoundary>
  );
};

export const ObjectColumnsContent = () => {
  const { databaseName, schemaName, objectName } = useSchemaObjectParams();
  const {
    data: { rows: columns },
  } = useObjectColumns({
    databaseName,
    schemaName,
    name: objectName,
  });

  return (
    <MainContentContainer>
      <VStack alignItems="flex-start" spacing="6">
        <Text textStyle="heading-sm">
          {columns.length} {pluralize(columns.length, "Column", "Columns")}
        </Text>
        <Table variant="linkable" borderRadius="xl">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Nullable</Th>
              <Th>Type</Th>
            </Tr>
          </Thead>
          <Tbody>
            {columns.map((column) => (
              <Tr key={column.name}>
                <Td {...truncateMaxWidth} py="2">
                  <Text noOfLines={1}>{column.name}</Text>
                </Td>
                <Td {...truncateMaxWidth} py="2">
                  <Text noOfLines={1}>{column.nullable.toString()}</Text>
                </Td>
                <Td {...truncateMaxWidth} py="2">
                  <Text noOfLines={1}>{column.type}</Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>
    </MainContentContainer>
  );
};
