import { ColorModeProvider, ColorModeScript } from "@chakra-ui/react";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter } from "react-router-dom";

import { AppErrorBoundary } from "~/components/AppErrorBoundary";
import { ChakraProviderWrapper } from "~/components/ChakraProviderWrapper";
import { DevtoolsContainer } from "~/components/DevtoolsContainer";
import {
  FronteggAdminPanelProvider,
  FronteggProviderWrapper,
} from "~/components/FronteggProviderWrapper";
import IncidentStatusWidget from "~/components/IncidentStatusWidget/IncidentStatusWidget";
import LoadingScreen from "~/components/LoadingScreen";
import { appConfig } from "~/config/AppConfig";
import { JotaiProviderWrapper } from "~/layouts/JotaiProviderWrapper";
import { getQueryClient } from "~/queryClient";
import { config as themeConfig, initialColorMode } from "~/theme";
import { fronteggLoginDisabled } from "~/utils/frontegg";

import { UnauthenticatedRoutes } from "./UnauthenticatedRoutes";

export const App = () => {
  return (
    <BrowserRouter
      basename={appConfig.basename}
      future={{
        v7_relativeSplatPath: true,
      }}
    >
      <ColorModeScript initialColorMode={initialColorMode} />
      <ColorModeProvider options={themeConfig}>
        <ChakraProviderWrapper>
          <AppErrorBoundary containerProps={{ height: "100vh" }}>
            <QueryClientProvider client={getQueryClient()}>
              <DevtoolsContainer />
              <FronteggProviderWrapper>
                <JotaiProviderWrapper>
                  {/* The Frontegg admin panel is required for the AdminPortal to work, but it hides
                      it's children while loading, so we just include it as a peer. */}
                  <FronteggAdminPanelProvider />
                  <IncidentStatusWidget />
                  <AppErrorBoundary containerProps={{ height: "100vh" }}>
                    <React.Suspense fallback={<LoadingScreen />}>
                      <FronteggRoutes>
                        <UnauthenticatedRoutes />
                      </FronteggRoutes>
                    </React.Suspense>
                  </AppErrorBoundary>
                </JotaiProviderWrapper>
              </FronteggProviderWrapper>
            </QueryClientProvider>
          </AppErrorBoundary>
        </ChakraProviderWrapper>
      </ColorModeProvider>
    </BrowserRouter>
  );
};

/**
 * Prevents our routes from rendering on auth routes so frontegg can handle them.
 */
const FronteggRoutes = (props: React.PropsWithChildren) => {
  if (fronteggLoginDisabled()) return props.children;

  if (location.pathname.startsWith("/account")) {
    // Paths that start with /account are handled by Frontegg. Our routes still render,
    // so we have to explicitly handle these cases, otherwise we end up with redirect
    // loops (https://github.com/MaterializeInc/cloud/issues/4351), and broken logouts
    // (https://github.com/MaterializeInc/cloud/issues/4351)
    return null;
  }

  return props.children;
};
