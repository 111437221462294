import {
  DefaultError,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import React from "react";

import { useCanAccessTenantApiTokens } from "~/api/auth";
import {
  buildGlobalQueryKey,
  buildQueryKeyPart,
} from "~/api/buildQueryKeySchema";
import {
  acceptInvitation,
  activateAccount,
  activateStrategy,
  createTenantApiToken,
  createUserApiToken,
  deleteTenantApiToken,
  deleteUserApiToken,
  fetchMfaPolicy,
  fetchMfaPolicyAllowRemember,
  fetchRefreshAccessToken,
  fetchRoles,
  fetchTenantApiTokens,
  fetchTenants,
  fetchUser,
  fetchUserApiTokens,
  forgotPassword,
  login,
  logout,
  mfaEnroll,
  oauthConfiguration,
  oidcPostlogin,
  prelogin,
  resetPassword,
  ResetPasswordParams,
  setActiveTenant,
  signup,
  socialPostlogin,
  SocialPostloginParams,
  verifyMfa,
} from "~/api/frontegg";
import {
  ActivateAccountResponse,
  ActivateStrategyParams,
  ApiToken,
  LoginSuccess,
  MfaEnrollParams,
  MfaEnrollResponse,
  MfaPolicyAllowRememberParams,
  MfaRequiredResponse,
  NewApiToken,
  SignupParams,
  SignupResponse,
  SocialLoginSuccess,
  TenantApiToken,
  TenantsResponse,
  User,
  UserApiToken,
  VerifyMfaParams,
  VerifyMfaResponse,
} from "~/api/frontegg/types";
import {
  clearFronteggToken,
  getFronteggToken,
  refreshToken,
  setFronteggToken,
} from "~/api/fronteggToken";
import { appConfig } from "~/config/AppConfig";
import { useCachedQueryData } from "~/hooks/useCachedQueryData";
import { ResetStoreContext } from "~/layouts/JotaiProviderWrapper";
import { clearCurrentTenantId, setCurrentTenantId } from "~/utils/frontegg";

export const fronteggQueryKeys = {
  all: () => buildGlobalQueryKey("frontegg"),
  acceptInvitation: () =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("acceptInvitation"),
    ] as const,
  activateAccount: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("activateAccount")] as const,
  activateStrategy: (params: ActivateStrategyParams) =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("activateStrategy"),
      params,
    ] as const,
  user: () => [...fronteggQueryKeys.all(), buildQueryKeyPart("user")] as const,
  roles: (params: { enabled: boolean }) =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("roles"), params] as const,
  apiTokens: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("api-tokens")] as const,
  listApiTokens: (params: { canAccessTenantApiTokens: boolean }) =>
    [...fronteggQueryKeys.apiTokens(), params] as const,
  createApiToken: () =>
    [...fronteggQueryKeys.apiTokens(), buildQueryKeyPart("create")] as const,
  deleteApiToken: () =>
    [...fronteggQueryKeys.apiTokens(), buildQueryKeyPart("delete")] as const,
  forgotPassword: () =>
    [
      ...fronteggQueryKeys.apiTokens(),
      buildQueryKeyPart("forgotPassword"),
    ] as const,
  isLoggedIn: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("isLoggedIn")] as const,
  login: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("login")] as const,
  logout: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("logout")] as const,
  mfaEnroll: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("mfaEnroll")] as const,
  mfaAllowRemember: (params: MfaPolicyAllowRememberParams) =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("mfaAllowRemember"),
      params,
    ] as const,
  mfaPolicy: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("mfaPolicy")] as const,
  verifyMfa: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("verifyMfa")] as const,
  prelogin: (params: { email: string }) =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("prelogin"),
      params,
    ] as const,
  resetPassword: () =>
    [
      ...fronteggQueryKeys.apiTokens(),
      buildQueryKeyPart("resetPassword"),
    ] as const,
  oidcPostlogin: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("oidcPostlogin")] as const,
  setCurrentTenant: () =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("setCurrentTenant"),
    ] as const,
  socialPostlogin: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("socialPostlogin")] as const,
  signup: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("signup")] as const,
  oauthConfiguration: () =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("oauthConfiguration"),
    ] as const,
  tenants: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("tenants")] as const,
};

export async function queryUser(requestOptions?: RequestInit) {
  if (appConfig.userStub) {
    return appConfig.userStub;
  }

  return fetchUser(requestOptions);
}

export async function queryTenants(
  requestOptions?: RequestInit,
): Promise<TenantsResponse> {
  if (appConfig.tenantsStub) {
    return appConfig.tenantsStub;
  }
  return fetchTenants(requestOptions);
}

export function useAcceptInvitation(
  options: UseMutationOptions<
    Response,
    DefaultError,
    { userId: string; token: string }
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.acceptInvitation(),
    mutationFn: acceptInvitation,
    ...options,
  });
}

export function useActivateAccount(
  options: UseMutationOptions<
    ActivateAccountResponse,
    DefaultError,
    { userId: string; token: string; password?: string }
  > = {},
) {
  const setToken = useSetFronteggToken();
  return useMutation({
    mutationKey: fronteggQueryKeys.activateAccount(),
    mutationFn: async (params) => {
      const response = await activateAccount(params);
      if (response.accessToken) {
        // If mfa is required, they won't get an access token here
        setToken(response.accessToken);
      }
      return response;
    },
    ...options,
  });
}

export function useActivateStrategy(params: ActivateStrategyParams) {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.activateStrategy(params),
    queryFn: ({ queryKey: [, , queryKeyParams], signal }) =>
      activateStrategy(queryKeyParams, { signal }),
  });
}

export function useCurrentUser() {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.user(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    queryFn: ({ signal }) => queryUser({ signal }),
  });
}

export function useRoles(params: { enabled: boolean } = { enabled: true }) {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.roles(params),
    queryFn: ({ signal }) => (params.enabled ? fetchRoles({ signal }) : []),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}

export function useListApiTokens() {
  const canAccessTenantApiTokens = useCanAccessTenantApiTokens();
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.listApiTokens({ canAccessTenantApiTokens }),
    queryFn: async ({ signal }) => {
      const fetches: Promise<UserApiToken[] | TenantApiToken[]>[] = [
        fetchUserApiTokens({ signal }),
      ];
      if (canAccessTenantApiTokens) {
        fetches.push(fetchTenantApiTokens({ signal }));
      }
      const apiTokens = await Promise.all(fetches);
      return apiTokens
        .flat()
        .toSorted((x, y) => Date.parse(y.createdAt) - Date.parse(x.createdAt));
    },
  });
}

type CreateApiTokenVariables =
  | {
      type: "personal";
      description: string;
    }
  | {
      type: "service";
      description: string;
      user: string;
      roleIds: string[];
    };

function formatAppPassword({ clientId, secret }: NewApiToken) {
  const formattedClientId = clientId.replaceAll("-", "");
  const formattedSecret = secret.replaceAll("-", "");
  const password = `mzp_${formattedClientId}${formattedSecret}`;
  const obfuscatedPassword = `${new Array(password.length).fill("*").join("")}`;
  return { password, obfuscatedPassword };
}

export function useCreateApiToken(
  options?: UseMutationOptions<
    NewApiToken,
    DefaultError,
    CreateApiTokenVariables
  >,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: fronteggQueryKeys.createApiToken(),
    mutationFn: async (params: CreateApiTokenVariables) => {
      if (params.type === "personal") {
        return await createUserApiToken(params);
      } else {
        return await createTenantApiToken(params);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: fronteggQueryKeys.apiTokens(),
      });
    },
    ...options,
  });

  const newPassword = React.useMemo(() => {
    if (!mutation.data) return null;

    const { password, obfuscatedPassword } = formatAppPassword(mutation.data);
    return { ...mutation.data, password, obfuscatedPassword };
  }, [mutation.data]);

  return {
    ...mutation,
    data: newPassword,
  };
}

export function useDeleteApiToken(
  options: UseMutationOptions<Response, DefaultError, { token: ApiToken }> = {},
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: fronteggQueryKeys.deleteApiToken(),
    mutationFn: async (params: { token: ApiToken }) => {
      if (params.token.type === "personal") {
        return await deleteUserApiToken({ id: params.token.clientId });
      } else {
        return await deleteTenantApiToken({ id: params.token.clientId });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: fronteggQueryKeys.apiTokens(),
      });
    },
    ...options,
  });
}

export function useForgotPassword(
  options: UseMutationOptions<Response, DefaultError, { email: string }> = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.forgotPassword(),
    mutationFn: forgotPassword,
    ...options,
  });
}

export function useResetPassword(
  options: UseMutationOptions<Response, DefaultError, ResetPasswordParams> = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.resetPassword(),
    mutationFn: resetPassword,
    ...options,
  });
}

export function useLogout(options: UseMutationOptions = {}) {
  const resetStore = React.useContext(ResetStoreContext);
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: fronteggQueryKeys.logout(),
    mutationFn: async () => {
      await logout();
      clearFronteggToken();
      clearCurrentTenantId();
      queryClient.clear();
      resetStore();
    },
    ...options,
  });
}

export function useOidcLogin(
  options: UseMutationOptions<
    Response,
    DefaultError,
    { code: string; state: string }
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.oidcPostlogin(),
    mutationFn: oidcPostlogin,
    ...options,
  });
}

export function useLogin(
  options: UseMutationOptions<
    LoginSuccess | MfaRequiredResponse,
    DefaultError,
    { email: string; password: string }
  > = {},
) {
  const setToken = useSetFronteggToken();
  return useMutation({
    mutationKey: fronteggQueryKeys.login(),
    mutationFn: async (params) => {
      const result = await login(params);
      if (result.accessToken) {
        // If mfa is required, and it hasn't been enrolled yet, we won't get an access
        // token here.
        setToken(result.accessToken);
      }
      return result;
    },
    ...options,
  });
}

/**
 * This hook should only be called in one place, and the caller needs to handle
 * rendering the MFA form when required.
 */
export function useRefreshToken() {
  const setToken = useSetFronteggToken();
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.isLoggedIn(),
    staleTime: 9 * 60 * 1000, // Access token is good for 10 minutes, refresh after 9
    gcTime: Infinity,
    queryFn: async () => {
      if (!appConfig.hasAuthProvider) return { isLoggedIn: true };
      if (getFronteggToken()) return { isLoggedIn: true };

      try {
        const response = await fetchRefreshAccessToken();
        if ("mfaRequired" in response && response.mfaRequired) {
          return {
            isLoggedIn: false,
            response,
          } as const;
        }
        setToken(response.accessToken);
        return { isLoggedIn: true };
      } catch {
        return { isLoggedIn: false };
      }
    },
  });
}

export type UseIsLoggedInData = ReturnType<typeof useRefreshToken>["data"];

export function useIsLoggedIn() {
  const cachedResult = useCachedQueryData<UseIsLoggedInData>(
    fronteggQueryKeys.isLoggedIn(),
  );
  if (!cachedResult) {
    return { isLoggedIn: false, isInitialized: false };
  }
  return { ...cachedResult, isInitialized: true };
}

export function useMfaPolicy() {
  return useSuspenseQuery({
    queryFn: ({ signal }) => fetchMfaPolicy({ signal }),
    queryKey: fronteggQueryKeys.mfaPolicy(),
    refetchInterval: 5000,
  });
}

export function useMfaPolicyAllowRemember(
  params: MfaPolicyAllowRememberParams,
) {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.mfaAllowRemember(params),
    queryFn: ({ queryKey: [, , paramsFromKey], signal }) =>
      fetchMfaPolicyAllowRemember(paramsFromKey, { signal }),
  });
}

export function useMfaEnroll(
  options: UseMutationOptions<
    MfaEnrollResponse,
    DefaultError,
    MfaEnrollParams
  > = {},
) {
  const setToken = useSetFronteggToken();
  return useMutation({
    mutationKey: fronteggQueryKeys.mfaEnroll(),
    mutationFn: async (params) => {
      const response = await mfaEnroll(params);
      setToken(response.accessToken);
      return response;
    },
    ...options,
  });
}

export function useVerifyMfa(
  options: UseMutationOptions<
    VerifyMfaResponse,
    DefaultError,
    VerifyMfaParams
  > = {},
) {
  const setToken = useSetFronteggToken();
  return useMutation({
    mutationKey: fronteggQueryKeys.verifyMfa(),
    mutationFn: async (params) => {
      const response = await verifyMfa(params);
      setToken(response.accessToken);
      return response;
    },
    ...options,
  });
}

export function usePrelogin(
  params: { email: string },
  options: { enabled: boolean } = { enabled: true },
) {
  return useQuery({
    enabled: options.enabled,
    queryKey: fronteggQueryKeys.prelogin(params),
    queryFn: ({ queryKey: [, , queryKeyParams], signal }) =>
      prelogin(queryKeyParams, { signal }),
  });
}

export function useSignup(
  options: UseMutationOptions<SignupResponse, DefaultError, SignupParams> = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.signup(),
    mutationFn: signup,
    ...options,
  });
}

export function useOauthConfiguration() {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.oauthConfiguration(),
    queryFn: ({ signal }) => oauthConfiguration({ signal }),
  });
}

export function useOauthLogin(
  options: UseMutationOptions<
    SocialLoginSuccess,
    DefaultError,
    SocialPostloginParams
  > = {},
) {
  const setToken = useSetFronteggToken();
  return useMutation({
    mutationKey: fronteggQueryKeys.socialPostlogin(),
    mutationFn: async (params) => {
      const result = await socialPostlogin(params);
      setToken(result.accessToken);
      return result;
    },
    ...options,
  });
}

export function useSetActiveTenant(
  options: UseMutationOptions<User, DefaultError, { tenantId: string }> = {},
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: fronteggQueryKeys.setCurrentTenant(),
    mutationFn: async (params: { tenantId: string }) => {
      const user = await setActiveTenant(params);
      setCurrentTenantId(params.tenantId);
      // Since the token includes the current tenant ID, we have to refresh it before we
      // refetch any other data.
      await refreshToken();
      return user;
    },
    onSuccess: (user) => {
      queryClient.setQueryData<User>(fronteggQueryKeys.user(), user);
    },
    ...options,
  });
}

export function useTenants() {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.tenants(),
    queryFn: (requestOptions) => queryTenants(requestOptions),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}

function useSetFronteggToken() {
  const queryClient = useQueryClient();
  return (token: string | null) => {
    setFronteggToken(token);
    queryClient.setQueryData<UseIsLoggedInData>(
      fronteggQueryKeys.isLoggedIn(),
      {
        isLoggedIn: true,
      },
    );
  };
}
