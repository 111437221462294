import { StatusSummary } from "./types";

export const STATUSPAGE_URL =
  "https://statuspage.incident.io/materialize/api/v1/summary";

export async function fetchSummary(requestOptions?: RequestInit) {
  const response = await fetch(STATUSPAGE_URL, requestOptions);

  if (!response.ok) {
    throw new Error(await response.json());
  }

  return response.json() as Promise<StatusSummary>;
}
