import React from "react";

import { Notice } from "~/api/materialize/types";

import NoticeOutput from "./NoticeOutput";
import PlanInsightsNotice, {
  PLAN_INSIGHTS_NOTICE_CODE,
} from "./plan-insights/PlanInsightsNotice";
import { CommandOutput } from "./store/shell";

const CommandResultNotice = ({
  notice,
  commandOutput,
  commandResultIndex,
}: {
  notice: Notice;
  commandOutput: CommandOutput;
  commandResultIndex: number;
}) => {
  if (notice.code === PLAN_INSIGHTS_NOTICE_CODE) {
    return (
      <PlanInsightsNotice
        notice={notice}
        commandOutput={commandOutput}
        commandResultIndex={commandResultIndex}
      />
    );
  }
  return <NoticeOutput notice={notice} />;
};
export default CommandResultNotice;
