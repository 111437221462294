import { useTheme } from "@chakra-ui/react";
import React from "react";

import { MaterializeTheme } from "~/theme";

const CopyIcon = () => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4V3C10 1.89543 9.10457 1 8 1H3C1.89543 1 1 1.89543 1 3V8C1 9.10457 1.89543 10 3 10H4"
        stroke={colors.foreground.secondary}
        strokeLinecap="round"
      />
      <rect
        x="6"
        y="6"
        width="9"
        height="9"
        rx="2"
        stroke={colors.foreground.secondary}
      />
    </svg>
  );
};

export default CopyIcon;
