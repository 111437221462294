import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const MinimizeIcon = forwardRef<IconProps, "svg">((props, ref) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="4"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13 7L9 7L9 3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9L7 9L7 13"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9999 2L9.33325 6.66667"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.99976 14L6.66642 9.33337"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export default MinimizeIcon;
