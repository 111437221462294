import React from "react";

import Alert from "~/components/Alert";

const CreateEnvironmentWarning = () => {
  const message = (
    <>
      New regions include a <code>25cc</code> quickstart cluster. You will be
      billed for this cluster until you connect to the region and drop it.
    </>
  );

  return <Alert variant="warning" message={message} />;
};

export default CreateEnvironmentWarning;
