import { useContext } from "react";

import { LaunchDarklyContext } from "~/components/LaunchDarkly";
import { appConfig } from "~/config/AppConfig";
import { flexibleDeploymentFlags } from "~/config/flexibleDeploymentFlags";

export const useFlags = !appConfig.isFlexibleDeployment
  ? () => {
      const context = useContext(LaunchDarklyContext);
      if (!context) {
        throw new Error("useFlags must be used within LaunchDarklyProvider");
      }
      return context.client.allFlags();
    }
  : () => flexibleDeploymentFlags;
