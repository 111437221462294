import { FieldError } from "react-hook-form";

export const replicasErrorMessage = (
  error: FieldError | undefined,
  maxReplicas: number,
) => {
  if (!error?.type) return error?.message;
  if (error.type === "min") return "Replicas cannot be negative.";
  if (error.type === "max") return `Replicas cannot exceed ${maxReplicas}.`;
  if (error.type === "required") return "Replicas is required.";
};
