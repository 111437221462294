import { forwardRef, Icon, IconProps, useTheme } from "@chakra-ui/react";
import React from "react";

import { MaterializeTheme } from "~/theme";

const BoxIcon = forwardRef<IconProps & { color?: string }, "svg">(
  (props, ref) => {
    const { colors } = useTheme<MaterializeTheme>();

    return (
      <Icon
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="4"
        ref={ref}
        {...props}
        stroke={props.color || colors.foreground.tertiary}
      >
        <path
          d="M8.75 14.72V8"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.92969 4.64014L8.74969 8.0068L14.5697 4.64014"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.75 10.6664V5.33311C14.7498 5.09929 14.688 4.86965 14.571 4.66721C14.454 4.46478 14.2858 4.29668 14.0833 4.17977L9.41667 1.51311C9.21397 1.39608 8.98405 1.33447 8.75 1.33447C8.51595 1.33447 8.28603 1.39608 8.08333 1.51311L3.41667 4.17977C3.21418 4.29668 3.04599 4.46478 2.92897 4.66721C2.81196 4.86965 2.75024 5.09929 2.75 5.33311V10.6664C2.75024 10.9003 2.81196 11.1299 2.92897 11.3323C3.04599 11.5348 3.21418 11.7029 3.41667 11.8198L8.08333 14.4864C8.28603 14.6035 8.51595 14.6651 8.75 14.6651C8.98405 14.6651 9.21397 14.6035 9.41667 14.4864L14.0833 11.8198C14.2858 11.7029 14.454 11.5348 14.571 11.3323C14.688 11.1299 14.7498 10.9003 14.75 10.6664Z"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Icon>
    );
  },
);

export default BoxIcon;
