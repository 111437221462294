import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  ModalContent,
  Spinner,
  Text,
  useTheme,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useCurrentOrganization } from "~/api/auth";
import { DailyCosts, PlanType } from "~/api/cloudGlobalApi";
import ErrorBox from "~/components/ErrorBox";
import { Modal } from "~/components/Modal";
import { SupportButton } from "~/components/SupportLink";
import {
  MainContentContainer,
  PageHeader,
  PageHeading,
} from "~/layouts/BaseLayout";
import { MaterializeTheme } from "~/theme";
import { nowUTC } from "~/util";
import { formatCurrency } from "~/utils/format";

import DailyUsageChart, {
  chartHeightPx,
  legendHeightPx,
} from "./DailyUsageChart";
import InvoiceTable from "./InvoiceTable";
import PlanDetails from "./PlanDetails";
import { useDailyCosts, useRecentInvoices } from "./queries";
import RegionSelect from "./RegionSelect";
import SpendBreakdown from "./SpendBreakdown";
import TimeRangeSelect from "./TimeRangeSelect";
import { getTimeRangeSlice } from "./utils";

const DEFAULT_TIME_RANGE_LOOKBACK_DAYS = 7;

interface PageTopBarProps {
  backButtonLabel: string;
  previousHref: string;
}

const PageTopBar = ({
  previousHref,
  backButtonLabel,
  children,
}: React.PropsWithChildren<PageTopBarProps>) => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <Flex
      width="100%"
      backgroundColor={colors.background.primary}
      alignItems="center"
      justifyContent="space-between"
      px="4"
      py="3"
      boxSizing="border-box"
      borderBottom="1px solid"
      borderBottomColor={colors.border.primary}
    >
      <Flex alignItems="center">
        <Button
          size="sm"
          as={Link}
          to={previousHref}
          leftIcon={<ArrowBackIcon />}
          variant="ghost"
          data-testid="back-button"
        >
          {backButtonLabel}
        </Button>
      </Flex>
      {children}
    </Flex>
  );
};

const InvoiceTableWrapper = ({ planType }: { planType: PlanType }) => {
  const { data: invoices, isLoading, isError, error } = useRecentInvoices();
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <ErrorBox message={error.message} />
      ) : (
        <InvoiceTable invoices={invoices ?? []} planType={planType} />
      )}
    </>
  );
};

const AwsMarketplaceInvoiceBanner = () => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <Flex
      data-testid="aws-marketplace-banner"
      backgroundColor={colors.background.secondary}
      border="1px solid"
      borderColor={colors.border.secondary}
      gap={4}
      padding={4}
      justifyContent="space-between"
      borderRadius="lg"
      marginBottom={4}
    >
      <Box>
        <Text textStyle="text-ui-med">Procured through AWS</Text>
        <Text textStyle="text-ui-reg">
          Invoices are tracked in your company&apos;s AWS Billing Console.
        </Text>
      </Box>
      <HStack gap={4}>
        <Divider orientation="vertical" borderColor={colors.border.info} />
        <Button
          variant="secondary"
          as="a"
          target="_blank"
          rel="noopener"
          href="https://console.aws.amazon.com/billing/home"
        >
          View invoices
        </Button>
      </HStack>
    </Flex>
  );
};

type ChartPanelProps = {
  timeRange: number;
  setTimeRange: (val: number) => void;
  dailyCosts: DailyCosts["daily"] | null;
  isLoading: boolean;
  isError: boolean;
  region: "all" | string;
  error: Error | null;
};

function getTotalSpend(
  dailyCosts: DailyCosts["daily"] | null,
  region: "all" | string,
): number {
  if (dailyCosts === null) {
    return 0;
  }
  let totalPrice = 0;
  for (const day of dailyCosts) {
    for (const costKey of ["compute", "storage"] as const) {
      for (const price of day.costs[costKey].prices) {
        if (region === "all" || price.regionId === region) {
          totalPrice += parseFloat(price.subtotal);
        }
      }
    }
  }
  return totalPrice;
}

const ChartPanel = ({
  timeRange,
  setTimeRange,
  dailyCosts,
  region,
  isLoading,
  isError,
  error,
}: ChartPanelProps) => {
  const totalSpend = useMemo(
    () => getTotalSpend(dailyCosts, region),
    [dailyCosts, region],
  );
  return (
    <Box data-testid="chart-panel">
      <HStack justifyContent="space-between" my={3}>
        <Text
          as="h4"
          textStyle="heading-md"
          data-testid={`${region}-spend-amount`}
        >
          {formatCurrency(totalSpend)}
        </Text>
        <div data-testid="time-range-select">
          <TimeRangeSelect timeRange={timeRange} setTimeRange={setTimeRange} />
        </div>
      </HStack>
      <Flex
        minHeight={legendHeightPx + chartHeightPx}
        data-testid="chart-container"
      >
        {isLoading && (
          <Spinner
            data-testid="chart-loading-spinner"
            size="xl"
            margin="auto"
          />
        )}
        {isError && (
          <ErrorBox
            data-testid="chart-error"
            message={
              error?.message || "There was an error fetching your usage."
            }
          />
        )}
        {!isLoading && !isError && dailyCosts && (
          <DailyUsageChart region={region} data={dailyCosts} />
        )}
      </Flex>
    </Box>
  );
};

const UsagePage = () => {
  const navigate = useNavigate();
  const { organization } = useCurrentOrganization();
  const { state } = useLocation();
  const [regionFilter, setRegionFilter] = useState<"all" | string>("all");
  const [lastQueryTime, setLastQueryTime] = useState(nowUTC());
  const [timeRangeFilter, setTimeRangeFilter] = useState(
    DEFAULT_TIME_RANGE_LOOKBACK_DAYS,
  );
  const {
    data: dailyCosts,
    isLoading: isDailyCostsLoading,
    isError: isDailyCostsError,
    error: dailyCostsError,
  } = useDailyCosts(timeRangeFilter, lastQueryTime);

  const chartTooltipRef = useRef<HTMLDivElement>(null);
  const previousHref: string = state?.from || "/";
  const timeRangeCosts = useMemo(
    () => getTimeRangeSlice(dailyCosts?.daily ?? null, timeRangeFilter),
    [dailyCosts, timeRangeFilter],
  );
  return (
    <Modal
      isOpen
      onClose={() => navigate(previousHref)}
      size="full"
      closeOnEsc={false}
    >
      <ModalContent>
        <PageTopBar previousHref={previousHref} backButtonLabel="Back">
          <SupportButton>Contact support</SupportButton>
        </PageTopBar>
        <MainContentContainer width="100%" maxWidth="1400px" mx="auto">
          <PageHeader
            boxProps={{
              justifyContent: "flex-start",
              gap: 10,
            }}
          >
            <PageHeading>Usage &amp; Billing</PageHeading>
            <div data-testid="region-select">
              <RegionSelect region={regionFilter} setRegion={setRegionFilter} />
            </div>
          </PageHeader>
          <Grid
            templateAreas={`"chart details"
            "spend details"
            "invoices ."`}
            gridTemplateColumns="minmax(500px, 70%) minmax(300px, 3fr)"
            gridColumnGap={12}
            gridRowGap={10}
          >
            <GridItem area="chart">
              <ChartPanel
                region={regionFilter}
                timeRange={timeRangeFilter}
                setTimeRange={(range) => {
                  setTimeRangeFilter(range);
                  setLastQueryTime(nowUTC());
                }}
                dailyCosts={timeRangeCosts}
                isLoading={isDailyCostsLoading}
                isError={isDailyCostsError}
                error={dailyCostsError}
              />
            </GridItem>
            <GridItem area="details">
              <PlanDetails
                region={regionFilter}
                dailyCosts={dailyCosts ?? null}
                timeSpan={timeRangeFilter}
              />
            </GridItem>
            <GridItem area="spend">
              <SpendBreakdown
                region={regionFilter}
                dailyCosts={timeRangeCosts}
                totalDays={timeRangeFilter}
              />
            </GridItem>
            <GridItem area="invoices">
              <Text as="h3" textStyle="heading-sm" mb={4}>
                Invoice history
              </Text>
              {organization?.subscription?.marketplace === "aws" ? (
                <AwsMarketplaceInvoiceBanner />
              ) : (
                <InvoiceTableWrapper
                  planType={organization?.subscription?.type ?? "uncategorized"}
                />
              )}
            </GridItem>
          </Grid>
        </MainContentContainer>
      </ModalContent>
      <div ref={chartTooltipRef} />
    </Modal>
  );
};

export default UsagePage;
