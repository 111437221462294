import { QueryKey } from "@tanstack/react-query";
import { sql } from "kysely";

import { executeSqlV2, queryBuilder } from "~/api/materialize";

export const buildMaxReplicasPerClusterQuery = () => {
  return sql<{
    max_replicas_per_cluster: string;
  }>`SHOW max_replicas_per_cluster`;
};

/**
 * Fetches the maximum number replicas per cluster based on LaunchDarkly configuration
 */
export default async function fetchMaxReplicasPerCluster({
  queryKey,
  requestOptions,
}: {
  queryKey: QueryKey;
  requestOptions: RequestInit;
}) {
  const compiledQuery = buildMaxReplicasPerClusterQuery().compile(queryBuilder);

  const response = await executeSqlV2({
    queries: compiledQuery,
    queryKey,
    requestOptions,
  });

  let max: number | null = null;

  if (response.rows) {
    max = parseInt(response.rows[0].max_replicas_per_cluster);
  }

  return max;
}
