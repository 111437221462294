import { InferResult } from "kysely";

import { queryBuilder } from "..";

function getSourceByNameStatement(
  name: string,
  databaseName: string,
  schemaName: string,
) {
  return queryBuilder
    .selectFrom("mz_object_fully_qualified_names as o")
    .where("object_type", "=", "source")
    .where("o.database_name", "=", databaseName)
    .where("o.schema_name", "=", schemaName)
    .where("o.name", "=", name)
    .select([
      "o.id",
      "o.database_name as databaseName",
      "o.schema_name as schemaName",
      "o.name",
    ]);
}

export const getSourceByNameColumns = [
  "id",
  "databaseName",
  "schemaName",
  "name",
];

export type SourceByNameResult = InferResult<
  ReturnType<typeof getSourceByNameStatement>
>[0];

export default getSourceByNameStatement;
