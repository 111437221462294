import { typographySystem } from "../typography";

export const FormControl = {
  baseStyle: {
    helperText: {
      ...typographySystem["text-small"],
      color: "foreground.secondary",
    },
  },
};
