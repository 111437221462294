import { Flex, useTheme } from "@chakra-ui/react";
import React from "react";

import { LoadingAnimation } from "~/icons";
import { MaterializeTheme } from "~/theme";

const LoadingScreen = () => {
  const { colors } = useTheme<MaterializeTheme>();

  return (
    <Flex
      direction="column"
      height="100vh"
      width="100vw"
      align="center"
      justify="center"
    >
      <LoadingAnimation
        height="auto"
        width="128px"
        fill={colors.accent.purple}
      />
    </Flex>
  );
};

export default LoadingScreen;
