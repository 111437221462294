import { Box, Flex, HStack, Text, useTheme } from "@chakra-ui/react";
import React, { forwardRef } from "react";

import { createNamespace } from "~/api/materialize";
import { Sink } from "~/api/materialize/sink/sinkList";
import { Source } from "~/api/materialize/source/sourceList";
import { ConnectorStatusPill } from "~/components/StatusPill";
import TimePeriodSelect from "~/components/TimePeriodSelect";
import { MaterializeTheme } from "~/theme";

interface OverviewHeaderProps {
  connector: Source | Sink;
  timePeriodMinutes: number;
  setTimePeriodMinutes: React.Dispatch<React.SetStateAction<number>>;
}

const OverviewHeader = forwardRef<HTMLDivElement, OverviewHeaderProps>(
  ({ connector, timePeriodMinutes, setTimePeriodMinutes }, ref) => {
    const { colors } = useTheme<MaterializeTheme>();
    return (
      <Flex alignItems="flex-start" width="100%">
        <HStack
          justifyContent="space-between"
          width="100%"
          ref={ref}
          flexWrap="wrap"
        >
          <Box>
            <Text
              textStyle="text-ui-med"
              fontWeight="500"
              noOfLines={1}
              color={colors.foreground.secondary}
            >
              {createNamespace(connector.databaseName, connector.schemaName)}
            </Text>
            <HStack spacing="5">
              <Text textStyle="heading-lg">{connector.name}</Text>
              <ConnectorStatusPill connector={connector} />
            </HStack>
          </Box>
          <Box marginLeft="auto">
            <TimePeriodSelect
              timePeriodMinutes={timePeriodMinutes}
              setTimePeriodMinutes={setTimePeriodMinutes}
            />
          </Box>
        </HStack>
      </Flex>
    );
  },
);

export default OverviewHeader;
