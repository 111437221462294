import { createStore } from "jotai";

let store = createStore();

/**
 * Returns the current Jotai store. Note that it's not safe to subscribe directly to the
 * store, since we create a new store instance when you switch organization.
 */
export function getStore() {
  return store;
}

/**
 * Creates a new Jotai store in module scope, resetting all global state.
 * This function won't actually change the value used by the provider, you probably want
 * ResetStoreContext instead.
 */
export function resetStore() {
  store = createStore();
}
