import { useAtom } from "jotai";
import React from "react";
import { Route } from "react-router-dom";

import { CodeMirrorProvider } from "~/components/CommandBlock";
import { SentryRoutes } from "~/sentry";
import { currentRegionIdAtom } from "~/store/environments";

import Shell from "./Shell";
import { ShellVirtualizedListProvider } from "./ShellVirtualizedList";

const ShellRoutes = () => {
  const [currentRegionId] = useAtom(currentRegionIdAtom);

  return (
    <SentryRoutes>
      <Route
        path="/"
        element={
          // Remount the Shell when the region changes to clear any local state
          <ShellVirtualizedListProvider key={currentRegionId}>
            <CodeMirrorProvider>
              <Shell />
            </CodeMirrorProvider>
          </ShellVirtualizedListProvider>
        }
      />
    </SentryRoutes>
  );
};

export default ShellRoutes;
