import { CloudRegion } from "~/store/cloudRegions";

import { ImpersonationConfig } from "./impersonation";

export const getCloudRegions = ({
  stack,
  impersonation,
  isFlexibleDeployment,
}: {
  stack: string | null;
  impersonation: ImpersonationConfig;
  isFlexibleDeployment: boolean;
}): CloudRegion[] | null => {
  if (impersonation) {
    return [
      {
        provider: impersonation.provider,
        region: impersonation.region,
        regionApiUrl: "",
      },
    ];
  }
  if (stack === "local") {
    return [
      {
        provider: "local",
        region: "kind",
        regionApiUrl: "http://127.0.0.1:8033",
      },
    ];
  }
  if (isFlexibleDeployment) {
    return [
      {
        provider: "local",
        region: "flexible-deployment",
        regionApiUrl: "",
      },
    ];
  }

  return null;
};
