import { QueryKey } from "@tanstack/react-query";
import { InferResult } from "kysely";

import { NULL_DATABASE_NAME } from "~/platform/object-explorer/constants";

import { queryBuilder } from "../db";
import { executeSqlV2 } from "../executeSqlV2";

export type ObjectExplorerColumnsParameters = {
  databaseName: string;
  schemaName: string;
  name: string;
};

export function buildObjectColumnsQuery(params: {
  databaseName: string;
  schemaName: string;
  name: string;
}) {
  let query = queryBuilder
    .selectFrom("mz_object_fully_qualified_names as ofqn")
    .innerJoin("mz_columns as c", "c.id", "ofqn.id")
    .select(["c.name", "c.type", "c.nullable"])
    .where("ofqn.name", "=", params.name)
    .where("ofqn.schema_name", "=", params.schemaName);
  if (params.databaseName === NULL_DATABASE_NAME) {
    query = query.where("ofqn.database_name", "is", null);
  } else {
    query = query.where("ofqn.database_name", "=", params.databaseName);
  }
  return query;
}

export function fetchObjectColumns({
  parameters,
  queryKey,
  requestOptions,
}: {
  parameters: ObjectExplorerColumnsParameters;
  queryKey: QueryKey;
  requestOptions?: RequestInit;
}) {
  const compiledQuery = buildObjectColumnsQuery(parameters).compile();
  return executeSqlV2({
    queries: compiledQuery,
    queryKey: queryKey,
    requestOptions,
  });
}

export type Column = InferResult<ReturnType<typeof buildObjectColumnsQuery>>[0];
