import { QueryKey } from "@tanstack/react-query";
import { sql } from "kysely";

import { escapedLiteral as lit, queryBuilder } from "~/api/materialize";

import { executeSqlV2 } from "../executeSqlV2";

export interface AlterClusterSettingsParams {
  clusterName: string;
  size: string;
  replicas: number;
}

export interface AlterClusterNameParams {
  clusterName: string;
  name: string;
}

export const alterClusterSettingsStatement = (
  values: AlterClusterSettingsParams,
) => {
  return sql`ALTER CLUSTER ${sql.id(values.clusterName)} SET (
    SIZE = ${lit(values.size)},
    REPLICATION FACTOR = ${sql.raw(values.replicas.toString())}
  )
`;
};

export const alterClusterNameStatement = (values: AlterClusterNameParams) => {
  return sql`ALTER CLUSTER ${sql.id(values.clusterName)} RENAME TO ${sql.id(values.name)}
`;
};

export function alterCluster({
  nameParams,
  settingsParams,
  queryKey,
  requestOptions,
}: {
  nameParams: AlterClusterNameParams;
  settingsParams: AlterClusterSettingsParams;
  queryKey: QueryKey;
  requestOptions?: RequestInit;
}) {
  const alterSettings =
    alterClusterSettingsStatement(settingsParams).compile(queryBuilder);
  const alterName = alterClusterNameStatement(nameParams).compile(queryBuilder);
  return executeSqlV2({
    queries: [alterSettings, alterName],
    queryKey: queryKey,
    requestOptions,
  });
}
